import React, { useState, useContext } from "react";
import { Button, Popover, notification } from "antd";
import OrderContext from "../../../context/OrderContext";
import useNewRelicMetrics from "../../../hooks/newRelicMetricsHook";
import {
  CANCEL_DELIVERY_EVENT,
  CANCEL_DELIVERY_SUBMIT_EVENT,
} from "../../../util/NewRelicConstants";

import {
  CloseOutlined,
  InfoCircleFilled,
  CheckCircleTwoTone,
} from "@ant-design/icons";
import Axios from "axios";
import "./CancelDelivery.less";
import getBffUrl from "../../../util/getBffUrl";

export const CancelDelivery = (props) => {
  const { referenceId, orderNumber, orderIndex } = props;
  const { logNewRelicMetricsEvent } = useNewRelicMetrics();
  const [visible, setVisible] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const { updateOrder } = useContext(OrderContext);

  const handleClose = () => {
    setVisible(false);
  };

  async function handleCancel() {
    logNewRelicMetricsEvent(CANCEL_DELIVERY_SUBMIT_EVENT, {
      order_number: orderNumber,
      index: orderIndex,
    });
    setIsLoading(true);

    try {
      const response = await Axios.delete(
        `${getBffUrl()}/deliveryOrder/v1/cancelOrder/${referenceId}`
      );

      if (response && response.status === 200) {
        setIsLoading(false);
        setVisible(false);
        notification.open({
          message: "Success",
          description: `Delivery order number ${referenceId} has been cancelled`,
          icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        });
        updateOrder();
      } else {
        notification.open({
          message: "Error",
          description: `Failed to cancel order number ${referenceId}. Please try again`,
          icon: <InfoCircleFilled className="info-circle-confirmation" />,
        });
      }
    } catch (error) {
      setIsLoading(false);
      setVisible(false);
      notification.open({
        message: "Error",
        description: `Failed to cancel order number ${referenceId}. Please try again`,
        icon: <InfoCircleFilled className="info-circle-confirmation" />,
      });
      console.log("ERROR CANCELING ORDER", error);
    }
  }

  const popOverContent = (
    <div className="popover-wrapper" style={{ width: "450px" }}>
      <Button
        className="close-icon"
        type="text"
        icon={<CloseOutlined />}
        onClick={handleClose}
      />
      <div className="icon-wrapper">
        <div>
          <InfoCircleFilled className="info-circle-color" />
        </div>

        <p className="popover-text">
          This cancellation will only impact the order status in DMP and will
          not affect other Systems. Select &apos;Proceed with Cancel&apos; to
          continue or the X in the top right corner to abandon.
        </p>
      </div>
      <div className="confirmation-button">
        <Button loading={loading} onClick={handleCancel} type="primary">
          Proceed with cancel
        </Button>
      </div>
    </div>
  );

  return (
    <div id="cancel-delivery-button">
      <Popover
        open={visible}
        className="popover"
        placement="left"
        content={popOverContent}
        trigger="click"
      >
        <Button
          className="default-btn"
          block
          onClick={() => {
            logNewRelicMetricsEvent(CANCEL_DELIVERY_EVENT, {
              order_number: orderNumber,
              index: orderIndex,
            });
            setVisible(true);
          }}
        >
          Cancel Delivery
        </Button>
      </Popover>
    </div>
  );
};
