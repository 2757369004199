import React, { useContext, useState } from "react";
import { Button, Popover, Row, Col } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import permit from "../../util/Permissions";
import { UserContext } from "../../context/RootContext";
import "./NewManualOrderButton.less";
import NewMOModal from "./NewMOModal";
import {
  NEW_MANUAL_ORDER_CLICK_EVENT,
  NEW_MANUAL_ORDER_CONTINUE_EVENT,
} from "../../util/NewRelicConstants";
import useNewRelicMetrics from "../../hooks/newRelicMetricsHook";

const NewManualOrderButton = () => {
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { user } = useContext(UserContext);
  const { logNewRelicMetricsEvent } = useNewRelicMetrics();

  const content = (
    <Row>
      <Col span={2}>
        <InfoCircleFilled style={{ color: "#FAAD14" }} />
      </Col>
      <Col span={22}>
        <Row>
          <Col span={14}>
            Use this only if you are not able to look up the original order.
          </Col>
        </Row>
        <Row className="popover-buttons">
          <Col span={14}>Do you want to continue?</Col>
          <Col span={5}>
            <Button
              onClick={() => {
                setVisible(false);
                logNewRelicMetricsEvent(NEW_MANUAL_ORDER_CONTINUE_EVENT, {
                  text: "NO",
                });
              }}
            >
              No
            </Button>
          </Col>
          <Col span={5}>
            <Button
              type="primary"
              onClick={() => {
                setIsModalVisible(true);
                setVisible(false);
                logNewRelicMetricsEvent(NEW_MANUAL_ORDER_CONTINUE_EVENT, {
                  text: "YES",
                });
              }}
            >
              Yes
            </Button>
            <NewMOModal
              setIsModalVisible={setIsModalVisible}
              isModalVisible={isModalVisible}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );

  return (
    <div className="new-mo-container">
      {permit("canCreateNewManualOrder", user) && (
        <Popover
          id="popover"
          placement="topLeft"
          content={content}
          trigger="click"
          open={visible}
          onOpenChange={(visible) => {
            setVisible(visible);
            logNewRelicMetricsEvent(NEW_MANUAL_ORDER_CLICK_EVENT, {});
          }}
        >
          <Button type="primary">New Manual Order</Button>
        </Popover>
      )}
    </div>
  );
};

export default NewManualOrderButton;
