import useSTHOrderDetails from "./sthOrderDetailsHook";
import { DASHBOARD_STH_CONTEXT } from "../../util/Constants";
import useOrderDetails from "./orderDetailsHook";
import { useContext } from "react";
import DashboardContext from "../../context/DashboardContext";

/**
 * Higher-order function that selects the appropriate hook based on the dashboard context.
 *
 * @param {Function} orderDetailsHook - The default hook for standard order details.
 * @param {Function} sthOrderDetailsHook - The hook specific to STH order details.
 * @returns {Function} - A function that, when invoked, will use the appropriate hook based on the dashboard context.
 */
function withDynamicHookSelection(orderDetailsHook, sthOrderDetailsHook) {
  return function (data) {
    const dashboardContext = useContext(DashboardContext);

    if (dashboardContext === DASHBOARD_STH_CONTEXT) {
      return sthOrderDetailsHook(data);
    }

    return orderDetailsHook();
  };
}

/**
 * Hook that provides order details based on the current dashboard context.
 * It dynamically selects between the standard order details hook and the STH-specific order details hook.
 *
 * @param {Object} data - The data to be passed to the selected hook.
 * @returns {Object} - The return value of the selected hook.
 */
const useOrderDetailsByContext = withDynamicHookSelection(
  useOrderDetails,
  useSTHOrderDetails
);

export default useOrderDetailsByContext;
