import React, { useContext, useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";
import LocationContext from "./context/LocationContext";
import Loading from "./components/Loading/Loading";
import { PFContext, UserContext } from "./context/RootContext";
import { MailProvider, MailMessageProvider } from "./context/RootContext";
import { userRole } from "./util/Roles";
import TagManager from "react-gtm-module";
import useNewRelicMetrics from "./hooks/newRelicMetricsHook";
import packageJson from "../../package.json";
import * as newRelicConstants from "./util/NewRelicConstants";
import getRoutes from "./routing/routesConfig";
import { useAuthInterceptor } from "./routing/useAuthInterceptor";
import AccessRequest from "./components/AccessRequest/AccessRequest";

function App() {
  const { setCustomAttribute } = useNewRelicMetrics();

  if (process.env.REACT_APP_GTM_ID) {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_ID,
    });
  }
  const { user, loading, error, getUserDetails } = useContext(UserContext);
  const { data } = useContext(PFContext);
  const { ldapId } = data;

  const [currentLocation, setCurrentLocation] = useState(null);

  const value = {
    currentLocation,
    setCurrentLocation: (v) => {
      setCurrentLocation(v);
      sessionStorage.setItem("location", v.locationNumber || null);
    },
  };

  useEffect(() => {
    if (packageJson.version && newRelicConstants.ATTR_NAMES.app_version) {
      setCustomAttribute(
        newRelicConstants.ATTR_NAMES.app_version,
        packageJson.version
      );
    }
  }, [packageJson.version, newRelicConstants.ATTR_NAMES.app_version]);

  useEffect(() => {
    if (!user.ldapId && !loading && !error) {
      getUserDetails(ldapId);
    }
    if (user.locations && currentLocation === null) {
      const sessionLocation = sessionStorage.getItem("location");
      const locationToSet = user.locations.find(
        (l) => l.locationNumber === sessionLocation
      );
      setCurrentLocation(locationToSet || user.locations[0]);
      setCustomAttribute(
        newRelicConstants.ATTR_NAMES.user_company,
        user.companyName
      );
      setCustomAttribute(
        newRelicConstants.ATTR_NAMES.user_role,
        userRole(user)
      );
      setCustomAttribute(
        newRelicConstants.ATTR_NAMES.user_location,
        locationToSet
          ? locationToSet.locationName
          : user.locations[0].locationName
      );
    }
  }, [user]);

  const { noAccess } = useAuthInterceptor();

  if (noAccess) {
    return <AccessRequest />;
  }

  return !user || !currentLocation ? (
    <div className={"background"}>
      <Loading />
    </div>
  ) : (
    <>
      <div>
        <LocationContext.Provider value={value}>
          <MailProvider>
            <MailMessageProvider>
              <RouterProvider router={getRoutes(user, currentLocation)} />
            </MailMessageProvider>
          </MailProvider>
        </LocationContext.Provider>
      </div>
    </>
  );
}

export default App;
