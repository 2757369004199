import React from "react";
import { Button } from "antd";
import "./Revisit.less";
import { RevisitToolTip } from "../../../Actions/RevisitToolTip/RevisitToolTip";

export const Revisit = ({ isRevisit, isReplacementRevisit, setDrawerOpen }) => {
  return isRevisit && !isReplacementRevisit ? (
    <RevisitToolTip buttonName="Revisit" isUpOutline={false} />
  ) : (
    <div className="revisit-container">
      <Button
        type="primary"
        onClick={() => {
          setDrawerOpen(true);
        }}
      >
        Revisit
      </Button>
    </div>
  );
};
