import { useLocation } from "react-router-dom";
import { useContext, useEffect } from "react";
import RoutePlannerViewContext from "../../context/RoutePlannerViewContext";
import moment from "moment";

/**
 * Usage Summary:
 * Invoking this hook in a component returns a list of observable values and an update function.
 *
 * This custom hook is based on useSearchStateHook and is an alternative, non-generic implementation for the RoutePlannerView component's subtree.
 */
export function useContextManager() {
  const routerLocation = useLocation();
  const routePlannerViewContext = useContext(RoutePlannerViewContext);

  useEffect(() => {
    const queryParams = queryStringDecoder(routerLocation);
    const tab = identifyTab(routerLocation);
    if (tab === "dispatch") {
      queryParams.date = moment().format("YYYY-MM-DD");
    }
    routePlannerViewContext.setValues({ tab: tab, ...queryParams });
  }, [routerLocation]);

  return {};
}

export function checkPageType(routerLocation) {
  let pageType = "Invalid";

  if (routerLocation.pathname.toLowerCase().includes("/manifest")) {
    pageType = "Manifest";
  } else if (routerLocation.pathname.toLowerCase().includes("/details")) {
    pageType = "Details";
  } else if (
    /^\/route-planner\/routes\/(dispatch|planning|unassigned|archive)$/.test(
      routerLocation.pathname
    )
  ) {
    pageType = "Summary";
  }

  return pageType;
}

export function identifyTab(routerLocation) {
  const path = routerLocation.pathname.toLowerCase();
  if (path.includes("/dispatch")) {
    return "dispatch";
  } else if (path.includes("/archive")) {
    return "archive";
  } else if (path.includes("/unassigned")) {
    return "unassigned";
  } else if (path.includes("/planning")) {
    return "planning";
  } else {
    return "invalid";
  }
}

/**
 * Takes an object of query keys and params and return a query string in the form of "querykey1=queryvalue1&querykey2=queryvalue2"
 */
export function queryStringBuilder(paramsObj) {
  const entries = Object.entries(paramsObj);
  return entries
    .filter(([key, value]) => value !== undefined)
    .map(([key, value]) => {
      if (value !== undefined) {
        return `${key}=${value}`;
      }
    })
    .join("&");
}

/**
 * if it's details, routeId must be present, set all other params to empty.
 * else, decode the params by splitting and return the object
 */
export function queryStringDecoder(routerLocation) {
  // ! This approach is brittle. Refactor at a later time
  const queryDict = { filterMap: {} };
  if (checkPageType(routerLocation) === "Details") {
    const pathArray = routerLocation.pathname.split("/");
    queryDict.routeId = pathArray[pathArray.length - 1];
  } else {
    const entries = routerLocation.search.slice(1).split("&");
    entries.forEach((entry) => {
      const [key, value] = entry.split("=");
      if (key === "date") {
        queryDict.date = value;
      }
      if (
        key === "customerOrderNumber" ||
        key === "customerLastName" ||
        key === "msNumber"
      ) {
        queryDict.searchType = key;
        queryDict.searchTerm = value;
      }
      if (key === "sortField") {
        queryDict.sorterColumn = value;
      }
      if (key === "sortOrder") {
        queryDict.sorterDirection = value;
      }
      if (key.includes("filter_")) {
        queryDict.filterMap[key.split("_")[1]] = value;
      }
    });
  }
  return queryDict;
}

/**
 * Generic function: Takes an object and returns an object of filtered entries
 * * undefined are filtered out
 * * functions are filtered out
 * * bigint are filtered out
 * * symbols are filtered out
 *
 * * boolean values are returned as is
 * * numbers are returned as is
 * * strings are returned as is but empty strings are filtered out
 *
 * * nulls are returned as is
 * * arrays are joined by a comma following the same filters as above
 * * deeper nested arrays and objects are filtered out
 * TODO: add support to recursively flatten nested objects and arrays
 */
export function toFilteredDict(obj) {
  const entries = Object.entries(obj);
  const filteredDict = {};
  entries.forEach((entry) => {
    const [key, value] = entry;
    switch (typeof value) {
      case "undefined":
      case "function":
      case "bigint":
      case "symbol":
        return;
      case "boolean":
      case "number":
        filteredDict[key] = value;
        return;
      case "string":
        if (value === "") {
          return;
        }
        filteredDict[key] = value;
        return;
      case "object":
        if (value === null) {
          filteredDict[key] = "null";
          return;
        }
        if (Array.isArray(value)) {
          const singleLevelArray = value.filter((item) => {
            return (
              typeof item === "boolean" ||
              typeof item === "number" ||
              (typeof item === "string" && item !== "") ||
              item === null
            );
          });
          filteredDict[key] = singleLevelArray
            .map((item) => {
              if (item === null) {
                return "null";
              }
              return item;
            })
            .join(",");
          return;
        }
        return;
      default:
        return;
    }
  });
  return filteredDict;
}
