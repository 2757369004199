import React from "react";
import "./PurchaseOrder.less";
import PropTypes from "prop-types";

const ProductLine = (props) => {
  const { productLine } = props;
  const displayCancellation = getCancellationString();
  function getCancellationString() {
    if (productLine && productLine.quantity < 1) {
      return <div className="line-cancelled-label">CANCELLED</div>;
    }
  }

  return (
    <div id="product-line">
      <div className="model-number-label">Model #</div>
      <div className="model-number">{productLine.modelNbr}</div>
      <div className="sku-number-label">Store SO SKU</div>
      <div className="sku-number">{productLine.skuNbr}</div>
      {displayCancellation}
    </div>
  );
};

ProductLine.propTypes = { productLine: PropTypes.object.isRequired };

export default ProductLine;
