import { notification } from "antd";

function useNotification() {
  const [api, contextHolder] = notification.useNotification();

  const notificationStyles = {
    error: {
      duration: 0,
      style: {
        border: "1px solid #ED1C24",
        borderLeft: "7px solid #ED1C24",
        background: "#FEF2F2",
      },
    },
    success: {
      style: {
        border: "1px solid #00AE4D",
        borderLeft: "7px solid #00AE4D",
        background: "#FEF2F2",
      },
    },
  };

  /**
   * Open a notification with a given type, message, description, and style.
   * @param {string} type - The type of notification (e.g., 'info', 'success', 'error', 'warning').
   * @param {string} message - The title to display in the notification.
   * @param {string} description - The additional description to display below the message.
   * @param {Object} style - The custom styling for the notification.
   */
  const openNotification = (type, message, description, style) => {
    api[type]({
      message,
      description,
      ...style,
    });
  };

  /**
   * Open an error notification with a message, description, an error object,
   * and optionally a formatter function for the error.
   * @param {Object} params - The parameters object.
   * @param {string} params.message - The title message for the notification.
   * @param {string} params.description - The description for the notification.
   * @param {Object} params.error - Optional error object from which to extract the description.
   * @param {Function} params.errorFormatter - An optional formatter function to customize the error message and description.
   */
  const openErrorNotification = ({
    message,
    description,
    error,
    errorFormatter,
  }) => {
    let errorMessage = message || "Error";
    let errorDescription = description || "An unexpected error occurred.";

    // If a formatter function is provided, use it to set the error message and description
    if (errorFormatter && errorFormatter instanceof Function) {
      const formattedError = errorFormatter(error);
      errorMessage = formattedError.message || errorMessage;
      errorDescription = formattedError.description || errorDescription;
    } else if (error && error.response) {
      // If the error object has a 'response' object with a specific description, use it
      errorDescription = error.response.data.description || errorDescription;
    }

    openNotification(
      "error",
      errorMessage,
      errorDescription,
      notificationStyles.error
    );
  };

  /**
   * Open an success notification with a message/header and description.
   * @param {Object} params - The parameters object.
   * @param {string} params.message - The title message for the notification.
   * @param {string} params.description - The description for the notification.
   * @param {Function} params.successFormatter - An optional formatter function to customize the success message and description.
   */
  const openSuccessNotification = ({
    message,
    description,
    successFormatter,
  }) => {
    let successMessage = message || "success";
    let successDescription =
      description || "Your request has been processed successfully.";

    if (successFormatter && successFormatter instanceof Function) {
      const successFormatter = successFormatter();
      successMessage = successFormatter.message || successMessage;
      successDescription = successFormatter.description || successDescription;
    }
    openNotification(
      "success",
      successMessage,
      successDescription,
      notificationStyles.success
    );
  };

  return { openErrorNotification, openSuccessNotification, contextHolder };
}

export default useNotification;
