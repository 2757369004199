export default (number) => {
  let new_number = "";
  if (number != null && number.length > 0) {
    const clean_number = number.replace(/\D/g, "");
    const size = clean_number.length;
    if (size >= 1 && size < 4) {
      new_number = "".concat("(", clean_number);
    } else if (size >= 4 && size < 7) {
      new_number = "".concat(
        "(",
        clean_number.slice(0, 3),
        ") ",
        clean_number.slice(3)
      );
    } else if (size >= 7 && size <= 10) {
      new_number = "".concat(
        "(",
        clean_number.slice(0, 3),
        ") ",
        clean_number.slice(3, 6),
        "-",
        clean_number.slice(6)
      );
    } else if (size > 10) {
      new_number = "".concat(
        "(",
        clean_number.slice(0, 3),
        ") ",
        clean_number.slice(3, 6),
        "-",
        clean_number.slice(6, 10)
      );
      // new_number = number.slice(0, 14);
    }
  }
  return {
    number: new_number,
    isValid: new RegExp("^\\(\\d{3}\\)\\s\\d{3}-\\d{4}", "i").test(new_number),
  };
};
