import React from "react";
import { Result } from "antd";
import "./ErrorComponents.less";

const NotFound = () => (
  <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    className="not-found"
  />
);

export default NotFound;
