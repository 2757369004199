import React, { useState, useRef } from "react";
import "./DashboardCommon.less";
import DeliveriesCommon from "../DeliveriesCommon/DeliveriesCommon";
import SearchContext from "../../context/SearchContext";
import { DeliveriesCommonProvider } from "../../context/DeliveriesCommonContext";

export const DashboardCommon = () => {
  return (
    <div className="dashboard-common-wrapper">
      <div id="dashboard-common-container">
        <DeliveriesCommonProvider>
          <DeliveriesCommon />
        </DeliveriesCommonProvider>
      </div>
    </div>
  );
};
