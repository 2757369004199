import React from "react";
import { Button, Popconfirm, message } from "antd";
import useNewRelicMetrics from "../../hooks/newRelicMetricsHook";
import { CONFORMATION_POP_UP_REVISIT_EVENT } from "../../util/NewRelicConstants";

const Confirmation = ({
  orderCancellation,
  orderNumber,
  orderIndex,
  overlayType,
}) => {
  const { logNewRelicMetricsEvent } = useNewRelicMetrics();
  return (
    <>
      {
        <Popconfirm
          data-testid="confirmation-pop-up"
          title="Cancelling this order cannot be undone. Do you want to cancel the order?"
          placement="topLeft"
          onConfirm={orderCancellation}
          onCancel={() => message.error("This order has not been cancelled.")}
          okText="Yes"
          cancelText="No"
        >
          <Button
            className="default-btn"
            block
            onClick={() => {
              logNewRelicMetricsEvent(CONFORMATION_POP_UP_REVISIT_EVENT, {
                order_number: orderNumber,
                index: orderIndex,
                overlay_type: overlayType,
              });
            }}
          >
            Cancel Revisit
          </Button>
        </Popconfirm>
      }
    </>
  );
};

export default Confirmation;
