import React from "react";
import PropTypes from "prop-types";
import "./Note.less";

const Note = (props) => {
  const { timestamp, user, noteText, label, modelNumber, workOrderNumber } =
    props;

  return (
    <div className="single-note-container" data-testid="single-note-container">
      <div className="note-details">
        <span className="timestamp">{timestamp.toUpperCase()} | </span>
        <span className="username">{user}: </span>
      </div>
      <div className="note">
        {label && (
          <span className="note-label">
            {`${label}${
              modelNumber
                ? ` (${modelNumber}):`
                : `${workOrderNumber ? ` (${workOrderNumber}):` : ":"}`
            }`}{" "}
          </span>
        )}
        {noteText}
      </div>
    </div>
  );
};

Note.propTypes = {
  timestamp: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  noteText: PropTypes.string.isRequired,
  label: PropTypes.string,
  modelNumber: PropTypes.string,
  workOrderNumber: PropTypes.string,
};

export default Note;
