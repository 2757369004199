import React, { useEffect, useContext } from "react";
import { PFContext } from "../context/RootContext";
import Loading from "./Loading/Loading";

const PFLogic = ({ children }) => {
  const { data, loading, error, identityMeCall } = useContext(PFContext);

  useEffect(() => {
    if (!loading) {
      identityMeCall();
    }
  }, []);
  if (loading) {
    return "Loading";
  }
  if (error) {
    return "";
  }

  if (data) {
    return children;
  }
  return <Loading />;
};

export default PFLogic;
