import React, { useMemo, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./SearchBarComponent.less";
import { Input, Select, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useMatch, useResolvedPath } from "react-router-dom";
import { getOptions } from "./SearchBarOptions";
import RoutePlannerViewContext from "../../../context/RoutePlannerViewContext";

/**
 * NOTE: This component is currently a work in progress and not yet adopted in the codebase outside of route-planner view
 *
 * This component automatically populates the available options by checking the route
 * The default option is automatically selected by checking for the "default" entry
 */
export default function SearchBarComponent({ configOverride }) {
  // Config
  const resolvedPath = useResolvedPath(useMatch("*").pathname);
  const { defaultOption, standardOptions } = getOptions(
    configOverride,
    resolvedPath
  );

  // Hooks
  const navigate = useNavigate();
  const routePlannerViewContext = useContext(RoutePlannerViewContext);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchType, setSearchType] = useState("");

  useEffect(() => {
    setSearchType(routePlannerViewContext.searchType);
    setSearchTerm(routePlannerViewContext.searchTerm);
  }, [routePlannerViewContext.searchType, routePlannerViewContext.searchTerm]);

  function getCurrentOption() {
    if (searchType) {
      return standardOptions.find((option) => option.value === searchType);
    }

    return defaultOption;
  }

  const disableSearchButton = useMemo(() => {
    const currentOption = getCurrentOption();

    if (searchType && currentOption) {
      return !isInputValid(searchTerm, currentOption.validationString);
    }
    return true;
  }, [searchTerm, searchType]);

  return (
    <div className="search-bar-container">
      <Select
        className="search-bar-select"
        showArrow={false}
        defaultValue={getCurrentOption().displayName}
        value={getCurrentOption().displayName}
        onChange={(selection) => {
          setSearchType(selection);
        }}
      >
        {standardOptions.map((option, index) => (
          <Select.Option key={index} value={option.value} role="option">
            {option.displayName}
          </Select.Option>
        ))}
      </Select>
      <Input
        className="search-bar-input"
        placeholder={getCurrentOption().placeholder}
        value={searchTerm ? searchTerm : undefined}
        onPressEnter={() => {
          if (isInputValid(searchTerm, getCurrentOption().validationString)) {
            navigate(`?${searchType}=${searchTerm}`);
          }
        }}
        addonAfter={
          <Button
            className="search-button"
            type="text" //This applies a transparent background
            icon={<SearchOutlined />}
            disabled={disableSearchButton}
            onClick={() => {
              navigate(`?${searchType}=${searchTerm}`);
            }}
          />
        }
        onChange={(event) => {
          setSearchTerm(event.target.value);
        }}
      />
    </div>
  );
}

function isInputValid(input, validationString) {
  return new RegExp(validationString, "i").test(input);
}
