import { useState, useEffect, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";
import LocationContext from "../../context/LocationContext";
import { API_CONFIG } from "../../util/RoutePlanner/routePlannerUtils";
import getDrdsBffUrl from "../../util/RoutePlanner/getDrdsBffUrl";
import axios from "axios";
import useNotification from "../../hooks/useNotification";

import { checkPageType, identifyTab } from "./useContextManager";

export const useRouteDetailsData = () => {
  const routerLocation = useLocation();
  const locationContext = useContext(LocationContext);

  const [stopsData, setStopsData] = useState([]);
  const [driverData, setDriverData] = useState({});

  const previousQuery = useRef({});

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [enableDownloadManifest, setEnableDownloadManifest] = useState(false);

  useEffect(() => {
    setStopsData([]);
    setDriverData({});
  }, [locationContext.currentLocation]);

  useEffect(() => {
    if (checkPageType(routerLocation) === "Details") {
      const queryParams = queryStringDecoder(routerLocation);
      const tab = identifyTab(routerLocation);

      if (
        isQueryValid(tab, queryParams) &&
        isQueryChanged(tab, queryParams, previousQuery)
      ) {
        const apiQuery = apiQueryBuilder(
          tab,
          queryParams,
          locationContext.currentLocation
        );
        fetch(apiQuery);
      }
    }
  }, [routerLocation]);

  async function fetch(apiQuery) {
    setStopsData([]);
    setDriverData({});
    try {
      //Make an axios call here using url+location
      const drdsApiResponse = await axios.get(
        `${getDrdsBffUrl()}${API_CONFIG.BASE_URL}${apiQuery}`
      );
      const [data] = drdsApiResponse.data;
      const { stops } = data;

      if (data && data.length !== 0) {
        setEnableDownloadManifest(true);
      }
      setStopsData(stops); //TODO: This data possibly needs to be uniquely keyed
      setDriverData({
        date: data.date,
        carrierName: data.carrierName,
        routeId: data.routeId,
        truckId: data.truckId,
        driverName: data.driverName,
        driverId: data.driverId,
      });
    } catch (error) {
      console.error("Error fetching route details:", error);
      setEnableDownloadManifest(false);
      setError(error.message);
      setStopsData([]);
      setDriverData({});
    } finally {
      setLoading(false);
    }
  }

  return {
    detailsData: { stopsData, driverData },
    loading,
    error,
    enableDownloadManifest,
  };
};

export function queryStringDecoder(routerLocation) {
  const queryDict = {};
  const pathArray = routerLocation.pathname.split("/");
  queryDict.routeId = pathArray[pathArray.length - 1];
  return queryDict;
}

export function isQueryValid(tab, queryParams) {
  return queryParams.routeId !== undefined && tab !== undefined;
}
export function isQueryChanged(tab, queryParams, previousQuery) {
  const changed =
    previousQuery.current.tab !== tab ||
    previousQuery.current.routeId !== queryParams.routeId;
  if (changed) {
    previousQuery.current = { ...queryParams, tab };
  }
  return changed;
}

export function apiQueryBuilder(tab, queryParams, currentLocation) {
  return `/detail?tab=${tab}&location=${currentLocation.locationNumber}&routeId=${queryParams.routeId}`;
}
