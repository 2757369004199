import moment from "moment-timezone";

export default (timeStamp, format) => {
  return moment
    .tz(new Date(timeStamp), "America/New_York")
    .locale("en")
    .format(format);
};

export function formatTime(timestamp) {
  // Split the string on 'T' to separate the date and time
  const [date, time] = timestamp.split("T");

  // Split the time string on '-' to separate the time and UTC offset
  const [timeOnly, offset] = time.split("-");
  if (timeOnly === "") {
    return "Invalid time";
  }

  // Remove the seconds from the time string
  const [hours, minutes] = timeOnly.slice(0, -3).split(":");

  let hour = parseInt(hours, 10);
  let ampm = "AM";

  if (hour === 0) {
    hour = 12;
  } else if (hour >= 12) {
    ampm = "PM";
    hour = hour % 12 || 12;
  }

  hour = hour.toString().padStart(2, "0");

  return `${hour}:${minutes} ${ampm}`;
}

export function formatTo12Hour(timestamp) {
  let [hour] = timestamp.split(":");
  const [, minute, second] = timestamp.split(":");
  let meridiem = "XM";

  if (parseInt(hour) === 0 || parseInt(hour) === 24) {
    hour = 12;
    meridiem = "AM";
  } else if (hour === 12) {
    meridiem = "PM";
  } else if (hour > 12) {
    hour = (hour - 12).toString().padStart(2, "0"); // add leading zeroes to single digit hours
    meridiem = "PM";
  } else {
    meridiem = "AM";
  }

  return `${hour}:${minute} ${meridiem}`;
}

export function formatTo12HourRP(timestamp) {
  let [hour] = timestamp.split(":");
  const [, minute, second] = timestamp.split(":");
  let meridiem = "XM";

  if (parseInt(hour) === 0 || parseInt(hour) === 24) {
    hour = 12;
    meridiem = "AM";
  } else if (hour === 12) {
    meridiem = "PM";
  } else if (hour > 12) {
    hour = (hour - 12).toString().padStart(2, "0"); // add leading zeroes to single digit hours
    meridiem = "PM";
  } else {
    meridiem = "AM";
  }

  return `${parseInt(hour, 10)}:${minute} ${meridiem}`;
}

export function convertDateFormat(dateStr) {
  const [year, month, day] = dateStr.split("-");
  return `${parseInt(month, 10)}/${parseInt(day, 10)}/${year}`;
}

export const getTodayDate = (dateFormat = "YYYY-MM-DD") => {
  return moment().locale("en").format(dateFormat);
};
