import Axios from "axios";
import {
  CLOSE_MAIL_REQUEST,
  CLOSE_MAIL_SUCCESS,
  CLOSE_MAIL_FAIL,
  CLEAR_CLOSE_MAIL,
} from "../constants/closeMailMessageConstants.js";
import getBffUrl from "../../util/getBffUrl";

export const closeMailMessage = (dispatch) => async (ldap, mailMsgId) => {
  dispatch({
    type: CLOSE_MAIL_REQUEST,
  });
  try {
    const { data } = await Axios.put(
      `${getBffUrl()}/mail/v1/closeMailMessage?ldap=${ldap}&mailMsgId=${mailMsgId}`,
      {}
    );

    dispatch({
      type: CLOSE_MAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: CLOSE_MAIL_FAIL, payload: message });
  }
};

export const cleanCloseMailMessage = (dispatch) => () => {
  dispatch({
    type: CLEAR_CLOSE_MAIL,
  });
};
