import React from "react";
import "./TimeWindow.less";
import { formatTo12Hour } from "../../../../util/DateFormatter";

function TimeWindow({ deliveryDetail }) {
  const { deliveryStartDateTime, deliveryEndDateTime } = deliveryDetail;
  const [deliveryStartDate, deliveryStartTime] =
    deliveryStartDateTime.split("T");
  const [, deliveryEndTime] = deliveryEndDateTime.split("T");
  return (
    <div className="time-window-card">
      <div className="delivery-status">Scheduled for {deliveryStartDate}</div>
      <div className="time-window">
        {formatTo12Hour(deliveryStartTime)} - {formatTo12Hour(deliveryEndTime)}
      </div>
      {/* <div className="time-window-btns-wrapper">
        <Button size="small" type="link">
          Modify
        </Button>

        <Button size="small" type="link">
          Reset
        </Button>
      </div> */}
    </div>
  );
}

export default TimeWindow;
