import React from "react";
import deliverySlotsReducer from "../context/reducers/deliverySlotsReducer";
import deliverySlotsInitialState from "../context/initialStates/deliverySlotsInitialState";
import {
  cleanDeliverySlotsAction,
  getDeliverySlotsAction,
  getRevisitSlotsAction,
} from "../context/actions/deliverySlotsActions";
import * as constants from "../context/constants/deliverySlotsConstants";

export default function DeliveriesHook(
  typeOfDelivery = constants.REGULAR_DELIVERY
) {
  const [deliverySlotsState, deliverySlotsDispatch] = React.useReducer(
    deliverySlotsReducer,
    deliverySlotsInitialState
  );
  const getDeliverySlots =
    typeOfDelivery === constants.REGULAR_DELIVERY
      ? getDeliverySlotsAction(deliverySlotsDispatch)
      : getRevisitSlotsAction(deliverySlotsDispatch);

  const cleanDeliverySlots = cleanDeliverySlotsAction(deliverySlotsDispatch);
  const slotsByDate = deliverySlotsState.data;
  return {
    deliverySlotsState,
    cleanDeliverySlots,
    getDeliverySlots,
    slotsByDate,
  };
}
