import Axios from "axios";
import {
  IDENTITY_ME_REQUEST,
  IDENTITY_ME_SUCCESS,
  IDENTITY_ME_FAIL,
} from "../constants/pfConstants";
import { navigate } from "./actions.utils";
const BASE_URL = process.env.REACT_APP_BASE_URL || window.location.origin;
const LOGIN_URL_LOCAL =
  process.env.REACT_APP_LOGIN_URL_LOCAL ||
  "http://localhost:8000/identity/login";
const LOGIN_URL_PROD = `${BASE_URL}/identity/login`;

export const identityMeCall = (dispatch) => async () => {
  dispatch({
    type: IDENTITY_ME_REQUEST,
  });

  try {
    const { data } = await Axios.get(`${BASE_URL}/identity/me`, {
      timeout: 5000,
    });
    dispatch({
      type: IDENTITY_ME_SUCCESS,
      payload: {
        ldapId: data.subject,
        userType: data.decoded_claims.user_type,
      },
    });
  } catch (error) {
    console.error("Error in identityMeCall:", error.toString());

    const currentUrl = encodeURIComponent(window.location.href);
    const loginUrl =
      window.location.origin === "http://localhost:3000"
        ? `${LOGIN_URL_LOCAL}?next=${currentUrl}`
        : `${LOGIN_URL_PROD}?next=${currentUrl}`;

    navigate(loginUrl);

    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: IDENTITY_ME_FAIL,
      payload: message,
    });
  }
};
