import React from "react";
import "./PurchaseOrder.less";
import PropTypes from "prop-types";

const CancelledProductLine = (props) => {
  const { productLine } = props;

  return (
    <div id="cancelled-product-line-content">
      <div className="cancelled-model-number-label">Model #</div>
      <div className="cancelled-model-number">{productLine.modelNbr}</div>
      <div className="cancelled-sku-number-label">Store SO SKU</div>
      <div className="cancelled-sku-number">{productLine.skuNbr}</div>
    </div>
  );
};

CancelledProductLine.propTypes = { productLine: PropTypes.object.isRequired };

export default CancelledProductLine;
