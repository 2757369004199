import { GE_PR_VENDOR_NUMBER } from "../../util/Constants";
import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  CHANGE_ROLE,
} from "../constants/userDetailsConstants";

const mvendorNumPicker = (list, role) => {
  const findVendorNumber = (role) => {
    const filtered = list.filter((i) => i.vendorType === role);
    if (filtered.length === 1 && filtered[0].vendorNumber !== undefined) {
      return filtered[0].vendorNumber;
    }
    return null;
  };

  if (list) {
    if (role === "ADMS Vendors") {
      return findVendorNumber("OEM");
    } else if (role === "PR ADMS Vendors") {
      return GE_PR_VENDOR_NUMBER;
    } else {
      return findVendorNumber("DV");
    }
  }
  return null;
};

const userDetailsReducer = (state, { payload, type }) => {
  switch (type) {
    case USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case USER_DETAILS_SUCCESS:
      return {
        loading: false,
        user: payload,
        error: false,
      };
    case USER_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case CHANGE_ROLE:
      return {
        ...state,
        user: {
          ...state.user,
          roles: payload[0] === "PR ADMS Vendors" ? ["ADMS Vendors"] : payload,
          mvendorNum: mvendorNumPicker(state.user.mvendorList, payload[0]),
        },
      };
    default:
      return state;
  }
};

export default userDetailsReducer;
