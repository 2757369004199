import React from "react";
import { Button } from "antd";
import "./ShipToHomeReschedule.less";
import useNewRelicMetrics from "../../../../hooks/newRelicMetricsHook";
import { RESCHEDULE_EVENT } from "../../../../util/NewRelicConstants";

export const ShipToHomeReschedule = ({
  orderIndex,
  customerOrderNumber,
  setDrawerOpen,
}) => {
  const { logNewRelicMetricsEvent } = useNewRelicMetrics();

  return (
    <div className="sth-reschedule-container">
      <Button
        type="primary"
        onClick={() => {
          logNewRelicMetricsEvent(RESCHEDULE_EVENT, {
            order_number: customerOrderNumber,
            text: "button",
            index: orderIndex,
          });
          setDrawerOpen(true);
        }}
      >
        Reschedule
      </Button>
    </div>
  );
};
