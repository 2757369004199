export function getOptions(override, matchObject) {
  switch (typeof override) {
    case "object":
      return override;
    case "string":
      return DATA[override] === undefined ? [] : DATA[override];
    default:
      switch (matchObject.pathname.toLowerCase()) {
        case "/route-planner/routes/archive":
          return DATA.archive;
        case "/route-planner/routes/planning":
          return DATA.planning;
        case "/route-planner/routes/dispatch":
          return DATA.dispatch;
        case "/route-planner/routes/unassigned":
          return DATA.unassigned;
        default:
          return [
            {
              displayName: "Invalid",
              value: "default",
              placeholder: "Invalid",
              validationString: "(?!.*)",
            },
          ];
      }
  }
}

// D.R.Y.
const DEFAULT_OPTION = {
  displayName: "Search by",
  value: "default",
  placeholder: "Type here to search",
  validationString: "(?!.*)",
};

/**
 * How to use:
 * Edit cases above to modify the option set to return for each route
 * Every option set entry must have an entry with "default" as the value
 * The displayName is what's shown in the UI
 * The value is what's used to interact with APIs
 * The placeholder is what's shown in the input field
 */
const DATA = {
  archive: {
    defaultOption: DEFAULT_OPTION,
    standardOptions: [
      {
        displayName: "Order number",
        value: "customerOrderNumber",
        placeholder: "ex H0121-127156, W123456789, C123456789",
        validationString: "^([Hh][0-9]+-[0-9]+)$|^([WwCc][0-9a-zA-Z]+)$",
      },
      {
        displayName: "Customer last name",
        value: "customerLastName",
        placeholder: "ex Smith, Jones",
        validationString: "^[^%\\\\'\"]+$",
      },
      {
        displayName: "MSN",
        value: "msNumber",
        placeholder: "ex 1AHV18L8NX",
        validationString: "^[a-z0-9]{10}$",
      },
    ],
  },
  dispatch: {
    defaultOption: DEFAULT_OPTION,
    standardOptions: [],
  },
  planning: {
    defaultOption: DEFAULT_OPTION,
    standardOptions: [],
  },
  unassigned: {
    defaultOption: DEFAULT_OPTION,
    standardOptions: [],
  },
};
