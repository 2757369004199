import React, { useContext, useEffect, useState } from "react";
import "./InlineDeliveryWindow.less";
import { Input, Select, Button } from "antd";
import axios from "axios";
import { UserContext } from "../../context/RootContext";
import PropTypes from "prop-types";
import getBffUrl from "../../../app/util/getBffUrl";

const InlineDeliveryWindow = (props) => {
  const [startDeliveryTimeWindow, setStartDeliveryTimeWindow] = useState("");
  const [endDeliveryTimeWindow, setEndDeliveryTimeWindow] = useState("");
  const [
    startDeliveryTimeWindowDropDownValue,
    setStartDeliveryTimeWindowDropDownValue,
  ] = useState("AM");
  const [
    endDeliveryTimeWindowDropDownValue,
    setEndDeliveryTimeWindowDropDownValue,
  ] = useState("PM");
  const [isValidInput, setIsValidInput] = useState(false);
  const { user } = useContext(UserContext);
  const { ldapId } = user;
  const lastUpdTimeStamp = new Date(new Date().getTime()).toString();
  const { Option } = Select;

  useEffect(() => {
    let validDeliveryWindow = false;
    if (
      startDeliveryTimeWindow &&
      startDeliveryTimeWindowDropDownValue &&
      endDeliveryTimeWindow &&
      endDeliveryTimeWindowDropDownValue
    ) {
      if (
        convertTime12to24(
          `${startDeliveryTimeWindow} ${startDeliveryTimeWindowDropDownValue}`
        ) <
        convertTime12to24(
          `${endDeliveryTimeWindow} ${endDeliveryTimeWindowDropDownValue}`
        )
      ) {
        validDeliveryWindow =
          new RegExp("(((0[1-9])|(1[0-2])):([0-5][0-9]))").test(
            startDeliveryTimeWindow
          ) &&
          new RegExp("(((0[1-9])|(1[0-2])):([0-5][0-9]))").test(
            endDeliveryTimeWindow
          );
        setIsValidInput(validDeliveryWindow);
      } else {
        setIsValidInput(false);
      }
    } else {
      setIsValidInput(false);
    }
  }, [
    startDeliveryTimeWindow,
    startDeliveryTimeWindowDropDownValue,
    endDeliveryTimeWindow,
    endDeliveryTimeWindowDropDownValue,
  ]);

  function addColon(event) {
    if (event.target.value.length === 2 && event.key !== "Backspace") {
      event.target.value = `${event.target.value}:`;
    }
  }

  const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(" ");
    const splitTime = time.split(":");
    if (splitTime[0] === "12") {
      splitTime[0] = "00";
    }
    if (modifier === "PM") {
      splitTime[0] = parseInt(splitTime[0], 10) + 12;
    }
    return `${splitTime[0]}:${splitTime[1]}`;
  };

  function determineResetFlag() {
    if (
      `${startDeliveryTimeWindow} ${startDeliveryTimeWindowDropDownValue}` ===
        "12:00 AM" &&
      `${endDeliveryTimeWindow} ${endDeliveryTimeWindowDropDownValue}` ===
        "11:59 PM"
    ) {
      return "Y";
    } else {
      return "N";
    }
  }

  function buildRequest() {
    return {
      referenceId: props.referenceId,
      startDeliveryTimeWindow: `${startDeliveryTimeWindow} ${startDeliveryTimeWindowDropDownValue}`,
      endDeliveryTimeWindow: `${endDeliveryTimeWindow} ${endDeliveryTimeWindowDropDownValue}`,
      lastUpdTimeStamp: lastUpdTimeStamp,
      lastUpdUserId: ldapId,
      resetFlag: determineResetFlag(),
    };
  }

  function saveDeliveryWindow() {
    const request = buildRequest();
    axios
      .patch(`${getBffUrl()}/deliveryOrder/v1/deliveryWindow`, request)
      .then((resp) => {
        if (resp.status === 200) {
          props.setDeliveryWindowFromInline(
            startDeliveryTimeWindow,
            startDeliveryTimeWindowDropDownValue,
            endDeliveryTimeWindow,
            endDeliveryTimeWindowDropDownValue
          );
          props.openInlineComponent();
          props.setImageStatus();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className={"delivery-window-container"}>
      <div id={"change-delivery-label"}>Change Delivery Window</div>
      <div className={"inline-window"}>
        <div className={"start-time-div"}>
          <Input
            className={"time-input"}
            id={"time-input-text-box"}
            placeholder={"12:00"}
            onChange={(event) => {
              setStartDeliveryTimeWindow(event.target.value);
            }}
            maxLength={5}
            onkeyup={(event) => {
              addColon(event);
            }}
          />
          <Input.Group className={"time-dropdown"} compact>
            <Select
              size="large"
              onChange={(value) => {
                setStartDeliveryTimeWindowDropDownValue(value);
              }}
              value={startDeliveryTimeWindowDropDownValue}
            >
              <Option className="search-option" value="AM">
                AM
              </Option>
              <Option className="search-option" value="PM">
                PM
              </Option>
            </Select>
          </Input.Group>
        </div>
        <div className={"end-time-div"}>
          <Input
            className={"time-input"}
            id={"time-input-text-box"}
            placeholder={"04:00"}
            onChange={(event) => {
              setEndDeliveryTimeWindow(event.target.value);
            }}
            maxLength={5}
            onkeyup={(event) => {
              addColon(event);
            }}
          />
          <Input.Group className={"time-dropdown"} compact>
            <Select
              size="large"
              onChange={(value) => {
                setEndDeliveryTimeWindowDropDownValue(value);
              }}
              value={endDeliveryTimeWindowDropDownValue}
            >
              <Option className="search-option" value="AM">
                AM
              </Option>
              <Option className="search-option" value="PM">
                PM
              </Option>
            </Select>
          </Input.Group>
        </div>
      </div>
      <div id={"button-option"}>
        <Button
          id={"save-button"}
          type="default"
          block
          size="small"
          disabled={!isValidInput}
          onClick={() => {
            saveDeliveryWindow();
          }}
        >
          Save
        </Button>
        <Button
          id={"cancel-button"}
          type="secondary"
          size="small"
          onClick={props.openInlineComponent}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

InlineDeliveryWindow.propTypes = {
  referenceId: PropTypes.string,
  scheduledDeliveryDate: PropTypes.string.isRequired,
  openInlineComponent: PropTypes.func.isRequired,
  setDeliveryWindowFromInline: PropTypes.func.isRequired,
  setImageStatus: PropTypes.func.isRequired,
};

export default InlineDeliveryWindow;
