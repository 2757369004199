import { useRef, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SearchContext from "../context/SearchContext";
const defaultOptions = {
  path: undefined,
  navigateOptions: {
    replace: false,
  },
};

/**
 *  useSearchState
 *  @param defaultValues Object
 *  @param options Object
 *  @param options.path string - the url path that save will write to. Defults to window.location.pathname
 *  @param options.navigationOptions - the react-router-dom navigation options. This can also be set on save(navigationOptions)
 *  @todo parse values/persistedValues for type. "1" should be 1,
 *        "true" should be true, "one,two" should be ["one", "two"]
 */
export const useSearchStateHook = (defaultValues, options = defaultOptions) => {
  const location = useLocation();
  const navigate = useNavigate();
  const locationSearchRef = useRef(location.search);

  const { sanitizer } = options;
  const queryParams = parseQueryString(location.search, defaultValues);

  const sanitizedQueryParams = sanitizer ? sanitizer(queryParams) : queryParams;
  const searchContext = useContext(SearchContext);
  const values = {
    ...defaultValues,
    ...sanitizedQueryParams,
    ...searchContext.values,
  };

  useEffect(() => {
    if (locationSearchRef.current !== location.search) {
      locationSearchRef.current = location.search;
      searchContext.setValues(sanitizedQueryParams);
    }
  }, [location.search]);

  const persist = (
    navOptions = {},
    additionalValues = {},
    persistOpts = {}
  ) => {
    let newValues = { ...values };

    if (Object.keys(additionalValues).length) {
      const cleaned = update(additionalValues);
      newValues = {
        ...newValues,
        ...cleaned,
      };
    }

    if (persistOpts.resetExcept) {
      newValues = {
        ...defaultValues,
        ...persistOpts.resetExcept.reduce((acc, key) => {
          return {
            ...acc,
            [key]: newValues[key],
          };
        }, {}),
      };
    }

    const asString = toString(newValues);

    navigate(
      {
        pathname: options.path || location.pathname,
        search: asString,
      },
      {
        replace: !!navOptions.replace,
        ...options.navigateOptions,
        ...navOptions,
      }
    );
  };

  const reset = (navOptions = {}) => {
    searchContext.setValues({ ...defaultValues });
    const asString = toString({ ...defaultValues });

    navigate(
      {
        pathname: options.path || location.pathname,
        search: asString,
      },
      {
        ...options.navigateOptions,
        ...navOptions,
      }
    );
  };

  const update = (
    updateValues,
    { persist = false, reset = false, navOptions = {} } = {}
  ) => {
    const existing = reset ? defaultValues : values;

    const newValues = { ...existing, ...updateValues };
    const cleanedValues = sanitizer ? sanitizer(newValues) : newValues;

    if (!persist) {
      searchContext.setValues(cleanedValues);
    } else {
      const asString = toString(cleanedValues);

      const opts = {
        ...options.navigateOptions,
        ...navOptions,
      };

      navigate(
        {
          pathname: options.path || location.pathname,
          search: asString,
        },
        {
          replace: !!navOptions.replace,
          ...options.navigateOptions,
          ...navOptions,
        }
      );
    }
    return cleanedValues;
  };

  const resetFilters = () => {
    searchContext.filters.current = {};
    const keysToKeep = ["type", "input", "date"];
    persist({ replace: true }, {}, { resetExcept: keysToKeep });
  };

  return {
    persist,
    persistedValues: sanitizedQueryParams,
    values: values,
    update,
    reset,
    resetFilters,
  };
};

function parseQueryString(queryString, defaultValues = {}) {
  return queryString
    .substring(1, queryString.length)
    .split("&")
    .map((paramPair) => paramPair.split("="))
    .filter((val) => {
      return !!val[0];
    })
    .reduce((acc, cur) => {
      const [key, value] = cur;
      let val = value;
      if (Array.isArray(defaultValues[key])) {
        val = decodeURIComponent(val).split(",");
      } else {
        val = decodeURIComponent(val);
      }
      return {
        ...acc,
        [key]: val,
      };
    }, {});
}

function toString(values) {
  return Object.keys(values)
    .filter((key) => values[key] !== "" && typeof values[key] !== "undefined") //keep null to remove keys
    .map((key) => {
      const value = values[key];
      let val = value;
      if (Array.isArray(value)) {
        val = value.join(",");
      }
      return `${key}=${values[key]}`;
    })
    .join("&");
}
