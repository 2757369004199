import Axios from "axios";
import {
  CREATE_MANUAL_ORDER_REQUEST,
  CREATE_MANUAL_ORDER_SUCCESS,
  CREATE_MANUAL_ORDER_FAIL,
  CLEAN_CREATE_MANUAL_ORDER,
} from "../constants/manualOrderConstants";
import getBffUrl from "../../util/getBffUrl";

export const createManualOrderAction =
  (dispatch) =>
  async ({ request }) => {
    dispatch({
      type: CREATE_MANUAL_ORDER_REQUEST,
    });
    try {
      await Axios.post(
        `${getBffUrl()}/deliveryOrder/v2/createManualOrder`,
        request
      )
        .then((response) => {
          if (response && response.status === 200) {
            dispatch({
              type: CREATE_MANUAL_ORDER_SUCCESS,
              payload: response.data,
            });
          }
        })
        .catch((error) => {
          const message =
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message;
          const description =
            error.response &&
            error.response.data &&
            error.response.data.description;
          const status = error.response && error.response.status;
          dispatch({
            type: CREATE_MANUAL_ORDER_FAIL,
            payload: {
              message: message,
              description: description,
              status: status,
            },
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

export const cleanCreateManualOrderAction = (dispatch) => () => {
  dispatch({
    type: CLEAN_CREATE_MANUAL_ORDER,
  });
};
