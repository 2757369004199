import { Button } from "antd";
import React, { useContext } from "react";
import "./WorkOrder.less";
import permit from "../../../../util/Permissions";
import { UserContext } from "../../../../context/RootContext";
import DashboardContext from "../../../../context/DashboardContext";
import { DASHBOARD_STH_CONTEXT } from "../../../../util/Constants";
import useNewRelicMetrics from "../../../../hooks/newRelicMetricsHook";
import {
  ALL_WORK_ORDERS_EVENT,
  WORK_BIN_ALL_WORK_ORDERS_EVENT,
} from "../../../../util/NewRelicConstants";

function WorkOrder({
  orderIndex,
  isWorkBin,
  customerOrderNumber,
  workOrderNumber,
  purchaseOrderDetail,
  deliveryDetail,
  storeNumber,
  source,
  isAppliance,
}) {
  const { user } = useContext(UserContext);
  const dashboardContext = useContext(DashboardContext);
  const { logNewRelicMetricsEvent } = useNewRelicMetrics();
  const isSTHContext = dashboardContext === DASHBOARD_STH_CONTEXT;
  const { palletCount } = deliveryDetail;

  function constructProductText(purchaseOrderDetails) {
    const count = isAppliance
      ? purchaseOrderDetails
        ? purchaseOrderDetails.reduce(
            (acc, purchaseOrder) => acc + purchaseOrder.lineItems.length,
            0
          )
        : 0
      : [
          ...new Set(
            purchaseOrderDetails.flatMap((purchaseOrder) =>
              purchaseOrder.lineItems.map((item) => item.skuNumber)
            )
          ),
        ].length;

    const itemType =
      count === 1
        ? isAppliance
          ? "sku"
          : "item"
        : isAppliance
        ? "skus"
        : "items";

    return `${count} ${itemType} in this Delivery`;
  }

  return (
    <div className="work-order-card">
      <div className="order-number">{customerOrderNumber}</div>
      <div className="work-order-text">WORK ORDER #</div>
      <div className="work-order-number">{workOrderNumber}</div>
      {purchaseOrderDetail && (
        <div className="work-order-count">
          {constructProductText(purchaseOrderDetail)}
        </div>
      )}
      {palletCount && (
        <div className="work-order-pallet-count">
          Pallet Quantity: {palletCount}
        </div>
      )}
      {source && <div className="work-order-source">Source: {source}</div>}
      {!isSTHContext && (
        <a
          target="_blank"
          rel="noreferrer"
          href={`${process.env.REACT_APP_WORK_ORDER_URL}/${customerOrderNumber}?storeNumber=${storeNumber}`}
        >
          {permit("allWorkOrdersButton", user) && (
            <Button
              type="link"
              onClick={() => {
                const eventType = isWorkBin
                  ? WORK_BIN_ALL_WORK_ORDERS_EVENT
                  : ALL_WORK_ORDERS_EVENT;
                logNewRelicMetricsEvent(eventType, {
                  order_number: customerOrderNumber,
                  index: orderIndex,
                });
              }}
            >
              All Work Orders
            </Button>
          )}
        </a>
      )}
    </div>
  );
}

export default WorkOrder;
