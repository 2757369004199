import { useState } from "react";
import {
  mapImagesWithOrder,
  getProductDetails,
} from "../../util/orderDetailsUtilities";

function useSTHOrderDetails(data = {}) {
  const [orderDetails, setOrderDetails] = useState({});
  const { sthDeliveries } = data ? data : {};

  const sthOrderDetailsHandler = {
    onExpand: async (expanded, record) => {
      if (expanded) {
        setOrderDetails((prevDetails) => ({
          ...prevDetails,
          [record.key]: {
            data: {},
            loading: true,
          },
        }));
        await sthOrderDetailsHandler.updateOrderDetails(record);
      }
    },
    updateOrderDetails: async (record) => {
      const normalizedData = normalizeSTHOrderDetail(
        sthDeliveries.find(
          (delivery) => delivery.reservationId === record.reservationId
        ),
        data.notes
      );

      // If the normalizedData has purchaseOrderDetail, enrich it with images
      const enrichedSTHOrderDetail =
        normalizedData && normalizedData.purchaseOrderDetail
          ? mapImagesWithOrder(
              normalizedData,
              await getProductDetails(normalizedData.purchaseOrderDetail)
            )
          : normalizedData;

      updateOrderDetailsState(record.key, enrichedSTHOrderDetail);
    },
    getOrderCardColor: (record) => {
      const sthDelivery = sthDeliveries.find(
        (delivery) => delivery.reservationId === record.reservationId
      );
      if (sthDelivery !== undefined) {
        if (sthDelivery.isCanceled) {
          return "gray";
        } else {
          switch (sthDelivery.currentDeliveryStatus) {
            case "COMPLETED":
              return "green";
            case "MSD_WO_ATT":
            case "MSD_W_ATT":
            case "COMP_W_EXP":
              return "red";
            default:
              return "blue";
          }
        }
      }
    },
  };
  const normalizeSTHOrderDetail = (data, notes) => {
    return {
      customerOrderNumber: data.deliveryDetail.customerOrderNumber,
      workOrderNumber: data.deliveryDetail.workOrder,
      reservationId: data.reservationId,
      sellerOrganizationCode: "",
      orderType: data.deliveryDetail.serviceType,
      source: "",
      customerDetail: data.customerDetail,
      deliveryDetail: data.deliveryDetail,
      purchaseOrderDetail: [data.purchaseOrderDetail], // The new endpoint has this as an object, but the old structure expects an array
      notes: notes ? notes : [],
      currentDeliveryStatus: data.currentDeliveryStatus,
      isCanceled: data.isCanceled,
      isReschedulable: data.isReschedulable,
      isAppliance: false,
    };
  };

  const updateOrderDetailsState = (key, data) => {
    setOrderDetails((prevState) => {
      return {
        ...prevState,
        [key]: {
          data: data,
          loading: false,
        },
      };
    });
  };

  return { orderDetails, ...sthOrderDetailsHandler };
}

export default useSTHOrderDetails;
