import { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import LocationContext from "../../context/LocationContext";

/**
 * Custom hook to reset the route to 'planning' when the location changes.
 *
 * This hook monitors changes in the current location's number. When a change
 * is detected, it navigates to the 'planning' route, effectively resetting
 * the application state for the new location.
 *
 * @function
 * @name useLocationChangeReset
 * @returns {void}
 *
 */
const useLocationChangeReset = () => {
  const { currentLocation } = useContext(LocationContext);
  const navigate = useNavigate();
  const prevLocationNumberRef = useRef();

  useEffect(() => {
    const currentLocationNumber = currentLocation?.locationNumber;

    if (
      prevLocationNumberRef.current !== undefined &&
      currentLocationNumber !== prevLocationNumberRef.current
    ) {
      navigate("planning", { replace: true });
    }

    prevLocationNumberRef.current = currentLocationNumber;
  }, [currentLocation?.locationNumber]);
};

export default useLocationChangeReset;
