import React, { useContext } from "react";
import {
  MailMessageContext,
  CreateMessageContext,
  UserContext,
} from "../../../context/RootContext";
import { Form, Select, Input, Button, Divider, Spin, Row, Col } from "antd";
import { CloseCircleTwoTone } from "@ant-design/icons";

export const CreateMail = ({ refMailMsgId, closer }) => {
  const { mailMessageState } = useContext(MailMessageContext);
  const { createMessageState, createMessage, cleanCreateMessage } =
    useContext(CreateMessageContext);
  const { loading, error, data } = createMessageState;
  const { user } = useContext(UserContext);
  const { ldapId, mailBoxId } = user;
  const { message } = mailMessageState;
  const [form] = Form.useForm();

  const categories = {
    1: "Damages/Returns",
    2: "Shortage",
    3: "Late Delivery",
    4: "POD",
    5: "Date Change",
    6: "Cancels",
    7: "50 Hour",
    8: "ASN Missing",
    9: "Non Label Product",
    10: "Miscellaneous",
    11: "Exception",
    12: "Overage",
    13: "Suspend",
    14: "Customer Refusal",
    15: "Freight Claim",
    16: "Pending",
    17: "Missing PaperWork",
    18: "Damage Allowance",
    19: "Damage But Accepted as Loaner",
    20: "Carrier/HDA",
    21: "Online Date Change",
  };
  // prod sandboxes
  const mailBoxes = {
    1: "Sandbox",
    2: "GE",
    201: "MAYTAG ",
    5: "MANIFEST",
    6: "Camco",
    7: "Maytag Canada",
    8: "LG",
    9: "LG Canada",
    11: "Electrolux",
    12: "Samsung",
    13: "Sharp",
    14: "NEWDV",
    15: "J.B. Hunt",
    16: "FSA",
    17: "BOSCH",
    18: "INNOVEL SOLUTIONS INC",
    200: "GE",
    123: "GEA",
    19: "Home Delivery America",
    22: "Daryl Flood Logistics",
    23: "TEMCO LOGISTICS",
    24: "LinnStar LOGISTICS",
    25: "North-South Delivery, LLC.",
    26: "Ralph's Transfer Co., Inc.",
    27: "R.A.S. LOGISTICS, INC.",
    28: "UNIVERSAL LOGISTICS OF VIRGINIA, DBA ESTES DEDICATED",
  };
  const priorities = {
    1: "High",
    2: "Low",
  };

  return (
    <>
      <div style={{ margin: "10px 10px 0px 10px" }}>
        {data && (
          <Row justify="space-between" align="middle">
            <Col>
              <Row align="middle">
                <img src="/images/success_check.svg" alt={"success"} />
                <div
                  id="successNewMessage"
                  style={{
                    fontSize: "16px",
                    color: "green",
                    paddingLeft: "20px",
                  }}
                >
                  Message sent successfully
                </div>
              </Row>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  cleanCreateMessage();
                  closer();
                }}
                type="primary"
              >
                Close form
              </Button>
            </Col>
          </Row>
        )}
        {!data && (
          <Form
            form={form}
            labelCol={{ span: 4 }}
            labelAlign="left"
            wrapperCol={{ span: 20 }}
            initialValues={
              refMailMsgId
                ? {
                    mailSubjDesc: message.mailSubjDesc,
                    msgTypeCode: categories[message.mailCategoryCode],
                    mailPriority: priorities[message.mailPriorityCode],
                  }
                : null
            }
            onFinish={(body) => {
              createMessage({
                request: {
                  ...body,
                  toMailBox: body.toMailBox.join(","),
                  refMailMsgId: refMailMsgId ? refMailMsgId.toString() : null,
                  mailPriority: refMailMsgId
                    ? message.mailPriorityCode.toString()
                    : body.mailPriority,
                  msgTypeCode: refMailMsgId
                    ? message.mailCategoryCode.toString()
                    : body.msgTypeCode,
                  userLdap: ldapId,
                },
                isNewMessage: refMailMsgId === undefined,
              });
            }}
          >
            <div style={{ height: "35px" }}>
              <Row align="middle">
                <Col span={10} offset={4}>
                  {!data && (
                    <h3>{refMailMsgId ? "Reply to" : "New"} message</h3>
                  )}
                </Col>
                <Col span={10}>
                  {loading && <Spin size="large" />}
                  {error && (
                    <CloseCircleTwoTone
                      twoToneColor="red"
                      style={{ fontSize: "40px" }}
                    />
                  )}
                </Col>
              </Row>
            </div>
            <br />
            <Form.Item name="mailPriority" label="Priority">
              <Select
                placeholder="Please select priority"
                disabled={refMailMsgId}
              >
                {Object.keys(priorities).map((opt) => (
                  <Select.Option value={opt} key={opt}>
                    {priorities[opt]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="msgTypeCode" label="Category">
              <Select
                placeholder="Please select category"
                disabled={refMailMsgId}
              >
                {Object.keys(categories).map((catNumber) => (
                  <Select.Option value={catNumber} key={catNumber}>
                    {categories[catNumber]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="toMailBox" label="To mail box">
              <Select placeholder="Please select mail box" mode="multiple">
                {Object.keys(mailBoxes)
                  .filter((mboxId) =>
                    mailBoxId !== undefined
                      ? mboxId !== mailBoxId.toString()
                      : true
                  )
                  .map((mboxId) => (
                    <Select.Option
                      value={mboxId}
                      key={mboxId}
                      id={`recipient-${mailBoxes[mboxId]}`}
                    >
                      {mailBoxes[mboxId]}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item name="mailSubjDesc" label="Subject">
              <Input disabled={refMailMsgId} />
            </Form.Item>
            <Form.Item name="msgBody" label="Message text">
              <Input.TextArea
                autoSize={{
                  minRows: 4,
                  maxRows: 8,
                }}
              ></Input.TextArea>
            </Form.Item>
            <Row justify="space-between">
              <Col offset={4}>
                <Row gutter={4}>
                  <Col>
                    <Button htmlType="submit" type="primary">
                      Submit form
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => {
                        form.resetFields();
                      }}
                    >
                      Clear form
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    cleanCreateMessage();
                    closer();
                  }}
                  type="primary"
                >
                  Close form
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </div>
      <Divider type="horizontal" style={{ borderTop: "5px solid #d9d9d9" }} />
    </>
  );
};
