import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ isAllowed, element, redirectPath, ...rest }) => {
  return isAllowed ? (
    React.cloneElement(element, rest)
  ) : (
    <Navigate to={redirectPath} replace />
  );
};

export default ProtectedRoute;
