import React, { useState, useEffect } from "react";
import "./AccessRequest.less";
import { Button, Col, Row } from "antd";
import getLogoutUrl from "../../util/getLogoutUrl";
import { Tooltip } from "antd";

const AccessRequest = () => {
  const [mdoUserDisplay, setMdoUserDisplay] = useState("");
  const [occUserDisplay, setOccUserDisplay] = useState("");
  const [nonUserDisplay, setNonUserDisplay] = useState("");
  const [othUserDisplay, setOthUserDisplay] = useState("");

  const MDO_TEXT = (
    <Tooltip placement="bottom" title="where XXXX is your location ID">
      <p>
        To get access to a specific location use{" "}
        <a
          href="https://accessrequestportal.homedepot.com"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          ARP Requests
        </a>{" "}
        for EMT_XXXX and DMP Workbin
      </p>
    </Tooltip>
  );
  const OCC_TEXT = "Request access through the Call Center";
  const NON_HD_TEXT = "Request access through your Partner Guardian";
  const OTHER_TEXT = "Please reach out to Support";

  const MDO = "MDO";
  const OCC = "OCC";
  const NON = "NON";
  const OTH = "OTH";

  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
  });

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.open(getLogoutUrl(), "_self");
  };

  function changeButtonDisplay(input) {
    switch (input) {
      case "MDO":
        setMdoUserDisplay(MDO_TEXT);
        setOccUserDisplay("");
        setNonUserDisplay("");
        setOthUserDisplay("");
        break;
      case "OCC":
        setOccUserDisplay(OCC_TEXT);
        setMdoUserDisplay("");
        setNonUserDisplay("");
        setOthUserDisplay("");
        break;
      case "NON":
        setNonUserDisplay(NON_HD_TEXT);
        setMdoUserDisplay("");
        setOccUserDisplay("");
        setOthUserDisplay("");
        break;
      case "OTH":
        setOthUserDisplay(OTHER_TEXT);
        setMdoUserDisplay("");
        setOccUserDisplay("");
        setNonUserDisplay("");
        break;
    }
  }

  return (
    <Row>
      <Col span={24}>
        <div className="access-request-container">
          <h1 id="access-request-subheader">You Dont Have Access Yet</h1>
          <img className="noAccessIcon" src="/images/noAccessDoorGray.svg" />
          <h2 id="access-request-subheader">What is your role?</h2>
          <br />
          <div>
            <h3 id="access-request-subheader">
              Once your access is approved, use your LDAP credentials to login.
            </h3>
          </div>
          <br />
          <br />
          <div id="access-request-row">
            <div className="access-request-label">
              <Button
                className="access-request-button"
                size="large"
                onClick={() => {
                  changeButtonDisplay(MDO);
                }}
              >
                MDO/FDC Associates
              </Button>
              <span id="mdoDisplay">{mdoUserDisplay}</span>
            </div>
            <div className="access-request-label">
              <Button
                className="access-request-button"
                size="large"
                onClick={() => {
                  changeButtonDisplay(OCC);
                }}
              >
                OCC Associates
              </Button>
              <span id="occDisplay">{occUserDisplay}</span>
            </div>
            <div className="access-request-label">
              <Button
                className="access-request-button"
                size="large"
                onClick={() => {
                  changeButtonDisplay(NON);
                }}
              >
                Non Home Depot Users
              </Button>
              <span id="nonHdDisplay">{nonUserDisplay}</span>
            </div>
            <div className="access-request-label">
              <Button
                className="access-request-button"
                size="large"
                onClick={() => {
                  changeButtonDisplay(OTH);
                }}
              >
                Other
              </Button>
              <span id="otherUserDisplay">{othUserDisplay}</span>
            </div>
          </div>
          <br />
          <br />
          <div className="logout">
            <button id="logout-link" onClick={() => logout()}>
              Close Page
            </button>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default AccessRequest;
