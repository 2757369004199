import React, { useContext, useEffect, useRef } from "react";
import "zone.js";
import "delivery-micro-client/delivery-micro-client";
import PropTypes from "prop-types";
import "./MicroUI.less";
import { UserContext, PFContext } from "../../context/RootContext";

const MicroUI = (props) => {
  const { workOrderNumber, location, orderNumber } = props;
  const { user } = useContext(UserContext);
  const { ldapId } = user;
  const { data } = useContext(PFContext);
  const { userType } = data;

  const microUI = useRef(null);
  const identifier = `DMPMICROUI_${workOrderNumber}`;
  const microUIUrl =
    userType === "Vendor"
      ? `${process.env.REACT_APP_MICRO_UI_EXTN_URL}`
      : `${process.env.REACT_APP_MICRO_UI_URL}`;
  const microUIConfig = {
    bootMode: "Edit",
    environment: microUIUrl,
    redirectUrl: `${window.location.origin}`,
    data: {
      userInfo: {
        ldapId: ldapId,
        location: location,
      },
      workOrderNumber: workOrderNumber,
      orderNumber: orderNumber,
    },
    isPickup: false,
    parent: "DMP",
    height: "800px",
    width: "100%",
    isCollapsedMode: false,
    skuType: "Appliance",
  };

  useEffect(() => {
    if (microUI && microUI.current) {
      microUI.current.config = microUIConfig;
    }
  });

  return (
    <div data-testid="micro-ui-wrapper">
      <delivery-micro-client identifier={identifier} ref={microUI} />
    </div>
  );
};
MicroUI.propTypes = {
  workOrderNumber: PropTypes.string,
  location: PropTypes.string,
  orderNumber: PropTypes.string,
};
export default MicroUI;
