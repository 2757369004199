import React, { useState } from "react";
import "./SearchResults.less";
import DeliveryDetails from "../../DeliveryDetails/DeliveryDetails";
import PropTypes from "prop-types";

const SearchResults = (props) => {
  const { deliveries } = props;

  const showDeliveryDate = Boolean(true);

  return (
    <div>
      <div className="order-container">
        {deliveries &&
          deliveries.unassignedOrders &&
          deliveries.unassignedOrders.map((order, orderIndex) => (
            <div key={orderIndex}>
              <DeliveryDetails
                showDeliveryDate={showDeliveryDate}
                deliveries={deliveries}
                orderDetails={order}
                key={orderIndex}
                orderIndex={order.orderIndex}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

SearchResults.propTypes = { deliveries: PropTypes.object.isRequired };

export default SearchResults;
