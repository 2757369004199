import React, { useContext, useState, useEffect } from "react";
import "./DateFilter.less";
import { Radio, DatePicker, Dropdown, Select } from "antd";
import moment from "moment";
import "moment/locale/zh-cn";
import { ReactComponent as DateCalendar } from "../../Images/calendar-date.svg";
import { DownOutlined } from "@ant-design/icons";
import SearchContext from "../../../../context/SearchContext";
import { UserContext } from "../../../../context/RootContext";
import LocationContext from "../../../../context/LocationContext";
import permit from "../../../../util/Permissions";
import useNewRelicMetrics from "../../../../hooks/newRelicMetricsHook";
import { SEARCH_CALENDAR_EVENT } from "../../../../util/NewRelicConstants";

const DateFilter = ({ hideDateSelection, onChange }) => {
  const calendarIcon = <DateCalendar />;
  const { Option } = Select;
  const { user } = useContext(UserContext);
  const { setSearchSelection } = useContext(SearchContext);
  const { setCurrentLocation } = useContext(LocationContext);
  const { locations } = user;
  const [visible, setVisible] = useState(false);
  const [dateOption, setDateOption] = useState("");
  const [dropdownVal, setDropdownVal] = useState(null);
  const [calendarValue, setCalendarValue] = useState(null);
  const { logNewRelicMetricsEvent } = useNewRelicMetrics();

  useEffect(() => {
    function getSearchDate() {
      let searchDate;
      if (dateOption === "Today") {
        searchDate = moment().format("MM/DD/YYYY");
      } else if (dateOption === "Tomorrow") {
        searchDate = moment().add(1, "days").format("MM/DD/YYYY");
      } else {
        searchDate = dateOption;
      }

      if (searchDate && searchDate !== "") {
        handleSearch(searchDate);
      }
    }

    function handleSearch(searchDate) {
      if (onChange) {
        onChange({ type: "DeliveryDate", date: searchDate });
      }
    }

    getSearchDate();
  }, [dateOption, setSearchSelection]);

  const makeUniqueId = (n) => {
    const needed_length = locations.length.toString().length;
    const arr = n.toString().split("");
    const zerosToAdd = needed_length - n.toString().length;
    for (let i = 0; i < zerosToAdd; i++) {
      arr.unshift("0");
    }
    return arr.join("");
  };

  const locationDropdownValues = locations
    .filter(
      (location) => location && location.locationNumber && location.locationName
    )
    .map((location, i) => (
      <Option value={makeUniqueId(i).toString(2)} key={i}>
        {location.locationName}
      </Option>
    ));

  const initializeDropDownItems = () => {
    return [
      {
        key: "0",
        className: "location-dropdown-menu",
        label: (
          <div>
            <p>Select location before proceeding</p>
            <p className="location-label">Location</p>
            <Select
              className="location-selector"
              popupClassName="locations"
              showSearch
              size="large"
              style={{ width: "100%" }}
              placeholder="Select..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value, e) => {
                setCurrentLocation(locations[Number(value)]);
                setDropdownVal(value);
                if (dropdownVal === null) {
                  setVisible(true);
                } else {
                  setVisible(false);
                }
              }}
            >
              {locationDropdownValues}
            </Select>
          </div>
        ),
      },
      {
        key: "1",
        className: "radio-group-menu",
        label: (
          <Radio.Group
            className="radio-group"
            disabled={
              permit("showLocationDropdownInDateFilter", user) &&
              dropdownVal === null
            }
            onChange={({ target }) => {
              logNewRelicMetricsEvent(SEARCH_CALENDAR_EVENT, {
                text: target.value,
              });
              setDateOption(target.value, moment());
              setCalendarValue(null);
            }}
            value={dateOption}
          >
            <Radio className="today" value="Today">
              Today
            </Radio>
            <Radio className="tomorrow" value="Tomorrow">
              Tomorrow
            </Radio>
          </Radio.Group>
        ),
      },
      {
        type: "divider",
      },
      {
        key: "2",
        className: "date-picker-container",
        label: (
          <div>
            <div className="date-picker-label">Select different date</div>
            <DatePicker
              className="date-picker-input"
              allowClear={false}
              popupClassName="date-picker-calendar"
              placeholder="(MM/DD/YYYY)"
              disabled={
                permit("showLocationDropdownInDateFilter", user) &&
                dropdownVal === null
              }
              suffixIcon={calendarIcon}
              value={calendarValue}
              onChange={(date, dateString) => {
                logNewRelicMetricsEvent(SEARCH_CALENDAR_EVENT, {
                  text: dateString,
                });
                setCalendarValue(date);
                setDateOption(dateString);
                setVisible(false);
              }}
              format="MM/DD/YYYY"
              disabledDate={(current) => {
                const maxDates = current && current > moment().add(90, "days");
                const minDates =
                  current && current < moment().subtract(121, "days");

                return maxDates || minDates;
              }}
              popupStyle={{
                width: "325px",
                fontSize: "18px",
                fontWeight: "300",
              }}
            />
          </div>
        ),
      },
    ].filter(
      (item, index) =>
        index !== 0 || permit("showLocationDropdownInDateFilter", user)
    );
  };

  return (
    <div hidden={hideDateSelection} id="date-filter-container">
      <div>
        <span className="view-date">View date(s):</span>
        <Dropdown
          menu={{
            items: initializeDropDownItems(),
            className: "date-dropdown-menu",
            onClick: (e) => {
              if (e.key === "1") {
                setVisible(false);
              }
            },
          }}
          dropdownRender={(menu) => <div id="date-filter-dropdown">{menu}</div>}
          overlayClassName="date-filter-dropdown"
          onOpenChange={(value) => {
            setVisible(value);
          }}
          open={visible}
          trigger={["click"]}
        >
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <span className="selected-date">
              {dateOption ? dateOption : "Select Date"}
            </span>{" "}
            <DownOutlined />
          </a>
        </Dropdown>
      </div>
    </div>
  );
};

export default DateFilter;
