import Axios from "axios";
import {
  CREATE_MESSAGE_REQUEST,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_FAIL,
  CLEAN_CREATE_MESSAGE,
} from "../constants/createMailConstants";
import getBffUrl from "../../util/getBffUrl";

export const createMessage =
  (dispatch) =>
  async ({ isNewMessage, request }) => {
    dispatch({
      type: CREATE_MESSAGE_REQUEST,
    });
    try {
      //const { data } = await Axios.post("http://localhost:8080/mail/v1/createMailMessage", request, {
      const { data } = await Axios.post(
        `${getBffUrl()}/mail/v1/createMailMessage`,
        request,
        {
          params: {
            isNewMessage,
          },
        }
      );
      dispatch({
        type: CREATE_MESSAGE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: CREATE_MESSAGE_FAIL, payload: message });
    }
  };

export const cleanCreateMessage = (dispatch) => () => {
  dispatch({
    type: CLEAN_CREATE_MESSAGE,
  });
};
