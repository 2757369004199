import React, { useContext, useEffect, useState } from "react";
import "./WorkOrderDetail.less";
import moment from "moment";
import PropTypes from "prop-types";
import { Collapse, Spin } from "antd";
import permit from "../../util/Permissions";
import { UserContext } from "../../context/RootContext";
import axios from "axios";
import getBffUrl from "../../util/getBffUrl";

const WorkOrderDetail = (props) => {
  const { Panel } = Collapse;
  const { deliveryAgentDetail } = props;
  const [workOrderDetail, setWorkOrderDetail] = useState(props.workOrderDetail);
  const [deliveryLineIds, setDeliveryLineIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);
  const totalWOQty =
    workOrderDetail &&
    workOrderDetail.lineLevelDetailList &&
    workOrderDetail.lineLevelDetailList.reduce(
      (total, productLine) =>
        total +
        (productLine.lineLevelEventList === null
          ? parseInt(productLine.quantity)
          : 0),
      0
    );
  const getLineItem = (lineItem) => {
    if (
      parseInt(lineItem.quantity) > 0 &&
      lineItem.lineLevelEventList === null
    ) {
      return (
        <div key={lineItem.modelNbr} className="delivery-po-line">
          {lineItem.modelNbr} ({lineItem.quantity}) SKU: {lineItem.skuNbr}
        </div>
      );
    }
  };

  const getCacelledLineItemIfAny = (lineItem) => {
    const lineEvent =
      lineItem && lineItem.lineLevelEventList
        ? lineItem.lineLevelEventList.find(
            (lineLevelEvent) =>
              lineLevelEvent &&
              lineLevelEvent.lineEventType &&
              lineLevelEvent.lineEventType === "Cancel"
          )
        : null;
    if (lineEvent !== null) {
      return (
        <div key={lineItem.modelNbr} className="delivery-po-line-cancelled">
          {lineItem.modelNbr} ({lineItem.quantity}) SKU: {lineItem.skuNbr}{" "}
          {lineEvent.eventCrtTs !== null
            ? moment(lineEvent.eventCrtTs).format("MM/DD/YY")
            : ""}
        </div>
      );
    }
  };
  const itemVsItems = (woQty) => {
    return woQty > 1 ? `${woQty} items` : `${woQty} item`;
  };

  const workbinWoSkus = (woDetailList) => {
    return woDetailList.length > 1
      ? `Delivery Options (${woDetailList.length} items)`
      : `Delivery Options (${woDetailList.length} item)`;
  };

  useEffect(() => {
    if (workOrderDetail) {
      const deliveryLineIds = [];
      for (const x in workOrderDetail.lineLevelDetailList) {
        deliveryLineIds.push(
          workOrderDetail.lineLevelDetailList[x].lineReferenceId
        );
      }
      setDeliveryLineIds(deliveryLineIds);
    }
  }, []);

  function buildDeliveryLines(delivery_lines) {
    const temp_delivery_lines = [];
    for (const x in delivery_lines) {
      let delivery_line_key = delivery_lines[x].modelNbr;
      if (
        delivery_lines[x].lineLevelEventList !== null &&
        delivery_lines[x].lineLevelEventList.length > 0
      ) {
        delivery_lines[x].quantity =
          delivery_lines[x].quantity === "0" ? "1" : delivery_lines[x].quantity;
        delivery_line_key =
          delivery_lines[x].modelNbr +
          delivery_lines[x].lineLevelEventList[0].eventCrtTs;
      }
      if (temp_delivery_lines[delivery_line_key]) {
        temp_delivery_lines[delivery_line_key].quantity = (
          parseInt(temp_delivery_lines[delivery_line_key].quantity) +
          parseInt(delivery_lines[x].quantity)
        ).toString();
      } else {
        temp_delivery_lines[delivery_line_key] = delivery_lines[x];
      }
    }
    const new_delivery_lines = [];
    for (const x in temp_delivery_lines) {
      new_delivery_lines.push(temp_delivery_lines[x]);
    }
    const new_workOrderDetail = { ...workOrderDetail };
    new_workOrderDetail.lineLevelDetailList = new_delivery_lines;
    setWorkOrderDetail(new_workOrderDetail);
  }

  function expandDetails(key) {
    if (key.length > 0) {
      setLoading(true);
      axios
        .post(`${getBffUrl()}/v1/deliveryLines`, {
          deliveryId: workOrderDetail.referenceId,
          deliveryLineIds: deliveryLineIds,
        })
        .then((response) => {
          if (response && response.status === 200) {
            if (response.data && response.data.length > 0) {
              buildDeliveryLines(response.data);
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }

  return (
    <div className="work-order-container">
      {deliveryAgentDetail && deliveryAgentDetail.deliveryNetwork && (
        <div data-testid="location-name-network" id="location-name-network">
          <div>
            <span className="da-location-network">
              {deliveryAgentDetail.deliveryNetwork}:{" "}
            </span>
            <span className="da-location-agentName">
              {deliveryAgentDetail.deliveryAgentName}
            </span>
          </div>
          <div className="contact-phones">
            <div id="da-phone-number">
              <span>P </span>
              {deliveryAgentDetail.phoneNumber}
            </div>
          </div>
        </div>
      )}
      {workOrderDetail && (
        <>
          <Collapse
            className="work-order-collapse"
            ghost={true}
            onChange={expandDetails}
          >
            <Panel
              header={
                <div id="delivery-vendor">
                  {permit("showDAVendorName", user) &&
                    workOrderDetail.vendorName}{" "}
                  ({itemVsItems(totalWOQty)})
                </div>
              }
            >
              {workOrderDetail.lineLevelDetailList && (
                <>
                  {loading ? (
                    <div className="wo-detail-loading">
                      <Spin />
                    </div>
                  ) : (
                    <div className="delivery-po-lines">
                      {workOrderDetail.lineLevelDetailList.map((lineItem) =>
                        getCacelledLineItemIfAny(lineItem)
                      )}
                      {workOrderDetail.lineLevelDetailList.map((lineItem) =>
                        getLineItem(lineItem)
                      )}
                    </div>
                  )}
                </>
              )}
            </Panel>
          </Collapse>
        </>
      )}
    </div>
  );
};

WorkOrderDetail.propTypes = {
  workOrderDetail: PropTypes.object,
  deliveryAgentDetail: PropTypes.object,
};

export default WorkOrderDetail;
