import React from "react";
import ReactDOM from "react-dom";
import PFLogic from "./app/components/PFLogic";
import { PFProvider, UserProvider } from "./app/context/RootContext";
import App from "./app/App";
import { SearchProvider } from "./app/context/SearchContext";
import { GlobalLoaderProvider } from "./app/context/RootContext";
ReactDOM.render(
  <PFProvider>
    <PFLogic>
      <GlobalLoaderProvider>
        <UserProvider>
          <SearchProvider>
            <App />
          </SearchProvider>
        </UserProvider>
      </GlobalLoaderProvider>
    </PFLogic>
  </PFProvider>,
  document.getElementById("app")
);
