import React from "react";
import { Button, Spin } from "antd";
import createPdf from "./createPdf";
import createPDFNewDesign from "./createPDFNewDesign";
import getBffUrl from "../../../util/getBffUrl";
import usePodImage from "../../../hooks/getPodImagesHook";
import useNewRelicMetrics from "../../../hooks/newRelicMetricsHook";
import { PRINT_EVENT } from "../../../util/NewRelicConstants";

const PrintOrder = (props) => {
  const {
    newDesign,
    customerDetail,
    customerOrderNumber,
    deliveryDate,
    workOrderDetail,
    purchaseOrderDetailList,
    notes,
    reservationId,
    sourceSystem,
    orderIndex,
    orderNumber,
  } = props;
  const { logNewRelicMetricsEvent } = useNewRelicMetrics();

  const reservationIdString = reservationId
    ? `&reservationId=${reservationId}`
    : "";
  const sourceSystemString = sourceSystem ? `&orderType=${sourceSystem}` : "";
  const url = `${getBffUrl()}/deliveryviz/v1/attachments/${reservationId}?attachmentTypes=POD,Signature,IMG${sourceSystemString}${reservationIdString}`;
  const { podImages, getPodImages } = usePodImage(url, true);

  const handleClick = async () => {
    logNewRelicMetricsEvent(PRINT_EVENT, {
      order_number: orderNumber,
      index: orderIndex,
    });
    if (newDesign) {
      await createPDFNewDesign(
        getPodImages,
        customerDetail,
        purchaseOrderDetailList,
        customerOrderNumber,
        deliveryDate,
        notes
      );
    } else {
      createPdf(
        customerDetail,
        workOrderDetail,
        purchaseOrderDetailList,
        notes
      );
    }
  };

  return (
    <div id="print-order-button">
      <Button className="default-btn" block onClick={handleClick}>
        Print
        {podImages.loading && (
          <Spin className={"podImages-spinner"} style={{ marginLeft: "1em" }} />
        )}
      </Button>
    </div>
  );
};

export default PrintOrder;
