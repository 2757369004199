import { useEffect, useMemo, useState, useContext } from "react";
import { useUrlData } from "./useUrlData";
import { getColumnDefinitions } from "../../util/RoutePlanner/rpColumnDefinitions";
import {
  columnRenderers,
  filterOptions,
  getDefaultColumnConfig,
} from "../../util/RoutePlanner/rpTableConfigUtils";

import RoutePlannerViewContext from "../../context/RoutePlannerViewContext";

/**
 * Custom hook for configuring the table columns, sorting, and filtering.
 *
 * @param {Array} data - The data to be displayed in the table.
 * @param {string} tab - The current tab selected.
 * @param {string} url - The base URL for the view details link.
 * @param {string} tableType - The type of table (e.g., "main" or "details").

 * @returns {Object} An object containing the table columns and the handleTableChange function.
 */
export const useTableConfig = (data, url, tableType) => {
  const { tab, date, filterMap, sorterColumn, sorterDirection } = useContext(
    RoutePlannerViewContext
  );

  const [filtersOptions, setFiltersOptions] = useState({});
  const { getQueryParam, bulkUpdateQueryParams } = useUrlData();
  const columnDefinitions = useMemo(
    () => getColumnDefinitions(tableType),
    [tableType]
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedStopId, setSelectedStopId] = useState("");
  const [driverNotesWorkOrderNumber, setDriverNotesWorkOrderNumber] =
    useState("");
  const [driverNotesOrderNumber, setDriverNotesOrderNumber] = useState("");

  useEffect(() => {
    const filterNames = tab
      ? columnDefinitions[tab].map((column) => column.dataIndex)
      : [];

    const initialFiltersOptions = filterNames.reduce((acc, filterName) => {
      if (filterName === "deliveryWindow") {
        acc[filterName] = filterOptions.generateDeliveryWindowOptions(data);
      } else {
        acc[filterName] = filterOptions.generateDefaultOptions(
          data,
          filterName
        );
      }
      return acc;
    }, {});

    setFiltersOptions(initialFiltersOptions);
  }, [data, tab, columnDefinitions]);

  const handlers = {
    handleTableChange: (pagination, filters, sorter) => {
      const updates = [];

      Object.keys(filters).forEach((key) => {
        const value = filters[key];
        if (value && value.length > 0) {
          updates.push({
            key: `filter_${key}`,
            value: value.join("|"),
            action: "set",
          });
        } else {
          updates.push({ key: `filter_${key}`, action: "delete" });
        }
      });

      if (sorter.field && sorter.order) {
        updates.push({ key: "sortField", value: sorter.field, action: "set" });
        updates.push({ key: "sortOrder", value: sorter.order, action: "set" });
      } else {
        updates.push({ key: "sortField", action: "delete" });
        updates.push({ key: "sortOrder", action: "delete" });
      }

      bulkUpdateQueryParams(updates);
    },
  };

  const initialState = {
    getInitialSortAndFilter: () => {
      const initialSort = {
        field: getQueryParam("sortField"),
        order: getQueryParam("sortOrder"),
      };

      const initialFilters = {};
      Object.keys(filtersOptions).forEach((key) => {
        const value = getQueryParam(`filter_${key}`);
        if (value) {
          initialFilters[key] = value.split("|");
        }
      });

      return { initialSort, initialFilters };
    },
  };

  const columnConfig = {
    url,
    queryParams: {
      tab,
      date,
      filterMap,
      sorterColumn,
      sorterDirection,
    },
    filtersOptions,
    setIsDrawerOpen,
    setSelectedStopId,
    setDriverNotesWorkOrderNumber,
    setDriverNotesOrderNumber,
    ...initialState.getInitialSortAndFilter(),
  };

  const columns = tab
    ? columnDefinitions[tab].map((column) => {
        const renderer = columnRenderers[column.dataIndex];
        if (renderer) {
          return renderer(column, columnConfig);
        }
        return getDefaultColumnConfig(column, columnConfig);
      })
    : [];

  return {
    columns,
    handleTableChange: handlers.handleTableChange,
    isDrawerOpen,
    setIsDrawerOpen,
    selectedStopId,
    driverNotesWorkOrderNumber,
    driverNotesOrderNumber,
  };
};
