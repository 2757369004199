import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import getSvgString from "../../../util/getSvgAndImageString";
import moment from "moment";

pdfMake.fonts = {
  Roboto: {
    normal:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
    bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
    italics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
    bolditalics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
  },
};

const buildPOTable = (lineItem, headers, margin, rowValues) => {
  const body = [];
  const col = [];
  const widths = [];
  rowValues.forEach((el) => {
    col.push(lineItem[el]);
    widths.push(150);
  });
  body.push(headers);
  body.push(col);

  return {
    margin: margin,
    table: {
      widths: widths,
      body,
    },
    layout: "noBorders",
  };
};

const orderContentSubHeader = (vendorName, msNumber, poNumber) => {
  return `${vendorName ? vendorName : ""}${
    vendorName && (msNumber || poNumber) ? ", " : ""
  }${msNumber ? "MS# ".concat(msNumber) : ""}${
    msNumber && poNumber ? ", " : ""
  }${poNumber ? "PO# ".concat(poNumber) : ""}`;
};

const buildPORows = (lineItem) => {
  const columnValueHeader = {
    modelNumber: "MODEL #",
    skuNumber: "STORE SKU",
    quantity: "QTY",
  };

  return Object.keys(columnValueHeader).reduce(
    (result, key) => {
      if (lineItem[key]) {
        result.headers.push(columnValueHeader[key]);
        result.values.push(key);
      }
      return result;
    },
    { headers: [], values: [] }
  );
};

const buildOrderContents = (purchaseOrderDetail) => {
  const orderContents = purchaseOrderDetail.map((items, index) => {
    return (
      items &&
      items.lineItems &&
      items.lineItems.map((lineItem) => {
        const poRows = buildPORows(lineItem);
        return [
          {
            text: `${orderContentSubHeader(
              items.vendorName,
              items.msNumber,
              items.poNumber
            )}`,
            margin: [2, 10, 0, 0],
          },
          //todo: add serial number after store sku when we add it in api from ovq
          lineItem &&
            buildPOTable(lineItem, poRows.headers, [2, 5, 0, 0], poRows.values),
          lineItem &&
            lineItem.itemDesc && {
              text: `Description: ${lineItem.itemDesc}`,
              margin: [2, 5, 0, 25],
            },
          lineItem &&
            lineItem.partsAndServices && {
              text: `Parts & Services`,
              margin: [2, 0, 0, 0],
            },
          lineItem &&
            lineItem.partsAndServices &&
            lineItem.partsAndServices.map((el) => {
              return (
                el && {
                  text: `${el.itemDesc} (${Math.floor(el.quantity)}) SKU# ${
                    el.skuNumber
                  }`,
                  margin: [2, 5, 0, 0],
                }
              );
            }),
          index < purchaseOrderDetail.length - 1
            ? {
                svg: getSvgString("horizontalLine"),
                margin: [0, 15, 0, 0],
              }
            : null,
        ];
      })
    );
  });
  return orderContents;
};

const createSubHeader = (widths, headers) => {
  const contents = headers.map((el) => {
    return {
      text: el.header,
      style: "sectionHeader",
      margin: el.margin,
      alignment: "left",
    };
  });
  return {
    table: {
      widths,
      body: [contents],
    },
    layout: "noBorders",
  };
};

export const getDocumentDefinition = async (
  getPodImages,
  customerDetail,
  purchaseOrderDetail,
  customerOrderNumber,
  deliveryDate,
  notes,
  data
) => {
  const {
    city,
    firstName,
    lastName,
    dayPhone,
    mobilePhone,
    streetAddress1,
    streetAddress2,
    state,
    zipCode,
  } = { ...customerDetail };

  function isBase64Image(str) {
    if (!str) {
      return false;
    }
    const base64regex =
      /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    return base64regex.test(str);
  }

  const isPurchaseOrderDetailAvailable =
    purchaseOrderDetail && purchaseOrderDetail.length > 0 ? true : null;
  const isNotesAvailable = notes && notes.length > 0 ? true : null;
  const isPodDataDetailsPresent =
    data &&
    data.attachments &&
    data.attachments.length > 0 &&
    data.attachments !== undefined
      ? true
      : null;

  function formatTimeStamp(createTimeStamp) {
    return moment(createTimeStamp).locale("en").format("MM/DD/YY hh:mm A");
  }

  const arrayRow = (data, idx1, idx2) => {
    let row;
    if (data) {
      row = data.attachments.slice(idx1, idx2);
      return row;
    }
  };

  return {
    pageSize: "A4",
    content: [
      {
        table: {
          widths: ["25%", "50%"],
          style: "mainHeader",
          heights: [60],
          body: [
            [
              {
                svg: getSvgString("homedepotIcon"),
                alignment: "left",
                style: "mainHeader",
              },
              {
                margin: [0, 0, 0, 0],
                lineHeight: 1.4,
                text: [
                  customerOrderNumber && {
                    text: `DELIVERY ORDER: ${customerOrderNumber}\n`,
                    bold: true,
                    fontSize: 14,
                    alignment: "center",
                  },
                  deliveryDate && {
                    text: `Delivery Date: ${
                      deliveryDate && moment(deliveryDate).format("MM/DD/YYYY")
                    }`,
                    fontSize: 14,
                    alignment: "center",
                  },
                ],
              },
            ],
          ],
        },
        layout: "noBorders",
      },
      createSubHeader(
        ["100%"],
        [{ header: "CUSTOMER INFORMATION", margin: [10, 2, 2, 2] }]
      ),
      {
        columns: [
          customerDetail && {
            style: "orderInfo",

            alignment: "left",
            text: [
              firstName && {
                text: `${firstName}${lastName ? ", " : "\n\n"}`,
              },
              lastName && {
                text: `${lastName} \n\n`,
              },
              streetAddress1 && {
                text: `${streetAddress1}\n`,
              },
              streetAddress2 && {
                text: `${streetAddress2}\n`,
              },
              city && {
                text: `${city}${state || zipCode ? ", " : "\n\n"}`,
              },
              state && {
                text: `${state}${zipCode ? " " : "\n\n"}`,
              },
              zipCode && {
                text: `${zipCode}\n\n`,
              },
              dayPhone && {
                text: `Primary: ${dayPhone}\n`,
              },
              mobilePhone && {
                text: `Secondary: ${mobilePhone}`,
              },
            ],
          },
        ],
      },

      isPurchaseOrderDetailAvailable &&
        createSubHeader(
          ["100%"],
          [{ header: "ORDER CONTENTS", margin: [10, 2, 2, 2] }]
        ),
      isPurchaseOrderDetailAvailable && buildOrderContents(purchaseOrderDetail),

      isNotesAvailable &&
        createSubHeader(["100%"], [{ header: "Notes", margin: [10, 2, 2, 2] }]),

      notes &&
        (notes.length > 0 ? "true" : null) && {
          table: {
            widths: ["100%"],
            body: notes.map((note) => [
              {
                style: "notesLabel",
                text: [
                  {
                    text: `${formatTimeStamp(note.createTimeStamp)} | `,
                    style: "notesLabel",
                  },
                  {
                    text: `${note.createdBy}:\n`,
                    bold: true,
                    style: "notesLabel",
                  },
                  {
                    text: note.text,
                    bold: true,
                    style: "notesText",
                  },
                ],
              },
            ]),
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0;
            },
            vLineWidth: function (i, node) {
              return 0;
            },
            fillColor: function (i, node) {
              return i % 2 === 1 ? "#f1f2f0" : null;
            },
          },
        },

      isPodDataDetailsPresent &&
        createSubHeader(
          ["100%"],
          [{ header: "PROOF OF DELIVERY", margin: [10, 2, 2, 2] }]
        ),

      isPodDataDetailsPresent && data.attachments.length <= 3
        ? {
            table: {
              headerRows: 1,
              display: "true",
              body: [
                data.attachments.map((podDetail, i) => {
                  return isBase64Image(podDetail.base64Image)
                    ? {
                        columns: [
                          {
                            orientation: "landscape",
                            image: `data:${podDetail.contentType.image}/${podDetail.contentType.subType};base64, ${podDetail.base64Image}`,
                            width: 150,
                            height: 150,
                            style: "podImage",
                          },
                        ],
                      }
                    : null;
                }),
              ],
            },
            layout: "noBorders",
          }
        : isPodDataDetailsPresent && {
            table: {
              headerRows: 1,
              display: "true",
              body: [
                isPodDataDetailsPresent &&
                  arrayRow(data, 0, 3).map((podDetail, i) => {
                    return isBase64Image(podDetail.base64Image)
                      ? {
                          columns: [
                            {
                              orientation: "landscape",
                              image: `data:${podDetail.contentType.image}/${podDetail.contentType.subType};base64, ${podDetail.base64Image}`,
                              width: 150,
                              height: 150,
                              style: "podImage",
                            },
                          ],
                        }
                      : null;
                  }),
              ],
            },
            layout: "noBorders",
          },
      isPodDataDetailsPresent && {
        table: {
          headerRows: 1,
          display: "true",
          body: [
            isPodDataDetailsPresent &&
              arrayRow(data, 3, 6).map((podDetail, i) => {
                return isBase64Image(podDetail.base64Image)
                  ? {
                      columns: [
                        {
                          orientation: "landscape",
                          image: `data:${podDetail.contentType.image}/${podDetail.contentType.subType};base64, ${podDetail.base64Image}`,
                          width: 150,
                          height: 150,
                          style: "podImage",
                        },
                      ],
                    }
                  : null;
              }),
          ],
        },
        layout: "noBorders",
      },
      isPodDataDetailsPresent && {
        table: {
          headerRows: 1,
          display: "true",
          body: [
            isPodDataDetailsPresent &&
              arrayRow(data, 6, 9).map((podDetail, i) => {
                return isBase64Image(podDetail.base64Image)
                  ? {
                      columns: [
                        {
                          orientation: "landscape",
                          image: `data:${podDetail.contentType.image}/${podDetail.contentType.subType};base64, ${podDetail.base64Image}`,
                          width: 150,
                          height: 150,
                          style: "podImage",
                        },
                      ],
                    }
                  : null;
              }),
          ],
        },
        layout: "noBorders",
      },
      isPodDataDetailsPresent && {
        table: {
          headerRows: 1,
          display: "true",
          body: [
            isPodDataDetailsPresent &&
              arrayRow(data, 9, 12).map((podDetail, i) => {
                return isBase64Image(podDetail.base64Image)
                  ? {
                      columns: [
                        {
                          orientation: "landscape",
                          image: `data:${podDetail.contentType.image}/${podDetail.contentType.subType};base64, ${podDetail.base64Image}`,
                          width: 150,
                          height: 150,
                          style: "podImage",
                        },
                      ],
                    }
                  : null;
              }),
          ],
        },
        layout: "noBorders",
      },
    ],

    styles: {
      mainHeader: {
        margin: [0, 0, 0, 40],
      },
      sectionHeader: {
        fillColor: "#333",
        color: "white",
        bold: true,
      },
      orderInfo: {
        lineHeight: 1.4,
        margin: [2, 7, 2, 2],
      },
      notesText: {
        //margin: [20, 10, 0, 0],
        fontSize: 13,
        fontStyle: "Open Sans",
        fontWeight: 600,
      },
      notesLabel: {
        margin: [20, 16, 0, 16],
        fontSize: 13,
        fontStyle: "Open Sans",
        color: "#404040",
      },
      podImage: {
        margin: [25, 20, 0, 5],
      },
    },
  };
};

export default async (
  getPodImages,
  customerDetail,
  purchaseOrderDetail,
  customerOrderNumber,
  deliveryDate,
  notes
) => {
  const data = await getPodImages();
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const { fonts } = pdfMake;
  pdfMake
    .createPdf(
      await getDocumentDefinition(
        getPodImages,
        customerDetail,
        purchaseOrderDetail,
        customerOrderNumber,
        deliveryDate,
        notes,
        data
      ),
      null,
      fonts
    )
    .open();
};
