import React, { useContext } from "react";
import {
  MailContext,
  UserContext,
  MailMessageContext,
} from "../../../context/RootContext";
import { Form, Input, Button, Divider, DatePicker, Spin, Row, Col } from "antd";

export const SearchHistory = ({ closer }) => {
  const { mailState, getHistoryMail, deleteMail } = useContext(MailContext);
  const { loading } = mailState;
  const { cleanMailMessage } = useContext(MailMessageContext);
  const { user } = useContext(UserContext);
  const { ldapId } = user;
  const [form] = Form.useForm();

  return (
    <>
      <div style={{ margin: "10px 10px 0px 10px" }}>
        <Form
          form={form}
          labelCol={{ span: 2 }}
          labelAlign="left"
          wrapperCol={{ span: 10 }}
          onFinish={(body) => {
            getHistoryMail(ldapId, body.subject, body.date);
          }}
        >
          <div style={{ height: "35px" }}>
            <Row align="middle">
              <Col span={10} offset={4}>
                <h3>History Search</h3>
              </Col>
              <Col span={10}>{loading && <Spin size="large" />}</Col>
            </Row>
          </div>
          <br />
          <Form.Item name="subject" id="mail_msn_input" label="MS#">
            <Input />
          </Form.Item>
          <Form.Item name="date" id="mail_date_sent_input" label="Date Sent">
            <DatePicker />
          </Form.Item>
          <Row gutter={4}>
            <Col offset={4}>
              <Row gutter={4}>
                <Col>
                  <Button htmlType="submit" type="primary">
                    Search
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      form.resetFields();
                    }}
                  >
                    Clear form
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  closer();
                  deleteMail();
                  cleanMailMessage();
                }}
                type="primary"
              >
                Close form
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <Divider type="horizontal" style={{ borderTop: "5px solid #d9d9d9" }} />
    </>
  );
};
