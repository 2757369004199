import React, { useContext, useState } from "react";
import DOMPurify from "dompurify";
import {
  CloseMailMessageContext,
  MailMessageContext,
  UserContext,
} from "../../../context/RootContext";
import { Space, Row, Button, Divider, Descriptions, Spin, Table } from "antd";
import { CreateMail } from "../CreateMail/CreateMail";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import * as constants from "../../../util/Constants";
import "./MailDetails.less";

export const MailDetails = () => {
  const { mailMessageState } = useContext(MailMessageContext);
  const { user } = useContext(UserContext);
  const { ldapId } = user;
  const { closeMailMessageState, closeMailMessage } = useContext(
    CloseMailMessageContext
  );
  const { loading, error, data } = closeMailMessageState;
  const { message } = mailMessageState;
  const [newMailOpened, setNewMail] = useState(false);

  function updateMailMessageToClosed(messageId) {
    closeMailMessage(ldapId, messageId);
  }

  function cellNullChecker(cell) {
    return cell === null ? "--" : cell;
  }

  function sanitizedData(data) {
    return {
      __html: DOMPurify.sanitize(data),
    };
  }

  return message ? (
    <>
      {newMailOpened && (
        <CreateMail
          closer={() => {
            setNewMail(false);
          }}
          refMailMsgId={message.refMailMsgId}
        />
      )}
      <div id="message-right" style={{ margin: "25px" }}>
        <Descriptions column={4} size="small" bordered>
          <Descriptions.Item span={4} label="from ">
            {message.mailMboxFromName}
          </Descriptions.Item>
          <Descriptions.Item span={4} label="Priority">
            {message.mailPriorityDesc}
          </Descriptions.Item>
          <Descriptions.Item span={4} label="Subject">
            {message.mailSubjDesc}
          </Descriptions.Item>
          <Descriptions.Item span={4} label="Category">
            {message.mailCategoryDesc}
          </Descriptions.Item>
        </Descriptions>
      </div>
      <div>
        {" "}
        {data != null ? (
          <Row justify="center">
            {loading && <Spin size="large" />}
            {data && (
              <div id="confirmation-container">
                <CheckCircleTwoTone
                  twoToneColor="green"
                  style={{ fontSize: "40px", paddingTop: "5px" }}
                />
                <span id="SuccessMessage">
                  {constants.DEFAULT_MESSAGE_CLOSED_SUCCESS}
                </span>
              </div>
            )}
            {error && (
              <CloseCircleTwoTone
                twoToneColor="red"
                style={{ fontSize: "40px" }}
              />
            )}
          </Row>
        ) : (
          <Row justify="center">
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  setNewMail(true);
                }}
              >
                Reply
              </Button>
              <Button
                id="closeMailMessageButton"
                type="primary"
                onClick={() => {
                  if (window.confirm(constants.DEFAULT_MESSAGE_CLOSED)) {
                    updateMailMessageToClosed(message.mailMsgId);
                  }
                }}
              >
                Close
              </Button>
            </Space>
          </Row>
        )}
      </div>
      <Divider plain></Divider>
      {message.mailMsgBody.map((mailItem, i) => (
        <div key={i} style={{ margin: "25px" }} id={`messageBody-${i}`}>
          {mailItem.recipients && (
            <Table
              size="small"
              bordered
              pagination={false}
              dataSource={mailItem.recipients}
              columns={[
                {
                  title: "To",
                  dataIndex: "msgTo",
                },
                {
                  title: "Opened By",
                  dataIndex: "msgOpenedBy",
                  render: cellNullChecker,
                },
                {
                  title: "Date",
                  dataIndex: "msgReadTs",
                  render: cellNullChecker,
                },
              ]}
            />
          )}
          <Descriptions column={8} size="small" bordered>
            <Descriptions.Item span={8}>
              <b>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizedData(mailItem.msgBody),
                  }}
                />
              </b>
            </Descriptions.Item>
          </Descriptions>
        </div>
      ))}
    </>
  ) : (
    <></>
  );
};
