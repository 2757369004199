import React, { useContext } from "react";
import { UserContext } from "../../context/RootContext";
import { Menu, Dropdown } from "antd";
import {
  GE_SUBROLES as subRoles,
  GE_PR_VENDOR_NUMBER,
} from "../../util/Constants";
import "./RolesDropdown.less";
import { DownOutlined } from "@ant-design/icons";

const RolesDropdown = () => {
  const { user, changeRole } = useContext(UserContext);
  const mainlandVendorNumber = user.mvendorList.filter(
    (vendor) => vendor.vendorType === "OEM"
  )[0].vendorNumber;
  const prVendorNumber = GE_PR_VENDOR_NUMBER;

  const getMenuItemLabel = (subRole, mainlandVendorNumber, prVendorNumber) => {
    let additionalText = "";
    if (subRole === "ADMS Vendors") {
      additionalText = `(${mainlandVendorNumber})`;
    }
    if (subRole === "PR ADMS Vendors") {
      additionalText = `(${prVendorNumber})`;
    }

    return (
      <div style={{ color: "white" }}>
        {subRoles[subRole]} {additionalText}
      </div>
    );
  };

  const initDropdownMenuItems = () => {
    return Object.keys(subRoles).map((subRole) => ({
      key: subRole,
      id: `rdd${subRole.replace(/\s/g, "")}`,
      label: getMenuItemLabel(subRole, mainlandVendorNumber, prVendorNumber),
    }));
  };

  const renderRole = () => {
    return user.mvendorNum === GE_PR_VENDOR_NUMBER
      ? subRoles[`PR ${user.roles[0]}`]
      : subRoles[user.roles[0]];
  };

  const renderVendorNumber = () => {
    return user.roles[0] === "ADMS Vendors" ? `(${user.mvendorNum})` : "";
  };

  return (
    <Dropdown
      id="RolesDropdown"
      trigger={["click"]}
      overlayClassName="ge-user-dropdown"
      placement="bottomRight"
      menu={{
        items: initDropdownMenuItems(),
        className: "ge-user-dropdown-list",
        onClick: ({ key }) => {
          changeRole([key]);
        },
      }}
    >
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        Role{" "}
        <span id="selected-role">
          {renderRole()} {renderVendorNumber()}
        </span>{" "}
        <DownOutlined />
      </a>
    </Dropdown>
  );
};

export default RolesDropdown;
