import { useContext } from "react";
import DashboardContext from "../context/DashboardContext";
import { DASHBOARD_SEARCH } from "../util/NewRelicConstants";

export default function useNewRelicMetrics() {
  const dashboardContext = useContext(DashboardContext);

  function getFullEventType(eventType) {
    const wildCard = "?";
    return eventType.includes(wildCard)
      ? eventType.replace(wildCard, DASHBOARD_SEARCH[dashboardContext])
      : eventType;
  }
  function setCustomAttribute(name, value) {
    if (window.newrelic) {
      window.newrelic.setCustomAttribute(name, value);
    }
  }

  function logNewRelicMetricsEvent(eventType, eventData) {
    if (
      process.env.REACT_APP_NEW_RELIC_CUSTOM_EVENT_TRACKING_ENABLED === "Y" &&
      window.newrelic
    ) {
      const fullEventType = getFullEventType(eventType);
      window.newrelic.addPageAction(fullEventType, eventData);
    }
  }

  function noticeError(err) {
    if (window.newrelic) {
      window.newrelic.noticeError(err);
    }
  }

  return {
    logNewRelicMetricsEvent,
    setCustomAttribute,
    noticeError,
  };
}
