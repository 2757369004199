import React, { useState } from "react";
import "./DeliveryWindow.less";
import * as constants from "../../util/Constants";
import moment from "moment";
import Timeline from "../Timeline/Timeline";
import "../Timeline/Timeline.less";
import PropTypes from "prop-types";
import InlineDeliveryWindow from "./InlineDeliveryWindow";

const DeliveryWindow = (props) => {
  const { deliveryWindowDetail, scheduledDeliveryDate, latestEvent } = props;
  const [startDeliveryWindow, setStartDeliveryWindow] = useState(
    deliveryWindowDetail ? deliveryWindowDetail.startDeliveryTimeWindow : null
  );
  const [endDeliveryWindow, setEndDeliveryWindow] = useState(
    deliveryWindowDetail ? deliveryWindowDetail.endDeliveryTimeWindow : null
  );
  const [setStartAMTime] = useState("AM");
  const [setEndDeliveryPMTime] = useState("PM");
  const [setShowImage] = useState(true);
  const [isOpen, openInline] = useState(false);

  function openInlineComponent() {
    openInline(false);
  }

  function setImageStatus() {
    setShowImage(true);
  }

  function setDeliveryWindowFromInline(
    startDeliveryWindow,
    startDeliveryAMTime,
    endDeliveryWindow,
    endDeliveryPMTime
  ) {
    setStartDeliveryWindow(startDeliveryWindow);
    setStartAMTime(startDeliveryAMTime);
    setEndDeliveryWindow(endDeliveryWindow);
    setEndDeliveryPMTime(endDeliveryPMTime);
  }

  function formatDeliveryDate() {
    if (latestEvent) {
      switch (latestEvent.orderEventDescription) {
        case constants.ORDER_PROCESSED:
          return `Scheduled for ${moment(scheduledDeliveryDate).format(
            "MM/DD/YY"
          )}`;
        case constants.PO_RECEIVED_EVENT:
          return `Scheduled for ${moment(scheduledDeliveryDate).format(
            "MM/DD/YY"
          )}`;
        case constants.ON_THE_WAY:
        case constants.AT_LOCATION:
          return `Scheduled for ${moment(scheduledDeliveryDate).format(
            "MM/DD/YY"
          )}`;
        case constants.DELIVERED:
          return `Delivered ${getDateFromEventTimestamp(
            latestEvent.orderEventCrtTimeStamp
          )}`;
        case constants.DELIVERED_W_EXCEPTION:
        case constants.MISSED_W_ATTEMPT:
          return `Delivery Attempted ${getDateFromEventTimestamp(
            latestEvent.orderEventCrtTimeStamp
          )}`;
        case constants.MISSED_WO_ATTEMPT:
          return "Awaiting reschedule";
        case constants.RESCHEDULED:
          return `Now Scheduled for ${moment(scheduledDeliveryDate).format(
            "MM/DD/YY"
          )}`;
        default:
          return "";
      }
    }
  }

  function determineDeliveryWindow() {
    if (latestEvent) {
      switch (latestEvent.orderEventDescription) {
        case constants.ORDER_PROCESSED:
        case constants.PO_RECEIVED_EVENT:
        case constants.ON_THE_WAY:
        case constants.AT_LOCATION:
        case constants.MISSED_WO_ATTEMPT:
        case constants.RESCHEDULED:
        case constants.DELIVERED:
        case constants.DELIVERED_W_EXCEPTION:
        case constants.MISSED_W_ATTEMPT:
          return getTimeWindow();
        default:
          return "";
      }
    }
  }

  function getTimeWindow() {
    if (deliveryWindowDetail) {
      if (
        deliveryWindowDetail.startDeliveryTimeWindow === constants.NA ||
        deliveryWindowDetail.endDeliveryTimeWindow === constants.NA ||
        !deliveryWindowDetail.startDeliveryTimeWindow ||
        !deliveryWindowDetail.endDeliveryTimeWindow ||
        deliveryWindowDetail.startDeliveryTimeWindow === constants.ALLDAY ||
        deliveryWindowDetail.endDeliveryTimeWindow === constants.ALLDAY ||
        getHourFromTimestamp(endDeliveryWindow) -
          getHourFromTimestamp(startDeliveryWindow) !==
          4
      ) {
        return "Window Pending";
      } else {
        return `${formatTimeWindow(
          deliveryWindowDetail.startDeliveryTimeWindow
        )}-${formatTimeWindow(deliveryWindowDetail.endDeliveryTimeWindow)}`;
      }
    }
  }

  function formatTimeWindow(timeWindow) {
    const zero = "0";
    let hour = getHourFromTimestamp(timeWindow);
    const minute = getMinuteFromTimestamp(timeWindow);
    if (hour >= 12) {
      if (hour > 12) {
        hour = hour - 12;
      }
      if (hour < 10) {
        hour = zero + hour;
      }
      return `${hour}:${minute}PM`;
    } else {
      return `${hour}:${minute}AM`;
    }
  }

  function getHourFromTimestamp(timeStamp) {
    return timeStamp.slice(0, 2);
  }

  function getMinuteFromTimestamp(timeStamp) {
    return timeStamp.slice(3, 5);
  }

  function getDateFromEventTimestamp(eventTimestamp) {
    return eventTimestamp ? eventTimestamp.slice(0, 8) : "";
  }

  function renderDeliveryStatusAndTimeWindow() {
    if (latestEvent.orderEventDescription === constants.CANCELLED) {
      return (
        <div>
          <span className={"cancelled-status"}>Order Cancelled</span>
        </div>
      );
    } else {
      return (
        <div>
          <div>
            <span className={"delivery-status"}>{formatDeliveryDate()}</span>
          </div>
          <div>
            <span className={"time-window"}>{determineDeliveryWindow()}</span>
          </div>
          <div>
            <span className={"change-time-window"}></span>
          </div>
        </div>
      );
    }
  }

  function deliveryWindow() {
    if (!isOpen) {
      return (
        <div className={"delivery-window-container"}>
          {renderDeliveryStatusAndTimeWindow()}
          <div className="timeline">
            <Timeline deliveryWindowDetail={deliveryWindowDetail} />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <InlineDeliveryWindow
            referenceId={
              deliveryWindowDetail ? deliveryWindowDetail.referenceId : null
            }
            openInlineComponent={openInlineComponent}
            deliveryWindowDetail={deliveryWindowDetail}
            scheduledDeliveryDate={scheduledDeliveryDate}
            setDeliveryWindowFromInline={setDeliveryWindowFromInline}
            setImageStatus={setImageStatus}
          />
        </div>
      );
    }
  }

  return <div>{deliveryWindow()}</div>;
};

DeliveryWindow.propTypes = {
  deliveryWindowDetail: PropTypes.object.isRequired,
  scheduledDeliveryDate: PropTypes.string.isRequired,
  latestEvent: PropTypes.object,
};

export default DeliveryWindow;
