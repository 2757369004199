import React, { useState, useContext } from "react";
import "./UpdateCustomerInfo.less";
import axios from "axios";
import { UserContext } from "../../../context/RootContext";
import { Row, Col, Divider, Form, Input, Button, Spin, message } from "antd";
import PhoneNumberFormatter from "../../../util/PhoneNumberFormatter";
import moment from "moment";
import getBffUrl from "../../../util/getBffUrl";
import useNewRelicMetrics from "../../../hooks/newRelicMetricsHook";
import { CUSTOMER_INFO_SAVE_CHANGES_EVENT } from "../../../util/NewRelicConstants";

const UpdateCustomerInfo = ({
  customerDetail = {},
  orderNumber,
  handleClose,
  simpleClose,
  setCustomerInfoIsUpdated,
  isUpdateCustomerInfoLoading,
  setUpdateCustomerInfoIsLoading,
}) => {
  const { user } = useContext(UserContext);
  const [form] = Form.useForm();
  const { ldapId } = user;
  const { logNewRelicMetricsEvent } = useNewRelicMetrics();
  const custPhoneNbr = form.getFieldValue("custPhoneNbr");
  const custAltPhoneNbr = form.getFieldValue("custAltPhoneNbr");
  const custStreetAddress1 = form.getFieldValue("custStreetAddress1");
  const custStreetAddress2 = form.getFieldValue("custStreetAddress2");

  const nullEscape = (v) => (v === null || v === undefined ? "" : v);
  const detailsToShow = [
    "custFirstName",
    "custLastName",
    "custStreetAddress1",
    "custStreetAddress2",
    "custPhoneNbr",
    "custAltPhoneNbr",
    "custZipCode",
    "custCity",
    "custState",
  ];
  const defaultPayloadConstructor = () => {
    const obj = {};

    Object.keys(customerDetail)
      .filter((d) => detailsToShow.includes(d))
      .forEach((d) => {
        obj[d] = nullEscape(customerDetail[d]);
      });

    return obj;
  };
  const [payload] = useState(defaultPayloadConstructor());

  //TODO: is this fieldValue needed?
  const [fieldValue, setFieldValue] = useState({});
  const [inputIsValid, setInputIsValid] = useState(true);
  const [inputAltIsValid, setInputAltIsValid] = useState(true);

  const saveChanges = (values) => {
    logNewRelicMetricsEvent(CUSTOMER_INFO_SAVE_CHANGES_EVENT, {
      order_number: orderNumber,
    });
    setUpdateCustomerInfoIsLoading(true);
    const new_payload = { ...payload };
    Object.keys(values).forEach((key) => {
      if (new_payload[key] !== undefined) {
        new_payload[key] = values[key].trim() !== "" ? values[key] : "";
      }
    });
    axios
      .patch(`${getBffUrl()}/deliveryOrder/v1/deliveryUpdate`, {
        referenceId: customerDetail.referenceId,
        lastUpdUserId: ldapId, //string
        companyName: user.companyName,
        customerDetail: new_payload,
      })
      .then((res) => {
        if (res.status === 200) {
          detailsToShow.forEach((d) => {
            customerDetail[d] = new_payload[d];
          });
          setCustomerInfoIsUpdated(true);
          handleClose();
        } else {
          message.error("There was an error. Please try again");
          setUpdateCustomerInfoIsLoading(false);
          console.log(res);
        }
      })
      .catch((err) => {
        message.error("There was an error. Please try again");
        setUpdateCustomerInfoIsLoading(false);
        console.log(err);
      });
  };

  const formatDate = () => {
    let dateString = null;
    dateString =
      customerDetail.scheduledDeliveryDate !== null
        ? moment(customerDetail.scheduledDeliveryDate)
            .locale("en")
            .format("MMMM DD, YYYY")
        : "";
    return dateString;
  };

  const handlePhoneChange = (value, field) => {
    const new_number = PhoneNumberFormatter(value);
    form.setFieldsValue({ [field]: new_number.number });
    if (field === "custPhoneNbr") {
      setInputIsValid(new_number.isValid);
    } else {
      setInputAltIsValid(new_number.number === "" ? true : new_number.isValid);
    }
  };

  return (
    <>
      <div id="update-customer-info-header-text">
        Edit Customer Information
        <Divider />
      </div>
      <Row gutter={24} className="cust-delivery-info">
        <Col xs={24} md={12} lg={12} id="cust-delivery-address">
          <img
            id="update-customer-info-pointer-img"
            src="/images/pointer.svg"
            alt="pointer-icon"
          />
          <span id="cust-delivery-address-header">DELIVERY ADDRESS</span>
          <br />
          <div id="delivery-address-text">
            {customerDetail.custFirstName} {customerDetail.custLastName}
            <br />
            {customerDetail.custStreetAddress1}
            <br />
            {customerDetail.custStreetAddress2}
            <br />
            {customerDetail.custCity}
            {", "}
            {customerDetail.custState} {customerDetail.custZipCode}
            <br />
            {customerDetail.custPhoneNbr}
            <br />
          </div>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <img
            id="cust-calender-img"
            src="/images/calendar.svg"
            alt="calendar-icon"
          />
          <span id="cust-current-delivery-date">CURRENT DELIVERY DATE</span>
          <div id="cust-delivery-date">{formatDate()}</div>
        </Col>
      </Row>
      <div className="update-customer-info-form">
        <Form
          layout="vertical"
          form={form}
          onFinish={saveChanges}
          onValuesChange={(v) => setFieldValue(v)}
        >
          <Row gutter={16}>
            <Col xs={24} md={12} lg={12}>
              <Form.Item
                name="custName"
                label="Customer Name"
                initialValue={`${payload.custFirstName} ${payload.custLastName}`}
              >
                <Input size="large" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12} xl={6}>
              <Form.Item
                name="custPhoneNbr"
                label="Home Phone Number"
                initialValue={payload.custPhoneNbr}
                rules={[
                  { required: true, message: "Home Phone Number is required" },
                ]}
              >
                <Input
                  size="large"
                  onChange={(event) =>
                    handlePhoneChange(event.target.value, "custPhoneNbr")
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12} xl={6}>
              <Form.Item
                name="custAltPhoneNbr"
                label="Office Phone Number"
                initialValue={payload.custAltPhoneNbr}
              >
                <Input
                  size="large"
                  onChange={(event) =>
                    handlePhoneChange(event.target.value, "custAltPhoneNbr")
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Form.Item
                name="custStreetAddress1"
                label="Address"
                initialValue={payload.custStreetAddress1}
                rules={[{ required: true, message: "Address is required" }]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Form.Item
                name="custStreetAddress2"
                label="Address Line 2"
                initialValue={payload.custStreetAddress2}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={4}>
              <Form.Item
                name="zip"
                label="ZIP"
                initialValue={payload.custZipCode}
              >
                <Input size="large" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={4}>
              <Form.Item
                name="city"
                label="City"
                initialValue={payload.custCity}
              >
                <Input size="large" disabled />
              </Form.Item>
            </Col>
            <Col xs={"auto"}>
              <Form.Item
                name="state"
                label="State"
                initialValue={payload.custState}
              >
                <Input size="large" id="cust-state" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} className="update-customer-info-buttons">
            <Col xs={24} sm={24} md={12}>
              <Button
                id="cancel-btn"
                size="large"
                onClick={() => simpleClose()}
              >
                Cancel
              </Button>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Button
                id="save-btn"
                size="large"
                htmlType="submit"
                disabled={
                  (custPhoneNbr === undefined ||
                    custPhoneNbr === payload.custPhoneNbr ||
                    !inputIsValid) &&
                  (custAltPhoneNbr === undefined ||
                    custAltPhoneNbr === "" ||
                    custAltPhoneNbr === payload.custAltPhoneNbr ||
                    !inputAltIsValid) &&
                  (custStreetAddress1 === undefined ||
                    custStreetAddress1 === payload.custStreetAddress1) &&
                  (custStreetAddress2 === undefined ||
                    custStreetAddress2 === payload.custStreetAddress2)
                }
              >
                Save Changes
              </Button>
            </Col>
          </Row>
          {isUpdateCustomerInfoLoading && (
            <div className="customer-info-spinner">
              <Spin size="large" />
            </div>
          )}
        </Form>
      </div>
    </>
  );
};

export default UpdateCustomerInfo;
