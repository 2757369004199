import {
  DELIVERY_SLOTS_REQUEST,
  DELIVERY_SLOTS_SUCCESS,
  DELIVERY_SLOTS_FAIL,
  CLEAN_DELIVERY_SLOTS,
} from "../constants/deliverySlotsConstants";

const deliverySlotsReducer = (state, { payload, type }) => {
  switch (type) {
    case DELIVERY_SLOTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELIVERY_SLOTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload,
        error: false,
      };
    case DELIVERY_SLOTS_FAIL:
      return {
        ...state,
        loading: false,
        errorData: payload,
        error: true,
      };
    case CLEAN_DELIVERY_SLOTS:
      return {
        data: null,
        loading: false,
        error: false,
        errorData: null,
      };
    default:
      return state;
  }
};

export default deliverySlotsReducer;
