import React from "react";
import moment from "moment";
import { ReactComponent as HomeDepotIcon } from "./print_manifest_thd_logo.svg";

export default function ManifestHeader({ route }) {
  const headingText = "Home Depot Route Manifest";

  return (
    <div className="manifest-header-container">
      <div className="manifest-thd-logo-container">
        <HomeDepotIcon className="manifest-thd-logo-container" />
      </div>

      <div className="manifest-header-print-heading">
        <div className="manifest-header-text">{headingText}</div>
        <div className="manifest-header-horizontal-bar"></div>
      </div>

      <div className="manifest-header-details">
        <div className="manifest-header-route-details">
          <div className="manifest-header-detail-label">
            <span>
              DATE:{" "}
              {moment(route.earliestStartDate)
                .locale("en")
                .format("MM/DD/YYYY hh:mm A")}
            </span>
          </div>
          <div className="manifest-header-detail-text">
            <span>
              Route: {route.routeID} - {route.resourceKey}
            </span>
          </div>
        </div>
        <div className="manifest-header-vertical-bar"></div>
        <div className="manifest-header-driver-details">
          <div className="manifest-header-detail-label">
            <span>Driver Name: {route.driverName}</span>
          </div>
          <div className="manifest-header-detail-text">
            <span>Carrier: {route.carrier}</span>
            <br />
          </div>
          <div className="manifest-header-detail-text">
            <span>Truck: {route.truckName}</span>
          </div>
        </div>
      </div>
      <div>
        <div className="vertical-bar"></div>
      </div>
    </div>
  );
}
