import React, { useState, useRef } from "react";
import "./Dashboard.less";
import Deliveries from "../Deliveries/Deliveries";
import SearchContext from "../../context/SearchContext";

const Dashboard = () => {
  return (
    <div className="dashboard-wrapper">
      <div id="dashboard-container">
        <Deliveries />
      </div>
    </div>
  );
};

export default Dashboard;
