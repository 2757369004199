import { useLocation, useSearchParams } from "react-router-dom";

/**
 * Custom hook for managing URL data
 */
export const useUrlData = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  /**
   * Get the value of a specific query parameter.
   *
   * @param {string} key - The key of the query parameter.
   * @returns {string | null} The value of the query parameter, or null if it doesn't exist.
   */
  const getQueryParam = (key) => {
    return searchParams.get(key);
  };

  /**
   * Set the value of a query parameter and update the URL.
   *
   * @param {string} key - The key of the query parameter.
   * @param {string} value - The value to set for the query parameter.
   */
  const setQueryParam = (key, value) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set(key, value);
    setSearchParams(newParams);
  };

  const bulkUpdateQueryParams = (updates) => {
    const newParams = new URLSearchParams(searchParams);

    updates.forEach(({ key, value, action }) => {
      if (action === "set") {
        newParams.set(key, value);
      } else if (action === "delete") {
        newParams.delete(key);
      }
    });

    setSearchParams(newParams);
  };

  /**
   * Delete a query parameter and update the URL.
   */
  const deleteQueryParam = (key) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.delete(key);
    setSearchParams(newParams);
  };

  /**
   * Get all current query parameters in String format.
   */
  const getAllQueryParams = () => {
    return searchParams.toString();
  };

  /**
   * Checks current url path and returns the string of the tab name.
   */
  const getCurrentTab = () => {
    const path = location.pathname.toLowerCase();
    if (path.includes("/dispatch")) {
      return "dispatch";
    } else if (path.includes("/archive")) {
      return "archive";
    } else if (path.includes("/unassigned")) {
      return "unassigned";
    } else if (path.includes("/planning")) {
      return "planning";
    } else {
      return "invalid";
    }
  };

  return {
    getQueryParam,
    setQueryParam,
    bulkUpdateQueryParams,
    deleteQueryParam,
    getAllQueryParams,
    getCurrentTab,
  };
};
