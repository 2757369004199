import React, { useState, useEffect, useContext } from "react";
import "./WorkBin.less";
import { Table, Spin } from "antd";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import LocationContext from "../../context/LocationContext";
import axios from "axios";
import getBffUrl from "../../util/getBffUrl";
import moment from "moment";
import useOrderDetails from "../../hooks/orderdetails/orderDetailsHook";
import OrderCard from "../OrderCard/OrderCard";

const WorkBin = () => {
  const columns = [
    {
      title: "Customer Order #",
      dataIndex: "customerOrderNumber",
      key: "customerOrderNumber",
    },
    { title: "Work Order", dataIndex: "workOrder", key: "workOrder" },
    {
      title: "WO Status",
      dataIndex: "workOrderStatus",
      key: "workOrderStatus",
    },
    { title: "Vehicle Type", dataIndex: "vehicleType", key: "vehicleType" },
    { title: "Delivery Date", dataIndex: "deliveryDate", key: "deliveryDate" },
    { title: "Exception", dataIndex: "exceptionType", key: "exceptionType" },
  ];

  const { orderDetails, onExpand, updateOrderDetails } = useOrderDetails();
  const [loading, setLoading] = useState(false);
  const [workBinList, setWorkBinList] = useState([]);
  const { currentLocation } = useContext(LocationContext);

  useEffect(() => {
    if (currentLocation) {
      getWorkBinList();
    }
  }, [currentLocation]);

  const getWorkBinList = () => {
    setLoading(true);
    axios
      .get(
        `${getBffUrl()}/v1/workBin/exceptions/${currentLocation.locationNumber}`
      )
      .then((response) => {
        if (response && response.status === 200) {
          const workBinData = response.data.map((item, index) => {
            item.key = item.workOrder;
            item.orderIndex = index;
            item.deliveryDate = !item.deliveryDate ? (
              <div className="noDate"> - </div>
            ) : (
              moment(
                new Date(
                  item.deliveryDate.replace(/-/g, "/").replace(/T.+/, "")
                )
              ).format("YYYY-MM-DD")
            );
            return item;
          });
          setWorkBinList(workBinData);
        } else {
          console.error("Error while getting WorkBin list");
          setWorkBinList(null);
        }
      })
      .catch((error) => {
        setWorkBinList(null);
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCurrentLocation = () => {
    if (currentLocation) {
      const locationTitle = currentLocation.locationName;
      const locationName = locationTitle.split(" ").slice(1, -1).join(" ");
      return `${currentLocation.locationNumber} - ${locationName} Workbin`;
    }
  };

  return (
    <>
      <div className="workbin-header">
        <h4>{getCurrentLocation()}</h4>
      </div>
      <div className="workbin-container" data-testid="workbin-container">
        {loading ? (
          <div className="workbin-spinner" data-testid="workbin-spinner">
            <Spin size="large" />
          </div>
        ) : workBinList && workBinList.length < 1 ? (
          <div className="no-result">
            There are no Exceptions for this location
          </div>
        ) : workBinList === null ? (
          <div className="workbin-error-message">
            Oops, something went wrong, please try again.
          </div>
        ) : (
          <>
            <div className="workbin-table-container">
              <div className="table-wrapper" />
              <Table
                columns={columns}
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-dark" : "table-row-light"
                }
                scroll={{ x: "auto" }}
                expandable={{
                  onExpand: onExpand,
                  expandedRowRender: (record, index, indent, expanded) => (
                    <OrderCard
                      orderDetails={orderDetails}
                      record={record}
                      orderIndex={record.orderIndex}
                      customerOrderNumber={record.customerOrderNumber}
                      updateOrderDetails={updateOrderDetails}
                      expanded={expanded}
                      loadDeliveryTracker={false}
                      isWorkBin={true}
                    />
                  ),
                  expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                      <DownOutlined onClick={(e) => onExpand(record, e)} />
                    ) : (
                      <RightOutlined
                        onClick={(e) => {
                          onExpand(record, e);
                        }}
                      />
                    ),
                }}
                dataSource={workBinList}
                pagination={false}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default WorkBin;
