import {
  CREATE_MANUAL_ORDER_REQUEST,
  CREATE_MANUAL_ORDER_SUCCESS,
  CREATE_MANUAL_ORDER_FAIL,
  CLEAN_CREATE_MANUAL_ORDER,
} from "../constants/manualOrderConstants";

const createManualOrderReducer = (state, { payload, type }) => {
  switch (type) {
    case CREATE_MANUAL_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case CREATE_MANUAL_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload,
        error: false,
      };
    case CREATE_MANUAL_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        errorData: payload,
        error: true,
      };
    case CLEAN_CREATE_MANUAL_ORDER:
      return {
        data: null,
        loading: false,
        error: false,
        errorData: null,
      };
    default:
      return state;
  }
};

export default createManualOrderReducer;
