import React, { useContext, useEffect, useRef } from "react";
import "./RouteDetailsView.less";
import { Spin, Table } from "antd";
import { Link, useMatch, useResolvedPath, useLocation } from "react-router-dom";
import { useTableConfig } from "../../hooks/RoutePlanner/useTableConfig";
import DriverNotesDrawer from "./DriverNotesDrawer";
import { useRouteDetailsData } from "../../hooks/RoutePlanner/useRouteDetailsData";
import ManifestButton from "./common/ManifestButton";
import { getRPRowClassName } from "../../util/RoutePlanner/rpTableConfigUtils";
import RoutePlannerViewContext from "../../context/RoutePlannerViewContext";
import useNotification from "../../hooks/useNotification";

const returnPath = (queryParams) => {
  let returnQueryString = "";
  if (queryParams.date) {
    returnQueryString += `?date=${queryParams.date}`;
  }
  if (queryParams.sorterColumn && queryParams.sorterDirection) {
    returnQueryString += `&sortField=${queryParams.sorterColumn}&sortOrder=${queryParams.sorterDirection}`;
  }
  if (queryParams.filterMap) {
    Object.entries(queryParams.filterMap).forEach(([key, value]) => {
      returnQueryString += `&filter_${key}=${value}`;
    });
  }
  return returnQueryString;
};

export default function RouteDetailsView({ heading, subheading }) {
  const match = useMatch("/route-planner/*");
  const resolvedPath = useResolvedPath(match.pathname);

  const { detailsData, loading, error, enableDownloadManifest } =
    useRouteDetailsData();

  const location = useLocation();
  const originalNavigationContextRef = useRef(null);

  const { tab } = useContext(RoutePlannerViewContext);

  const { openErrorNotification, contextHolder } = useNotification();

  useEffect(() => {
    if (error) {
      openErrorNotification({
        message: "Error Retrieving Data",
        description:
          "We're Sorry! There is no data available at this time. Please try again later.",
      });
    }
  }, [error]);

  useEffect(() => {
    if (!originalNavigationContextRef.current && location.state) {
      originalNavigationContextRef.current = {
        isFromDashboard: location.state.fromDashboard || false,
        queryParams: location.state.queryParams || {},
      };
    }
  });

  const {
    columns,
    handleTableChange,
    isDrawerOpen,
    setIsDrawerOpen,
    selectedStopId,
    driverNotesWorkOrderNumber,
    driverNotesOrderNumber,
  } = useTableConfig(detailsData.stopsData, resolvedPath.pathname, "details");

  return (
    <div className="routedetail-view-table">
      <div className="routedetail-view-table-header">
        <div
          className="routedetail-view-table-header-back-arrow"
          data-testid="rp-details-back-arrow"
        >
          <Link
            to={{
              pathname: `${resolvedPath.pathname.substring(
                0,
                resolvedPath.pathname.indexOf("/details")
              )}`,
              search: originalNavigationContextRef.current?.isFromDashboard
                ? returnPath(originalNavigationContextRef.current.queryParams)
                : null,
            }}
          >
            <img
              src="/images/backArrow.svg"
              alt={"back"}
              style={{ width: "25px", height: "25px", alignSelf: "center" }}
            />
          </Link>
        </div>
        <h3>{heading}</h3> <h4>{subheading}</h4>
        {
          <div className="routedetail-view-download-manifest-button">
            <ManifestButton
              routeId={detailsData.driverData.routeId}
              isEnabled={enableDownloadManifest}
              selectedDate={detailsData.driverData.date}
              tab={tab}
              buttonName={"Open Manifest"}
            />
          </div>
        }
      </div>
      <br />

      {loading && (
        <div className="rp-details-spinner" data-testid="rp-details-spinner">
          <Spin size="large" />
        </div>
      )}
      {error && (
        <div>
          <span className={""}></span>
        </div>
      )}
      {contextHolder}
      <div className="routedetail-view-table-query-section">
        <table className="routedetail-view-table-query-driverinfo-table">
          <tbody>
            <tr className="routedetail-view-table-row">
              <th colSpan="2" style={{ padding: "8px" }}>
                Driver Information{" "}
              </th>
            </tr>
            <tr>
              <td className="routedetail-view-table-column-left">
                Name: <b>{detailsData.driverData.driverName}</b>
              </td>
              <td className="routedetail-view-table-column-right">
                Carrier: <b>{detailsData.driverData.carrierName}</b>
              </td>
            </tr>
            <tr>
              <td className="routedetail-view-table-column-left">
                Driver Key: <b>{detailsData.driverData.driverId}</b>
              </td>
              <td className="routedetail-view-table-column-right">
                Truck ID: <b>{detailsData.driverData.truckId}</b>
              </td>
            </tr>
            <tr>
              <td className="routedetail-view-table-column-left"></td>
              <td className="routedetail-view-table-column-right">
                Route ID: <b>{detailsData.driverData.routeId}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="routedetail-view-table-content">
        <Table
          columns={columns}
          tableLayout="auto"
          dataSource={detailsData.stopsData}
          onChange={handleTableChange}
          rowClassName={getRPRowClassName}
          pagination={false}
          size={"middle"}
        />
      </div>
      {isDrawerOpen && (
        <DriverNotesDrawer
          isDrawerOpen={isDrawerOpen}
          setDrawerOpen={setIsDrawerOpen}
          selectedStopId={selectedStopId}
          driverNotesWorkOrderNumber={driverNotesWorkOrderNumber}
          driverNotesOrderNumber={driverNotesOrderNumber}
        />
      )}
    </div>
  );
}
