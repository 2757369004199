import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";

const headerTable = (customerDetail, id) => ({
  table: {
    widths: ["50%", "50%"],
    heights: [61],
    style: "sectionHeader",
    body: [
      [
        {
          text: `${customerDetail.custLastName}, ${customerDetail.custFirstName}`,
          style: "sectionHeader",
          alignment: "left",
        },
        {
          text: customerDetail.custOrdId,
          style: "sectionHeader",
          alignment: "right",
        },
      ],
    ],
  },
  layout: {
    hLineWidth: function (i, node) {
      return i % 2 === 1 ? 3 : 0;
    },
    vLineWidth: function (i, node) {
      return 0;
    },
    hLineColor: function (i, node) {
      return "#A9A8A8";
    },
  },
  id: id,
});

export default (
  customerDetail,
  workOrderDetail,
  purchaseOrderDetailList,
  notes
) => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  function formatTimeStamp(createTimeStamp) {
    return moment(createTimeStamp).locale("en").format("MM/DD/YY hh:mm A");
  }

  function table(poDetail, columns, witdhsDef, showHeaders, headers) {
    return {
      style: "poTable",

      table: {
        headerRows: 1,
        widths: witdhsDef,
        height: 36,
        body: buildTableBody(poDetail, columns, showHeaders, headers),
      },
      layout: {
        hLineWidth: function (i, node) {
          return 0;
        },
        vLineWidth: function (i, node) {
          return 0;
        },
        fillColor: function (i, node) {
          return i % 2 === 0 && i !== 0 ? "#F0EFEF" : null;
        },
      },
    };
  }

  function buildTableBody(poDetail, columns, showHeaders, headers) {
    const body = [];

    if (showHeaders) {
      body.push(headers);
    }

    //TODO: Check if these .map()s should be .forEach()s instead
    poDetail.lineLevelDetailList.map((row) => {
      const dataRow = [];

      columns.map((column) => {
        dataRow.push(row[column]);
      });
      body.push(dataRow);
    });

    return body;
  }

  const documentDefinition = {
    pageSize: "A4",
    //pageOrientation: "landscape",
    pageBreakBefore: function (
      currentNode,
      followingNodesOnPage,
      nodesOnNextPage,
      previousNodesOnPage
    ) {
      return currentNode.id === "notes";
    },
    content: [
      headerTable(customerDetail, "CustomerDetails"),
      {
        columns: [
          {
            width: "50%",
            style: "customerInfo",
            text: [
              { text: `${customerDetail.custStreetAddress1}\n` },
              customerDetail.custStreetAddress2 && {
                text: `${customerDetail.custStreetAddress2}\n`,
              },
              {
                text: `${customerDetail.custCity}, ${customerDetail.custState} ${customerDetail.custZipCode}\n`,
              },
              { text: "\n" },
              customerDetail.custPhoneNbr && { text: "C: ", bold: true },
              customerDetail.custPhoneNbr && {
                text: `${customerDetail.custPhoneNbr}\n`,
              },
              customerDetail.custAltPhoneNbr && { text: "C: ", bold: true },
              customerDetail.custAltPhoneNbr && {
                text: `${customerDetail.custAltPhoneNbr}\n`,
              },
            ],
            alignment: "left",
          },
          {
            width: "50%",
            style: "customerInfo",
            alignment: "right",
            text: [
              {
                text: `Delivery date: ${moment(
                  customerDetail.scheduledDeliveryDate
                ).format("MM/DD/YY")}`,
              },
            ],
          },
        ],
      },
      {
        table: {
          widths: ["50%", "50%"],
          heights: [41],
          style: "secondaryHeader",
          body: [
            [
              {
                text: "Order Contents",
                style: "secondaryHeader",
                colSpan: 2,
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i % 2 === 1 ? 3 : 0;
          },
          vLineWidth: function (i, node) {
            return 0;
          },
          hLineColor: function (i, node) {
            return "#A9A8A8";
          },
        },
      },
      workOrderDetail &&
        workOrderDetail.woNbr && {
          width: "100%",
          height: 48,
          style: "partsServices",
          text: [
            { text: "Parts & Services, ", bold: true },
            { text: `PO# ${workOrderDetail.woNbr}` },
          ],
        },
      //gray-white switching
      //pdfmake ignoring only null values , so had to put null instead of false in the conditional operator below.
      workOrderDetail &&
        workOrderDetail.lineLevelDetailList &&
        (workOrderDetail.lineLevelDetailList.length > 0 ? "true" : null) && {
          style: "wotable",
          table: {
            widths: ["100%"],
            //heights: [38],
            body: workOrderDetail.lineLevelDetailList.map((lineItem, i) => {
              return [
                {
                  text: `${lineItem.modelNbr} (${lineItem.quantity})`,
                  style: "woitems",
                  fillColor: i % 2 ? "#ffffff" : "#f0efef",
                },
              ];
            }),
          },
          layout: {
            hLineWidth: function (i, node) {
              return workOrderDetail.lineLevelDetailList.length === i ? 1 : 0;
            },
            vLineWidth: function (i, node) {
              return 0;
            },
            hLineColor: function (i, node) {
              return "#b7b7b8";
            },
          },
        },

      purchaseOrderDetailList &&
        purchaseOrderDetailList.map((poDetail) => [
          {
            width: "100%",
            height: 48,
            style: "vendorNm",
            text: [
              poDetail.vendorName && {
                text: `${poDetail.vendorName}, `,
                bold: true,
              },
              poDetail.msNbr && { text: `MS# ${poDetail.msNbr}` },
            ],
          },
          table(
            poDetail,
            ["modelNbr", "skuNbr", "lineReferenceId", "quantity"],
            ["35%", "25%", "25%", "15%"],
            true,
            [
              { text: "MODEL #", style: "poHeader" },
              { text: "STORE SKU", style: "poHeader" },
              { text: "SERIAL #", style: "poHeader" },
              { text: "QTY", style: "poHeader" },
            ]
          ),
        ]),

      notes &&
        (notes.length > 0 ? "true" : null) &&
        headerTable(customerDetail, "notes"),
      {
        width: "100%",
        margin: [0, 17, 0, 0],
        text: {},
      },
      notes &&
        (notes.length > 0 ? "true" : null) && {
          table: {
            widths: ["50%", "50%"],
            heights: [41],
            style: "secondaryHeader",
            body: [
              [
                {
                  text: "Notes",
                  height: 80,
                  style: "secondaryHeader",
                  colSpan: 2,
                },
              ],
            ],
          },
          layout: {
            hLineWidth: function (i, node) {
              return i % 2 === 1 ? 3 : 0;
            },
            vLineWidth: function (i, node) {
              return 0;
            },
            hLineColor: function (i, node) {
              return "#A9A8A8";
            },
          },
        },
      notes &&
        (notes.length > 0 ? "true" : null) && {
          table: {
            widths: ["100%"],
            body: notes.map((note) => [
              {
                style: "notesLabel",
                text: [
                  {
                    text: `${formatTimeStamp(note.createTimeStamp)} | `,
                    style: "notesLabel",
                  },
                  {
                    text: `${note.lastUpdUserId}:\n`,
                    bold: true,
                    style: "notesLabel",
                  },
                  {
                    text: note.deliveryNoteText,
                    bold: true,
                    style: "notesText",
                  },
                ],
              },
            ]),
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0;
            },
            vLineWidth: function (i, node) {
              return 0;
            },
            fillColor: function (i, node) {
              return i % 2 === 1 ? "#F0EFEF" : null;
            },
          },
        },
    ],
    styles: {
      sectionHeader: {
        color: "black",
        fontSize: 22,
        fillColor: "#E2E2E2",
        margin: [15, 20, 15, 20],
        height: 61,
        fontStyle: "Open Sans",
        bold: true,
      },
      secondaryHeader: {
        color: "black",
        fontSize: 20,
        fillColor: "#E2E2E2",
        margin: [15, 10, 15, 10],
        fontStyle: "Open Sans",
        bold: true,
      },
      customerInfo: {
        fontSize: 18,
        color: "#4A4A4A",
        margin: [20, 20, 20, 20],
        fontStyle: "Open Sans",
      },
      semi: {
        fontWeight: 600,
      },
      regular: {
        fontWeight: 400,
      },
      partsServices: {
        margin: [0, 15, 0, 16],
        fontSize: 17,
        fontStyle: "Open Sans",
      },
      vendorNm: {
        margin: [0, 24, 0, 24],
        fontSize: 17,
        fontStyle: "Open Sans",
      },
      wotable: {
        margin: [20, 0, 0, 10],
      },
      woitems: {
        margin: [5, 5, 0, 5],
        fontSize: 17,
        fontWeight: 300,
        fontStyle: "Open Sans",
      },
      poTable: {
        margin: [20, 0, 0, 0],
        fontSize: 17,
        fontWeight: 300,
        fontStyle: "Open Sans",
      },
      tableContent: {
        margin: [20, 10, 0, 0],
        width: "100%",
        borderColor: "#eeeeee",
      },
      poHeader: {
        fontWeight: 400,
        fontSize: 15,
        //margin: [5, 2, 5, 2],
        color: "#686969",
      },
      cell: {
        fontSize: 9,
        margin: [5, 2, 5, 2],
      },
      notesText: {
        //margin: [20, 10, 0, 0],
        fontSize: 13,
        fontStyle: "Open Sans",
        fontWeight: 600,
      },
      notesLabel: {
        margin: [20, 16, 0, 16],
        fontSize: 13,
        fontStyle: "Open Sans",
        color: "#404040",
      },
    },
  };
  pdfMake.createPdf(documentDefinition).open();
};
