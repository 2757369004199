import React, { useState } from "react";
import { Button, Modal, Spin } from "antd";
import "./Pod.less";
import PropTypes from "prop-types";
import moment from "moment";
import usePodImage from "../../../hooks/getPodImagesHook";
import getBffUrl from "../../../util/getBffUrl";
import { Image } from "antd";
import useNewRelicMetrics from "../../../hooks/newRelicMetricsHook";
import { VIEW_POD_EVENT } from "../../../util/NewRelicConstants";
const PodModal = ({
  url,
  isModalVisible,
  setIsModalVisible,
  customerDetail,
}) => {
  const { podImages } = usePodImage(url, false);
  const { data, loading, error } = podImages;

  // This function checks that the string contains only valid base64 characters,
  // and that the length of the string is a multiple of 4 (which is a requirement for base64 encoding).
  function isBase64Image(str) {
    if (!str) {
      return false;
    }

    const base64regex =
      /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;
    return base64regex.test(str);
  }

  return (
    <Modal
      title={null}
      footer={null}
      width="60%"
      open={isModalVisible}
      onCancel={() => {
        setIsModalVisible(false);
      }}
    >
      <div id="pod-modal">
        <div className="pod-modal-header-label">{`POD for ${
          customerDetail.custFirstName
        } ${customerDetail.custLastName}'s delivery on ${moment(
          customerDetail.scheduledDeliveryDate
        ).format("MM/DD/YY")}`}</div>
        <div>
          {loading && (
            <div className="pod-spinner">
              <Spin size="large" />
            </div>
          )}
          {(error ||
            (data && data.attachments && data.attachments.length === 0)) && (
            <div>
              <span className={"attachment-not-found-text"}>
                No Images Available
              </span>
            </div>
          )}
          <div className="pod-images-container">
            {data.attachments &&
              data.attachments.length !== 0 &&
              data.attachments.map((podDetail, i) => {
                if (isBase64Image(podDetail.base64Image)) {
                  return (
                    <div key={i} className="pod-image-wrapper">
                      <Image.PreviewGroup>
                        <Image
                          src={`data:${podDetail.contentType.image}/${podDetail.contentType.subType};base64, ${podDetail.base64Image}`}
                        />
                      </Image.PreviewGroup>
                    </div>
                  );
                } else {
                  return (
                    <span key={i} className={"attachment-not-found-text"}>
                      No Image Available
                    </span>
                  );
                }
              })}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const Pod = (props) => {
  const {
    customerDetail,
    reservationId,
    referenceId,
    sourceSystem,
    orderNumber,
    orderIndex,
  } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { logNewRelicMetricsEvent } = useNewRelicMetrics();
  const reservationIdString = reservationId
    ? `&reservationId=${reservationId}`
    : "";
  const sourceSystemString = sourceSystem ? `&orderType=${sourceSystem}` : "";
  const url = `${getBffUrl()}/deliveryviz/v1/attachments/${referenceId}?attachmentTypes=POD,Signature,IMG${sourceSystemString}${reservationIdString}`;

  return (
    <div>
      <Button
        id="pod-view-button"
        className="default-btn"
        block
        onClick={() => {
          logNewRelicMetricsEvent(VIEW_POD_EVENT, {
            order_number: orderNumber,
            index: orderIndex,
          });
          setIsModalVisible(true);
        }}
      >
        View POD
      </Button>
      {isModalVisible && (
        <PodModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          customerDetail={customerDetail}
          url={url}
        />
      )}
    </div>
  );
};
Pod.propTypes = {
  referenceId: PropTypes.string.isRequired,
  sourceSystem: PropTypes.string,
  customerDetail: PropTypes.any,
};
export default Pod;
