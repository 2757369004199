// AppLayout.js
import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import InitialRoleModal from "../InitialRoleModal/InitialRoleModal";

const AppLayout = ({ user }) => {
  return (
    <div id="main-content">
      <Header user={user} />
      <Outlet />
      {!user.roles && <InitialRoleModal />}
    </div>
  );
};

export default AppLayout;
