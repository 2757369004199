import React, { useContext } from "react";
import { Button } from "antd";
import {
  MailMessageContext,
  CreateMessageContext,
  CloseMailMessageContext,
  UserContext,
} from "../../context/RootContext";

export const CellContent = ({ text, record }) => {
  const { getMailMessage } = useContext(MailMessageContext);
  const { cleanCloseMailMessage } = useContext(CloseMailMessageContext);
  const { cleanCreateMessage } = useContext(CreateMessageContext);
  const { user } = useContext(UserContext);
  const { ldapId } = user;

  return (
    <Button
      type="link"
      style={{ textAlign: "start" }}
      onClick={() => {
        getMailMessage(record, ldapId);
        cleanCloseMailMessage();
        cleanCreateMessage();
      }}
    >
      {text}
    </Button>
  );
};
