import { Button, Row, Col } from "antd";
import React, { useContext, useState } from "react";
import { ReactComponent as EditIcon } from "../../../../icons/edit.svg";
import PropTypes from "prop-types";
import MicroUI from "../../../DeliveryMicroUI/MicroUI";
import { Modal } from "antd";
import OrderContext from "../../../../context/OrderContext";
import "./CustomerInfoCommons.less";
import {
  CLOSE_CUSTOMER_INFO_MODAL_EVENT,
  CUSTOMER_INFO_EVENT,
  WORK_BIN_RESCHEDULE_EVENT,
} from "../../../../util/NewRelicConstants";
import useNewRelicMetrics from "../../../../hooks/newRelicMetricsHook";
import { SOURCE_SYSTEM_ORDER_UP } from "../../../../util/Constants";

function CustomerInfoCommons({
  orderIndex,
  customerOrderNumber,
  isWorkBin,
  customerDetail,
  location,
  orderSource,
  workOrderNumber,
  orderNumber,
  hideCustomerInfoEdit,
}) {
  const { updateOrder } = useContext(OrderContext);
  const [isCustomerInfoOpen, openCustomerInfoModal] = useState(false);
  const { logNewRelicMetricsEvent } = useNewRelicMetrics();
  const shouldShowMicroUI = orderSource
    .trim()
    .toUpperCase()
    .includes(SOURCE_SYSTEM_ORDER_UP);

  return (
    <div className="customer-info-card">
      {customerDetail && (
        <>
          <Row gutter={0} style={{ padding: "0" }}>
            <div className="customer-details-container">
              <span className="customer-name">
                {customerDetail.lastName}, {customerDetail.firstName}{" "}
                {customerDetail.middleName && customerDetail.middleName}
              </span>
              <Col span={4} align="center" className="customer-pro-label">
                {customerDetail.isProCustomer &&
                  customerDetail.isProCustomer === true && <span>Pro</span>}
              </Col>
              <Col span={4} align="center">
                <Button
                  hidden={hideCustomerInfoEdit}
                  aria-label="edit"
                  className="customer-info-edit-icon"
                  type="link"
                  onClick={() => {
                    const eventType = isWorkBin
                      ? WORK_BIN_RESCHEDULE_EVENT
                      : CUSTOMER_INFO_EVENT;
                    logNewRelicMetricsEvent(eventType, {
                      order_number: customerOrderNumber,
                      text: "pencil",
                      index: orderIndex,
                    });
                    openCustomerInfoModal(true);
                  }}
                >
                  <EditIcon />
                </Button>
              </Col>
            </div>

            {isCustomerInfoOpen && (
              <Modal
                centered
                visible={isCustomerInfoOpen}
                onCancel={() => {
                  // The difference between OU and non-OU flows is that
                  // the reschedule call in OU is out of our control, it happens in the app that runs in iframe.
                  // That is why in non-OU flow the order will be updated after reschedule call
                  // returns 200 response status.
                  // But in OU-flow here we update order when modal is closed by user after he finished his work in OU
                  logNewRelicMetricsEvent(CLOSE_CUSTOMER_INFO_MODAL_EVENT, {
                    order_number: orderNumber,
                    index: orderIndex,
                    overlay_type: "commons",
                  });
                  if (shouldShowMicroUI) {
                    updateOrder();
                  }
                  openCustomerInfoModal(false);
                }}
                footer={null}
                width={shouldShowMicroUI ? "87.5%" : "65%"}
              >
                <div slot="body">
                  {shouldShowMicroUI && (
                    <MicroUI
                      location={location}
                      workOrderNumber={workOrderNumber}
                      orderNumber={orderNumber}
                    />
                  )}
                </div>
              </Modal>
            )}
            <Col span={24} className="customer-pro-name">
              {customerDetail.isProCustomer &&
                customerDetail.isProCustomer === true && (
                  <span>{customerDetail.companyName}</span>
                )}
            </Col>
          </Row>
          <br />
          <p className="customer-address">
            {customerDetail.streetAddress1}
            {customerDetail.streetAddress1 && <br />}
            {customerDetail.streetAddress2}
            {customerDetail.streetAddress2 && <br />}
            {customerDetail.city}, {customerDetail.state}{" "}
            {customerDetail.zipCode}
          </p>
          <div className="customer-phone-number">
            <p>
              {customerDetail.dayPhone}
              {customerDetail.mobilePhone && <br />}
              {customerDetail.mobilePhone}
              {customerDetail.otherPhone && <br />}
              {customerDetail.otherPhone}
            </p>
          </div>
          {customerDetail.emailId && (
            <div className="customer-email-id">
              <Col span={24} align="left">
                <p>
                  <span className="customer-email-type">Email: </span>
                  {customerDetail.emailId}
                </p>
              </Col>
            </div>
          )}
        </>
      )}
    </div>
  );
}

CustomerInfoCommons.propTypes = {
  customerDetail: PropTypes.object.isRequired,
  workOrderNumber: PropTypes.string,
  orderNumber: PropTypes.string,
  location: PropTypes.string,
  orderSource: PropTypes.string,
};

export default CustomerInfoCommons;
