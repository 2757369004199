import axios from "axios";
import { useEffect, useState } from "react";

const usePodImage = (url, handler) => {
  const [podImages, setPodImages] = useState({
    loading: false,
    data: [],
    error: false,
  });

  useEffect(() => {
    if (!handler) {
      getPodImages(url);
    }
  }, []);

  const getPodImages = async () => {
    let imageData = null;
    setPodImages({
      ...podImages,
      loading: true,
    });
    try {
      const response = await axios.get(url);
      if (response && response.status === 200 && response.data) {
        setPodImages({
          loading: false,
          data: response.data,
          error: false,
        });
        imageData = response.data;
      } else {
        setPodImages({
          loading: false,
          data: [],
          error: false,
        });
      }
    } catch (error) {
      console.error(error);
      setPodImages({
        loading: false,
        data: [],
        error: true,
      });
    }
    if (handler) {
      return imageData;
    }
  };

  return { podImages, getPodImages };
};

export default usePodImage;
