import React from "react";
import "./RevisitToolTip.less";
import { Button, Tooltip } from "antd";
import { UpOutlined } from "@ant-design/icons";

export const RevisitToolTip = ({ buttonName, isUpOutline }) => {
  const alertMessage = (
    <Tooltip>
      <div className="container">
        <p className="title">Create Revisit Disabled</p>
        <p className="bottom-line"></p>
        <p className="text-message">
          Please go to original
          <br />
          order to generate the
          <br />
          revisit.
        </p>
      </div>
    </Tooltip>
  );

  const getInlineStyles = () => {
    return isUpOutline
      ? {
          width: "100%",
          backgroundColor: "#f5f5f5",
          color: "#ccc",
        }
      : {
          backgroundColor: "#f5f5f5",
          color: "#ccc",
        };
  };

  const renderButtonName = () => {
    return isUpOutline ? (
      <div className="button-content">
        <span />
        <span>{buttonName}</span>
        <span>
          <UpOutlined />
        </span>
      </div>
    ) : (
      <div>
        <span>{buttonName}</span>
      </div>
    );
  };

  const renderDisableAlert = () => {
    return (
      <div>
        <div id="create-revisit-dropDown">
          <Tooltip placement="left" overlay={alertMessage} trigger={["hover"]}>
            <Button
              disabled={true}
              className="revisit-button disabled-btn"
              style={getInlineStyles()}
              // eslint-disable-next-line no-empty-function
              onClick={() => {}}
            >
              {renderButtonName()}
            </Button>
          </Tooltip>
        </div>
      </div>
    );
  };

  return renderDisableAlert();
};
