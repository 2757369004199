import { notification } from "antd";
import * as constants from "./Constants";

export const showNotification = (description, error) => {
  notification.open({
    key: `open${Date.now()}`,
    ...(error
      ? constants.ERROR_NOTIFICATION_CONFIGS
      : constants.SUCCESS_NOTIFICATION_CONFIGS),
    message: error ? "Error" : "Success",
    description: description,
  });
};
