import React, { useState } from "react";
import axios from "axios";
import getBffUrl from "../../../../util/getBffUrl";
import { Drawer, Button, Row, Col, Divider, DatePicker, Modal } from "antd";
import moment from "moment";
import "./ShipToHomeRescheduleDrawer.less";
import { formatTo12Hour } from "../../../../util/DateFormatter";
import useNotification from "../../../../hooks/useNotification";
import { CLOSE_RESCHEDULE_MODAL_EVENT } from "../../../../util/NewRelicConstants";
import useNewRelicMetrics from "../../../../hooks/newRelicMetricsHook";

moment.locale("en");

export default function ShipToHomeRescheduleDrawer({
  deliveryDetail,
  reservationId,
  isDrawerOpen,
  setDrawerOpen,
  sthRefreshDeliveries,
  customerOrderNumber,
  orderIndex,
}) {
  const { deliveryStartDateTime, deliveryEndDateTime } = deliveryDetail;
  const [deliveryStartDate, deliveryStartTime] = deliveryStartDateTime
    ? deliveryStartDateTime.split("T")
    : ["", ""];
  const [, deliveryEndTime] = deliveryEndDateTime
    ? deliveryEndDateTime.split("T")
    : ["", ""];
  const [rescheduleDate, setRescheduleDate] = useState(
    moment(deliveryStartDate)
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { openErrorNotification, contextHolder } = useNotification();
  const { logNewRelicMetricsEvent } = useNewRelicMetrics();

  const showModal = () => {
    setIsModalOpen(true);
  };

  async function rescheduleOrder(payload) {
    const url = `${getBffUrl()}/v1/sth/reschedule`;
    try {
      setIsModalOpen(false);
      setDrawerOpen(false);
      await axios.post(url, payload);
    } catch (error) {
      console.error("Error rescheduling order", error);
      throw error;
    }
  }

  const handleOk = async () => {
    try {
      const deliveryRescheduledToDate = rescheduleDate.format("YYYY-MM-DD");
      await rescheduleOrder({
        reservationId,
        deliveryRescheduledToDate,
      });
      sthRefreshDeliveries(
        deliveryDetail.workOrder,
        deliveryStartDate,
        deliveryRescheduledToDate
      );
    } catch (error) {
      openErrorNotification({
        message: "Error Rescheduling Order",
        description:
          "We were unable to reschedule this delivery. Please try again later. If the issue continues, contact DMP_Support@homedepot.com.",
      });
    } finally {
      setIsModalOpen(false);
      setDrawerOpen(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Drawer
      className="shiptohome-drawer"
      height="45vh"
      title={<span className="drawer-title">Reschedule Delivery</span>}
      placement="bottom"
      closable={true}
      onClose={() => {
        logNewRelicMetricsEvent(CLOSE_RESCHEDULE_MODAL_EVENT, {
          order_number: customerOrderNumber,
          index: orderIndex,
        });
        setDrawerOpen(false);
      }}
      open={isDrawerOpen}
    >
      {contextHolder}
      <div className="drawer-content-wrapper">
        <Row className="drawer-content" gutter={[8, 8]}>
          <Col span={3}>
            <p>
              <b>Current Delivery Date:</b>
            </p>
            <p>
              Scheduled for {moment(deliveryStartDate).format("MM/DD/YYYY")}
            </p>
            <p>
              {formatTo12Hour(deliveryStartTime)} -{" "}
              {formatTo12Hour(deliveryEndTime)}
            </p>
          </Col>
          <Col xs={1} align="center" className="vertical-dividers">
            <Divider type="vertical" />
          </Col>
          <Col span={20} className="datepicker-column">
            <Row justify="center">
              <Col>
                <div className="datepicker-wrapper">
                  <b>Select a Date </b>
                  <DatePicker
                    className="reschedule-datepicker"
                    placement="bottomRight"
                    popupClassName="reschedule-datepicker-popup"
                    getPopupContainer={() =>
                      document.getElementById("date-popup")
                    }
                    open={isDrawerOpen}
                    disabledDate={(current) => {
                      if (current && current.day() === 0) {
                        return true;
                      }
                      if (current && current.isSameOrBefore(moment.now())) {
                        return true;
                      }
                      return false;
                      //Holidays TBD
                    }}
                    onChange={(date, dateString) => {
                      setRescheduleDate(date);
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <div id="date-popup" />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="drawer-footer">
        <Row className="actions-wrapper">
          <Button type="primary" onClick={showModal}>
            Submit
          </Button>
          <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <div>
              <p>
                <b>Please Confirm</b>
              </p>
              Are you sure you want to reschedule Order{" "}
              {deliveryDetail.workOrder} for{" "}
              {rescheduleDate.format("dddd, MMMM DD, YYYY", "en")}?
            </div>
          </Modal>
        </Row>
      </div>
    </Drawer>
  );
}
