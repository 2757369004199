import React, { useContext, useState } from "react";
import SearchContext from "./SearchContext";

export const DeliveriesCommonContext = React.createContext({
  loading: false,
  searchResult: {
    deliveries: [],
    pageSize: 0,
    total: 0,
    warning: null,
  },

  // eslint-disable-next-line no-empty-function
  setLoading: () => {},
  // eslint-disable-next-line no-empty-function
  setInitSearchResult: () => {},
  // eslint-disable-next-line no-empty-function
  setSearchResult: () => {},
});

export const DeliveriesCommonProvider = ({ children }) => {
  const defaultInitSearchResult = {
    deliveries: [],
    pageSize: 0,
    total: 0,
    warning: null,
  };
  const initialPaginationState = {
    currentPage: 0,
    pageNumber: 1,
    pageSize: 100,
  };

  const [initSearchResult, setInitSearchResult] = useState(
    defaultInitSearchResult
  );
  const [searchResult, setSearchResult] = useState({});

  return (
    <DeliveriesCommonContext.Provider
      value={{
        initialPaginationState,
        initSearchResult,
        setInitSearchResult,
        searchResult,
        setSearchResult,
      }}
    >
      {children}
    </DeliveriesCommonContext.Provider>
  );
};

export default DeliveriesCommonContext;
