import axios from "axios";
import getBffUrl from "./getBffUrl";

export async function getProductDetails(purchaseOrderDetail) {
  try {
    const response = await axios.get(
      `${getBffUrl()}/v1/products?skuNumbers=${getSkuNumbers(
        purchaseOrderDetail
      )}`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
    return [];
  }
}

function getSkuNumbers(purchaseOrderDetail) {
  const skuNumbers = [];
  purchaseOrderDetail.map((orderItem) => {
    if (orderItem.lineItems) {
      orderItem.lineItems.forEach((lineItem) => {
        if (lineItem.skuNumber) {
          skuNumbers.push(lineItem.skuNumber);
        }
      });
    }
  });

  return skuNumbers;
}

export function mapImagesWithOrder(order, productImages) {
  const newOrder = { ...order };
  newOrder.purchaseOrderDetail.forEach((orderItem) => {
    if (orderItem.lineItems) {
      orderItem.lineItems.forEach((lineItem) => {
        if (lineItem.skuNumber && productImages && productImages.products) {
          const product = productImages.products.find(
            (product) => lineItem.skuNumber === product.sku.toString()
          );
          if (product) {
            lineItem["images"] = product.images;
            lineItem["dimensions"] = product.dimensions;
          }
        }
      });
    }
  });
  return newOrder;
}
