import React, { useState, useEffect } from "react";
import { Button, Popover, Space } from "antd";
import "./TrackerInfo.less";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { ORDER_ITEM_DELIVERY_TRACKER } from "../../../../util/NewRelicConstants";
import useNewRelicMetrics from "../../../../hooks/newRelicMetricsHook";

function TrackerInfo({ orderIndex, customerOrderNumber, trackerLink }) {
  const [buttonSize, setButtonSize] = useState("large");
  const { logNewRelicMetricsEvent } = useNewRelicMetrics();

  function openToTracker(trackerUrl) {
    logNewRelicMetricsEvent(ORDER_ITEM_DELIVERY_TRACKER, {
      order_number: customerOrderNumber,
      index: orderIndex,
      location: trackerUrl,
      text: "Delivery Tracker",
    });
    window.open(trackerUrl, "_blank");
  }

  useEffect(() => {
    // This effect will resize the tracker button based on screen size
    const handleResize = () => {
      if (window.innerWidth < 950) {
        setButtonSize("small");
      } else {
        setButtonSize("large");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const popoverContent = (
    <div>
      <Space>
        <ExclamationCircleFilled />
        <span>Delivery Tracker Currently Unavailable</span>
      </Space>
    </div>
  );

  return (
    <div className="tracker-info-card">
      {trackerLink ? (
        <Button
          data-testid="delivery-tracker-button"
          type="secondary"
          size={buttonSize}
          onClick={() => {
            openToTracker(trackerLink);
          }}
        >
          Delivery Tracker
        </Button>
      ) : (
        <Popover
          placement="topLeft"
          arrowPointAtCenter
          content={popoverContent}
        >
          <Button
            data-testid="delivery-tracker-button"
            disabled={true}
            type="secondary"
            size={buttonSize}
            onClick={() => {
              openToTracker(trackerLink);
            }}
          >
            Delivery Tracker
          </Button>
        </Popover>
      )}
    </div>
  );
}

export default TrackerInfo;
