import { useContext, useState } from "react";
import axios from "axios";
import getBffUrl from "../../util/getBffUrl";
import {
  mapImagesWithOrder,
  getProductDetails,
} from "../../util/orderDetailsUtilities";
import { UserContext } from "../../context/RootContext";
import { checkAdditionalRoleAccessForUser } from "../../util/Permissions";
import { DMP_WORKBIN_ACCESS_GROUP } from "../../util/Constants";

function useOrderDetails() {
  const { user } = useContext(UserContext);
  const [orderDetails, setOrderDetails] = useState({});

  const orderDetailsHandler = {
    onExpand: async (expanded, record) => {
      if (expanded) {
        setOrderDetails({
          ...orderDetails,
          [record.key]: {
            data: {},
            loading: true,
          },
        });
        await orderDetailsHandler.updateOrderDetails(record);
      }
    },
    updateOrderDetails: async (record) => {
      const orderDetail = await getOrderDetails({
        orderNumber: record.customerOrderNumber,
        workOrderNumber: record.workOrder,
      });

      const enrichedOrderDetail =
        orderDetail && orderDetail.purchaseOrderDetail
          ? mapImagesWithOrder(
              orderDetail,
              await getProductDetails(orderDetail.purchaseOrderDetail)
            )
          : orderDetail;

      updateOrderDetailsState(record.key, enrichedOrderDetail);
    },
    getOrderCardColor: (record) => {
      switch (record.workOrderStatus) {
        case "Work Order Created":
        case "Submitted And Approved":
        case "Ready For Load":
          return "blue";
        case "In-Transit":
        case "Delivery Confirmation Obtained":
        case "Delivery Completed":
          return "green";
        case "Delivery Attempted":
        case "Dispatch Rejected":
          return "red";
        case "Work Order Cancelled":
          return "gray";
        default:
          return "";
      }
    },
    getWorkBinExceptions: (record) => {
      return (record.deliveryExceptions || [])
        .filter((deliveryException) =>
          [
            "Dispatch Rejected",
            "Vehicle Type Not Supported",
            "No Schedule Delivery Date",
          ].includes(deliveryException.exception)
        )
        .map((deliveryException) => deliveryException.exception);
    },
    gerOrderBackground: (record) => {
      return checkAdditionalRoleAccessForUser(DMP_WORKBIN_ACCESS_GROUP, user) &&
        orderDetailsHandler.getWorkBinExceptions(record).length > 0
        ? "table-row-hued-red"
        : "";
    },
  };

  async function getOrderDetails(params) {
    try {
      const response = await axios.get(`${getBffUrl()}/v1/order`, { params });
      if (response && response.status === 200) {
        return response.data;
      }
      return {};
    } catch (error) {
      console.log("ERROR FETCHING ORDER DETAILS", error);
      return {};
    }
  }

  function updateOrderDetailsState(key, data) {
    setOrderDetails((prevState) => {
      return {
        ...prevState,
        [key]: {
          data: data,
          loading: false,
        },
      };
    });
  }

  return { orderDetails, ...orderDetailsHandler };
}

export default useOrderDetails;
