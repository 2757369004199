import React, { useContext } from "react";
import "./CustomerInfo.less";
import PropTypes from "prop-types";
import MicroUI from "../DeliveryMicroUI/MicroUI";
import UpdateCustomerInfo from "../Actions/UpdateCustomerInfo/UpdateCustomerInfo";
import { UserContext } from "../../context/RootContext";
import permit from "../../util/Permissions";
import { Modal } from "antd";
import { ReactComponent as EditIcon } from "../../icons/edit.svg";
import OrderContext from "../../context/OrderContext";
import {
  CANCEL_CUSTOMER_INFO_MODAL_EVENT,
  CLOSE_CUSTOMER_INFO_MODAL_EVENT,
  CUSTOMER_INFO_EVENT,
} from "../../util/NewRelicConstants";
import useNewRelicMetrics from "../../hooks/newRelicMetricsHook";
import { SOURCE_SYSTEM_ORDER_UP } from "../../util/Constants";

const CustomerInfo = ({
  customerDetail,
  purchaseOrderDetailList,
  workOrderNumber,
  orderNumber,
  orderIndex,
  location,
  orderSource,
  onlineCommonsFlag,
  showPen,
  storeNumber,
}) => {
  const { user } = useContext(UserContext);
  const {
    updateOrder,
    setCustomerInfoIsUpdated,
    isUpdateCustomerInfoLoading,
    setUpdateCustomerInfoIsLoading,
    isCustomerInfoOpen,
    openCustomerInfoModal,
  } = useContext(OrderContext);

  const { logNewRelicMetricsEvent } = useNewRelicMetrics();

  storeNumber = !storeNumber ? "" : ` (Store #${storeNumber})`;

  const shouldShowMicroUI = orderSource
    .trim()
    .toUpperCase()
    .includes(SOURCE_SYSTEM_ORDER_UP);

  return (
    <div className="customer-info-container">
      <div className="order-id-wrapper">
        <div id="order-id">
          {customerDetail.custOrdId}
          {permit("updateCustInfo", user) &&
            (!permit("applyUpdateCustInfoProductFilter", user) ||
              (permit("applyUpdateCustInfoProductFilter", user) &&
                purchaseOrderDetailList
                  .map((po) => po.vendorNbr)
                  .toString()
                  .includes(user.mvendorNum))) &&
            showPen && (
              <div className="edit-customer-info-icon">
                <EditIcon
                  onClick={() => {
                    logNewRelicMetricsEvent(CUSTOMER_INFO_EVENT, {
                      order_number: orderNumber,
                      text: "pencil",
                      index: orderIndex,
                    });
                    openCustomerInfoModal(true);
                  }}
                />
              </div>
            )}
        </div>
        <div id="customer-name">
          {customerDetail.custLastName}, {customerDetail.custFirstName}
        </div>
      </div>
      {isCustomerInfoOpen && (
        <Modal
          centered
          open={isCustomerInfoOpen}
          onCancel={() => {
            // The difference between OU and non-OU flows is that
            // the reschedule call in OU is out of our control, it happens in the app that runs in iframe.
            // That is why in non-OU flow the order will be updated after reschedule call
            // returns 200 response status.
            // But in OU-flow here we update order when modal is closed by user after he finished his work in OU
            if (shouldShowMicroUI) {
              updateOrder();
              logNewRelicMetricsEvent(CLOSE_CUSTOMER_INFO_MODAL_EVENT, {
                order_number: orderNumber,
                index: orderIndex,
                overlay_type: "commons",
              });
            } else {
              logNewRelicMetricsEvent(CLOSE_CUSTOMER_INFO_MODAL_EVENT, {
                order_number: orderNumber,
                index: orderIndex,
                overlay_type: "legacy",
              });
            }
            openCustomerInfoModal(false);
          }}
          footer={null}
          width={shouldShowMicroUI ? "87.5%" : "65%"}
        >
          <div slot="body">
            {shouldShowMicroUI ? (
              <MicroUI
                location={location}
                workOrderNumber={workOrderNumber}
                orderNumber={orderNumber}
              />
            ) : (
              <UpdateCustomerInfo
                customerDetail={customerDetail}
                orderNumber={orderNumber}
                setCustomerInfoIsUpdated={setCustomerInfoIsUpdated}
                isUpdateCustomerInfoLoading={isUpdateCustomerInfoLoading}
                setUpdateCustomerInfoIsLoading={setUpdateCustomerInfoIsLoading}
                handleClose={() => {
                  updateOrder();
                }}
                simpleClose={() => {
                  logNewRelicMetricsEvent(CANCEL_CUSTOMER_INFO_MODAL_EVENT, {
                    order_number: orderNumber,
                    index: orderIndex,
                    overlay_type: "legacy",
                  });
                  openCustomerInfoModal(false);
                }}
              />
            )}
          </div>
        </Modal>
      )}
      <div className="address">
        <div>{customerDetail.custStreetAddress1.toLocaleLowerCase()}</div>
        {customerDetail.custStreetAddress2 && (
          <div>{customerDetail.custStreetAddress2.toLocaleLowerCase()}</div>
        )}
        <div>
          {customerDetail.custCity.toLocaleLowerCase()},{" "}
          {customerDetail.custState} {customerDetail.custZipCode}
        </div>
      </div>

      <div className={"contact-info"}>
        <div id="phone-number">
          <span>H </span>
          {customerDetail.custPhoneNbr}
        </div>
        {customerDetail.custAltPhoneNbr && (
          <div id="alt-phone-number">
            <span>O </span>
            {customerDetail.custAltPhoneNbr}
          </div>
        )}
        <div id="email">
          <span>{customerDetail.emailId}</span>
        </div>
      </div>
      <div id="source">
        Source:{" "}
        {onlineCommonsFlag === "Y" ? (
          <span>Online{storeNumber}</span>
        ) : (
          <span>
            {orderSource.includes("Store")
              ? orderSource
              : orderSource + storeNumber}
          </span>
        )}
      </div>
    </div>
  );
};

CustomerInfo.propTypes = {
  customerDetail: PropTypes.object.isRequired,
  workOrderNumber: PropTypes.string,
  orderNumber: PropTypes.string,
  location: PropTypes.string,
  orderSource: PropTypes.string,
};

export default CustomerInfo;
