import React from "react";
import { Button } from "antd";
import "./ManifestButton.less";
import PropTypes from "prop-types";
import { getRouteManifestPrintUrl } from "../../../util/RoutePlanner/routePlannerUtils";
import { ExportOutlined } from "@ant-design/icons";

const ManifestButton = (props) => {
  const { routeId, selectedDate, isEnabled, tab, buttonName } = props;
  return (
    <div>
      <Button
        id="manifest-view-button"
        icon={<ExportOutlined />}
        aria-label="open-manifest"
        className="default-btn"
        type="primary"
        block
        onClick={() => {
          window.open(
            getRouteManifestPrintUrl(tab, selectedDate, routeId),
            "_blank"
          );
        }}
        disabled={!isEnabled}
      >
        {buttonName}
      </Button>
    </div>
  );
};
ManifestButton.propTypes = {
  routeId: PropTypes.string,
  selectedDate: PropTypes.any,
  isEnabled: PropTypes.bool.isRequired,
};
export default ManifestButton;
