import PhoneNumberFormatter from "../../util/PhoneNumberFormatter";
export const SEARCH_TYPES = [
  {
    value: "MSNumber",
    label: "MSN",
  },
  {
    value: "OrderNumber",
    label: "Order number",
  },
  {
    value: "CustomerName",
    label: "Customer name",
  },
  {
    value: "CustomerPhone",
    label: "Customer phone",
  },
  {
    value: "PONumber",
    label: "PO number",
  },
  {
    value: "WONumber",
    label: "WO number",
  },
];

/*
  This specific function is needed for STH Work Order Number search.
  STH Work Orders are formatted by combining the Customer Order Number
  and the PO Number (ex: WM12345678-98765432) which is unlike traditional
  Work Order Numbers for other flows. This logic splits the STH Work
  Order Number and tests it against our existing Order Number and PO
  Number regex logics.
   */
function sthWorkOrderInputValidation(input) {
  let orderNumberInput = "";
  let poNumberInput = "";

  orderNumberInput = input.substring(0, input.lastIndexOf("-"));
  poNumberInput = input.substring(input.lastIndexOf("-") + 1);

  return (
    new RegExp("^([Hh][0-9]*-[0-9]*)$|^([WwCc][0-9a-zA-Z][0-9]*)$", "i").test(
      orderNumberInput
    ) && new RegExp("^[0-9]{6,10}$", "i").test(poNumberInput)
  );
}

const isMsnValid = (str) => {
  return new RegExp("^[a-z0-9]{10}$", "i").test(str);
};

const isOrderNumberValid = (str) => {
  return new RegExp(
    "^([Hh][0-9]*-[0-9]*)$|^([WwCc][0-9a-zA-Z][0-9]*)$",
    "i"
  ).test(str);
};

const isNameValid = (name) => {
  return new RegExp("^[^%\\\\'\"]+$", "i").test(name);
};
const isCustomerNameValid = (lastName, firstName) => {
  return (
    (lastName && lastName.length > 0 ? isNameValid(lastName) : false) &&
    (firstName && firstName.length > 0 ? isNameValid(firstName) : true)
  );
};

const isPurchaseOrderValid = (purchaseOrder, storeNumber) => {
  return (
    (purchaseOrder && purchaseOrder.length > 0
      ? new RegExp("^[0-9]{6,10}$", "i").test(purchaseOrder)
      : false) &&
    (storeNumber && storeNumber.length > 0
      ? new RegExp("^[0-9]{4}$", "i").test(storeNumber)
      : true)
  );
};

const isPhoneNumberValid = (number) => {
  const num = PhoneNumberFormatter(number);
  return num.isValid;
};

export const safeFilter = (str) => {
  if (typeof str !== "string") {
    return str;
  }
  // if anything is not alphanumeric, replace it with an empty string
  // allowed chars:
  //  - letters
  //  - numbers
  //  - spaces
  //  - dashes
  //  - slashes
  return str.replace(/[^\\/a-zA-Z0-9\s\\-]/g, "");
};

export function sanitizer(values) {
  const keys = Object.keys(values);

  return keys.reduce((acc, key) => {
    const value = safeFilter(values[key]);

    if (key === "type") {
      const allowedValues = SEARCH_TYPES.map((type) => {
        return type.value;
      }).concat("DeliveryDate");
      if (!allowedValues.includes(value)) {
        return { ...acc, [key]: "" };
      }
    }
    if (key === "input") {
      if (values.type === "CustomerName") {
        if (!isCustomerNameValid(value)) {
          const val = value.replace(/[%"\\']/gi, "");
          return { ...acc, [key]: val };
        }
      }
      const val = sanitizeInput(value, values.type);
      return { ...acc, [key]: val };
    }

    if (key === "pageNumber") {
      const val = parseInt(value, 10);
      if (!isNaN(val)) {
        return { ...acc, [key]: val };
      }
    }

    if (key === "pageSize") {
      const val = parseInt(value, 10);
      if (!isNaN(val)) {
        if (val <= 100 && val >= 0) {
          return { ...acc, [key]: val };
        }
      }
    }

    if (key === "currentPage") {
      const val = parseInt(value, 10);
      if (!isNaN(val)) {
        return { ...acc, [key]: val };
      }
    }

    if (key === "pageSize") {
      const val = parseInt(value, 10);
      if (!isNaN(val)) {
        return { ...acc, [key]: val };
      }
    }

    if (key === "searchAppliances") {
      let val;
      if (value === "true") {
        val = true;
      } else if (value === "false") {
        val = false;
      }
      if (typeof val === "boolean") {
        return { ...acc, [key]: val };
      }
    }

    return { ...acc, [key]: value };
  }, {});
}

export function maskInput(input, type) {
  if (type === "CustomerPhone") {
    const value = input.replace(/\D/g, "");
    const new_number = PhoneNumberFormatter(value);
    return new_number.number;
  }

  return input;
}

export function sanitizeInput(input, type) {
  if (type === "CustomerPhone") {
    const value = input.replace(/\D/g, "");
    return value;
  } else if (typeof input === "string") {
    return input.trim();
  }
  return input;
}

//PUT THIS IN A HELPER FILE
export function inputValidation(values) {
  const { input, optionalInput, type, date } = values || {};
  const searchTypeInput = type;
  if (type === "Search by...") {
    return false;
  }
  let validSearch = false;

  switch (type) {
    case "MSNumber":
      validSearch = isMsnValid(input);
      break;
    case "OrderNumber":
      validSearch = isOrderNumberValid(input);
      break;
    case "CustomerName":
      validSearch = isCustomerNameValid(input, optionalInput);
      break;
    case "PONumber":
      validSearch = isPurchaseOrderValid(input, optionalInput);
      break;
    case "CustomerPhone":
      validSearch = isPhoneNumberValid(input);
      break;
    case "WONumber":
      validSearch = sthWorkOrderInputValidation(input);
      break;
    case "DeliveryDate":
      // should this pare the date for proper format?
      validSearch = !!date;
      break;
    default:
      validSearch = false;
  }

  return validSearch;
}

export const FILTER_KEYS = [
  "deliveryDate",
  "deliveryWindow",
  "workOrderStatus",
  "isAppliance",
  "deliveryLocation",
  "promiseDate",
  "workOrder",
  "orderType",
];

export const pluckFilters = (keys, obj) => {
  return Object.keys(obj)
    .filter((key) => {
      return keys.includes(key);
    })
    .reduce((acc, cur) => {
      const values = decodeURIComponent(obj[cur]).split(",");
      return {
        ...acc,
        [cur]: values,
      };
    }, {});
};

export function isRetry(persistedValues, values) {
  if (typeof values.optionalInput === "string") {
    // the persisted value if not set in url will be undefined, not ""
    // so fallback to "" to compare undefined to ""
    const persistedOptionalInput = persistedValues.optionalInput || "";
    if (
      persistedOptionalInput === values.optionalInput &&
      persistedValues.input === values.input
    ) {
      return true;
    }
    return false;
  }
  if (persistedValues.input === values.input) {
    return true;
  }

  return false;
}
