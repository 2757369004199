import React, { useEffect, useContext, useState } from "react";
import "./Mail.less";
import {
  CloseMailMessageProvider,
  CreateMessageProvider,
  MailContext,
  UserContext,
} from "../../context/RootContext";
import { MailDetails } from "./MailDetails/MailDetails";
import { CreateMail } from "./CreateMail/CreateMail";
import { SearchHistory } from "./SearchHistory/SearchHistory";
import moment from "moment";
import { Button, Divider, Row, Col, Table } from "antd";
import { CellContent } from "./CellContent";
import { SearchOutlined } from "@ant-design/icons";

const Mail = () => {
  const { user } = useContext(UserContext);
  const { ldapId } = user;
  const { mailState, getMail } = useContext(MailContext);
  const { mail, loading, error } = mailState;
  const [newMailOpened, setNewMail] = useState(false);
  const [searchOpened, setSearchOpened] = useState(false);

  useEffect(() => {
    if (mail === null && !loading && !error) {
      getMail(ldapId);
    }
  });

  const tableHeaders = {
    mailPriorityDesc: {
      hdr: "Priority",
    },
    mailCategoryDesc: {
      hdr: "Category",
    },
    mailSubjDesc: {
      hdr: "Subject",
    },
    mailMsgReceived: {
      hdr: "Received",
    },
    mailMsgRead: {
      hdr: "Read",
    },
  };
  const columns = Object.keys(tableHeaders).map((header, i) => ({
    title: tableHeaders[header].hdr,
    dataIndex: header,
    render:
      header === "mailSubjDesc"
        ? (text, record) => <CellContent text={text} record={record} />
        : header === "mailMsgReceived"
        ? (text, record) => (
            <>
              {record.mailMboxFromName}
              <br />
              {moment(record.mailMsgReceived)
                .locale("en")
                .format("MM/DD/YY hh:mm A")}
            </>
          )
        : null,
    sorter: (a, b) => {
      const aa = a[header] && a[header].toUpperCase();
      const bb = b[header] && b[header].toUpperCase();
      if (aa > bb) {
        return 1;
      }
      if (aa < bb) {
        return -1;
      }
      return 0;
    },
  }));
  //
  return (
    <div id={"mail-container"}>
      <CloseMailMessageProvider>
        <CreateMessageProvider>
          <Row>
            <Col
              xl={{ span: 16 }}
              lg={{ span: 16 }}
              md={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <div id={"mail-left"}>
                {!searchOpened && (
                  <Row justify="center">
                    <div style={{ marginTop: "10px" }}>
                      <Button
                        onClick={() => {
                          setSearchOpened(true);
                        }}
                        type="link"
                      >
                        <Row align="middle">
                          <SearchOutlined
                            style={{ fontSize: "30px" }}
                            id="historySearch"
                          />
                          <div
                            style={{ fontSize: "16px", paddingLeft: "10px" }}
                          >
                            History Search
                          </div>
                        </Row>
                      </Button>
                    </div>
                    <Divider
                      type="horizontal"
                      style={{
                        borderTop: "5px solid #d9d9d9",
                        margin: "10px 0",
                      }}
                    />
                  </Row>
                )}
                {searchOpened && (
                  <SearchHistory
                    closer={() => {
                      setSearchOpened(false);
                    }}
                  />
                )}
                {mail && (
                  <div className="mail-table-wrapper">
                    <div className="mail-table-content">
                      <Table
                        bordered
                        columns={columns}
                        dataSource={mail.map((i, j) => ({ ...i, key: j }))}
                        pagination={false}
                        scroll={{ y: 760 }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </Col>
            <Col
              xl={{ span: 8 }}
              lg={{ span: 8 }}
              md={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <div id={"mail-right"}>
                {!newMailOpened && (
                  <Row justify="center">
                    <div>
                      <Button
                        onClick={() => {
                          setNewMail(true);
                        }}
                        type="link"
                      >
                        <Row align="middle">
                          <img
                            src="/images/addNote.svg"
                            alt={"new mail"}
                            id="newMailIcon"
                          />
                          <div
                            style={{ fontSize: "16px", paddingLeft: "10px" }}
                          >
                            New message
                          </div>
                        </Row>
                      </Button>
                    </div>
                    <Divider
                      type="horizontal"
                      style={{
                        borderTop: "5px solid #d9d9d9",
                        margin: "0px 0px",
                      }}
                    />
                  </Row>
                )}
                {newMailOpened && (
                  <CreateMail
                    closer={() => {
                      setNewMail(false);
                    }}
                  />
                )}
                <MailDetails />
              </div>
            </Col>
          </Row>
        </CreateMessageProvider>
      </CloseMailMessageProvider>
    </div>
  );
};

export default Mail;
