import React, { useContext } from "react";
import Confirmation from "../../../Confirmation/Confirmation";
import axios from "axios";
import getBffUrl from "../../../../util/getBffUrl";
import { message } from "antd";
import {
  UserContext,
  GlobalLoaderContext,
} from "../../../../context/RootContext";
import OrderContext from "../../../../context/OrderContext";

export const CancelComRevisit = ({
  workOrderNumber,
  orderIndex,
  customerOrderNumber,
  deliveryLocation,
  cancelledLines,
  overlayType,
}) => {
  const { user } = useContext(UserContext);
  const { updateOrder } = useContext(OrderContext);
  const { setGlobalLoading } = useContext(GlobalLoaderContext);

  function cancelWorkOrder() {
    const payload = buildCancelWorkOrderPayload();
    setGlobalLoading(true);

    axios
      .post(
        `${getBffUrl()}/deliveryOrder/v1/cancelWorkOrder/${workOrderNumber}`,
        payload
      )
      .then(
        (response) =>
          new Promise((resolve) => setTimeout(() => resolve(response), 1500))
      )
      .then(() => {
        message.success("This order has been cancelled");
        updateOrder();
      })
      .catch((error) => {
        message.error(
          "Something went wrong and this order has not been cancelled."
        );
        console.error("Error calling cancelManualOrder", error);
      })
      .finally(() => {
        setGlobalLoading(false);
      });
  }

  function buildCancelWorkOrderPayload() {
    return {
      customerOrderNumber: customerOrderNumber,
      cancelledBy: user.ldapId,
      deliveryLocation: deliveryLocation,
      notes: {
        text: 'DMP status changed to " Cancelled ".',
      },
      cancelledLines: cancelledLines,
    };
  }

  return (
    <div id="cancel-revisit-button">
      <Confirmation
        orderCancellation={cancelWorkOrder}
        orderNumber={customerOrderNumber}
        orderIndex={orderIndex}
        overlayType={overlayType}
      />
    </div>
  );
};
