import "./PrintManifestView.less";
import React, { useEffect } from "react";
import ManifestHeader from "./common/ManifestHeader";
import ManifestStop from "./common/ManifestStop";
import useRoutePlannerManifestData from "../../hooks/RoutePlanner/useRoutePlannerManifestData";
import { Spin, Button, Tooltip } from "antd";
import { PrinterOutlined, CopyOutlined } from "@ant-design/icons";
import useNotification from "../../hooks/useNotification";
import CheckmarkTooltipButton from "./common/CheckmarkTooltipButton";

const PrintManifestView = ({ disableCsvButton }) => {
  const { data, error, loading } = useRoutePlannerManifestData();

  const { openErrorNotification, contextHolder } = useNotification();

  useEffect(() => {
    if (error) {
      openErrorNotification({
        message: "Error Retrieving Data",
        description:
          "We're Sorry! There is no data available at this time. Please try again later.",
      });
    }
  }, [error]);

  function copyButtonHandler(manifestData) {
    try {
      buildManifestCsv(manifestData);
    } catch (error) {
      console.error(error);
      openErrorNotification({
        message: "Unable to copy manifest data",
        description:
          "Sorry, we were unable to get and copy the data to your clipboard; please try again later.",
      });
    }
  }

  return error ? (
    <div className="route-manifest-nodata-container">
      {contextHolder}
      <span>No Manifest Data</span>
    </div>
  ) : loading ? (
    <div className="rp-details-spinner" data-testid="rp-details-spinner">
      <Spin size="large" />
    </div>
  ) : (
    <>
      <div className="manifest-buttons-box">
        <Tooltip
          placement="bottom"
          showArrow={false}
          mouseEnterDelay={0.75}
          title="Print"
        >
          <Button
            type="primary"
            aria-label="print-button"
            icon={<PrinterOutlined />}
            onClick={() => {
              print(); // Using anonymous function wrapper here to facilitate jest mocking
            }}
          />
        </Tooltip>

        <CheckmarkTooltipButton
          aria-label="copy-button"
          icon={<CopyOutlined />}
          onClick={() => {
            copyButtonHandler(data);
          }}
          disabled={disableCsvButton}
          tooltip={{
            placement: "bottom",
            showArrow: false,
            mouseEnterDelay: 0.75,
            title: disableCsvButton
              ? "Copying manifest to clipboard not allowed for route details manifest"
              : "Copy this manifest data to your clipboard to use as input for the Revisit and Parts tool.",
          }}
        />
      </div>
      <div id="print-manifest">
        {data.routeManifest.map((route, index) => (
          <div key={index} className="route-manifest-container">
            <ManifestHeader route={route} />

            {route.stops.map((stop, index) => (
              <div key={index} id="manifest-stop">
                <ManifestStop stop={stop} />
              </div>
            ))}

            <div className="print-page-break" />
          </div>
        ))}
      </div>
    </>
  );
};

export default PrintManifestView;

/**
 *
 * @param timeString in the form "HH:MM:SS.s"
 * @returns string in the form "HH:MM"
 */
export function formatTime(timeString) {
  const [hours, minutes] = timeString.split(":");
  return `${hours}:${minutes}`;
}

export function buildLineitemCsv(route, stop, manifestLineitem) {
  const quantity = String(manifestLineitem.quantity)
    ? manifestLineitem.quantity
    : "";
  const sku = manifestLineitem.SKU ? manifestLineitem.SKU : "";
  const description = manifestLineitem.description
    ? manifestLineitem.description
    : "";

  return [
    route.routeDate,
    route.driverName,
    route.routeId,
    stop.stopNumber,
    stop.serviceType,
    stop.customerOrderNumber,
    stop.customerName,
    stop.msn,
    stop.earliestTime,
    stop.latestTime,
    stop.pallets,
    quantity,
    sku,
    description,
  ].join("|");
}

export function buildStopCsv(route, manifestStop) {
  const stop = {
    stopNumber: String(manifestStop.stopNumber) ? manifestStop.stopNumber : "",
    serviceType: manifestStop.order.serviceType
      ? manifestStop.order.serviceType
      : "",
    customerOrderNumber: manifestStop.order.customerOrderNumber
      ? manifestStop.order.customerOrderNumber
      : "",
    customerName: manifestStop.clientName ? manifestStop.clientName : "",
    msn: manifestStop.order.MSN ? manifestStop.order.MSN : "",
    earliestTime: manifestStop.order.earliestTime
      ? formatTime(manifestStop.order.earliestTime.split(" ")[1])
      : "",
    latestTime: manifestStop.order.latestTime
      ? formatTime(manifestStop.order.latestTime.split(" ")[1])
      : "",
    pallets: String(manifestStop.pallets) ? manifestStop.pallets : "",
  };
  return manifestStop.lineItem.map((manifestLineitem) => {
    return buildLineitemCsv(route, stop, manifestLineitem);
  });
}

export function buildRouteCsv(manifestRoute) {
  const route = {
    routeDate: manifestRoute.earliestStartDate
      ? manifestRoute.earliestStartDate.split(" ")[0]
      : "",
    driverName: manifestRoute.driverName ? manifestRoute.driverName : "",
    routeId: manifestRoute.routeID ? manifestRoute.routeID : "",
  };

  return manifestRoute.stops
    .map((manifestStop) => {
      return buildStopCsv(route, manifestStop);
    })
    .flat();
}

//Build the CSV string from the manifest JSON with the following pattern:
/*
  route01, stop01, lineItem01
  route01, stop01, lineItem02 (etc.)
  route01, stop02, lineItem01 (etc.)
  route02, stop01, lineItem01 (etc.)
  */
export function buildManifestCsv(manifestData) {
  const manifestCSV = "DMP_MFST\n"; //header line
  const routesArray = manifestData.routeManifest
    .map((manifestRoute) => {
      return buildRouteCsv(manifestRoute);
    })
    .flat();
  navigator.clipboard.writeText(manifestCSV.concat(routesArray.join("\n")));
}
