import React from "react";

const LocationContext = React.createContext({
  currentLocation: {
    locationName: "",
    locationNumber: "",
  },
  // eslint-disable-next-line no-empty-function
  setCurrentLocation: () => {},
});
export default LocationContext;
