import React from "react";
import createManualOrderReducer from "../context/reducers/manualOrderReducer";
import createManualOrderInitialState from "../context/initialStates/createMessageInitialState";
import {
  createManualOrderAction,
  cleanCreateManualOrderAction,
} from "../context/actions/manualOrderActions";

export default function CreateManualOrderHook() {
  const [createManualOrderState, createManualOrderDispatch] = React.useReducer(
    createManualOrderReducer,
    createManualOrderInitialState
  );
  const createManualOrder = createManualOrderAction(createManualOrderDispatch);
  const cleanCreateManualOrder = cleanCreateManualOrderAction(
    createManualOrderDispatch
  );
  return { createManualOrderState, createManualOrder, cleanCreateManualOrder };
}
