import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import "./Deliveries.less";
import DriverDeliveries from "./DriverDeliveries/DriverDeliveries";
import LocationContext from "../../context/LocationContext";
import Search from "../Search/Search";
import SearchResults from "./SearchResults/SearchResults";
import Loading from "../Loading/Loading";
import { UserContext } from "../../context/RootContext";
import permit from "../../util/Permissions";
import getBffUrl from "../../util/getBffUrl";
import useNewRelicMetrics from "../../hooks/newRelicMetricsHook";
import { SEARCH_RESULTS_EVENT } from "../../util/NewRelicConstants";
import { useSearchStateHook } from "../../hooks/useSearchStateHook";
import { sanitizer, inputValidation } from "../Search/search.helper";
import SearchContext from "../../context/SearchContext";
import { GlobalLoaderContext } from "../../context/RootContext";
const Deliveries = () => {
  const [deliveries, setDeliveries] = useState([]);
  const { currentLocation } = useContext(LocationContext);
  const { user } = useContext(UserContext);
  const { logNewRelicMetricsEvent } = useNewRelicMetrics();

  const { loading, setLoading } = useContext(SearchContext);
  const { globalLoading } = useContext(GlobalLoaderContext);

  const { persistedValues, values, reset } = useSearchStateHook(
    {
      type: "",
      input: "",
      optionalInput: "",
    },
    { sanitizer }
  );

  useEffect(() => {
    const isValid = inputValidation(persistedValues);
    if (currentLocation && isValid) {
      executeDeliveriesCall(persistedValues);
    } else {
      setDeliveries([]);
    }
  }, [JSON.stringify(persistedValues), currentLocation]);

  const executeDeliveriesCall = async (persistedValues) => {
    const start = performance.now();
    const responseData = await getDeliveries(persistedValues);
    const duration = performance.now() - start;
    const formattedDuration = duration ? Number(duration.toFixed(2)) : 0;
    const totalCount = !responseData.error ? responseData.data.total : 0;

    logNewRelicMetricsEvent(SEARCH_RESULTS_EVENT, {
      count: totalCount,
      responseTime: formattedDuration,
      searchTerm: persistedValues.input,
      searchGroup: persistedValues.type,
    });
  };

  function getParams({ type, input, optionalInput, date }) {
    const params = {};

    if (input && type) {
      if (type === "CustomerName") {
        params["CustomerLastName"] = input;
        if (optionalInput && optionalInput.length !== 0) {
          params["CustomerFirstName"] = optionalInput;
        }
      } else if (type === "PONumber") {
        params["PONumber"] = input;
        if (optionalInput && optionalInput.length !== 0) {
          params["StoreNumber"] = optionalInput;
        }
      } else {
        params[type] = input;
      }
    } else if (date) {
      params.DeliveryDate = date;
    } else {
      //KEEPING FOR NOW TO PREVENT POTENTIAL ERRORS. MIGHT BE REMOVED LATER
      params.DeliveryDate = moment().format("MM/DD/YYYY");
    }
    if (
      currentLocation &&
      currentLocation.locationNumber &&
      type !== "OrderNumber" &&
      type !== "MSNumber" &&
      (permit("locationBasedSearch", user) || type === "DeliveryDate")
    ) {
      params.LocationNumber = currentLocation.locationNumber;
    }

    return params;
  }

  async function getDeliveries(persistedValues) {
    setLoading(true);
    return await axios
      .get(`${getBffUrl()}/dashboard/v1/driversDeliveries`, {
        params: getParams(persistedValues),
      })
      .then((response) => {
        if (response && response.status === 200) {
          setDeliveries(addOrderIndex(response.data));
        } else {
          console.log("Error accessing the dashboard data");
          setDeliveries(null);
        }

        const responseData = response && response.data ? response.data : [];
        return {
          data: {
            deliveries: [responseData],
            total: calculateNumOfResults(responseData),
          },
        };
      })
      .catch((error) => {
        console.log(error);
        setDeliveries(null);
        return {
          error,
          data: null,
        };
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  function addOrderIndex(deliveries) {
    let count = 0;
    return deliveries.map((obj) => ({
      ...obj,
      unassignedOrders: obj.unassignedOrders.map((order) => ({
        ...order,
        orderIndex: count++,
      })),
    }));
  }

  function getSearchResults() {
    if (deliveries === null) {
      return (
        <div id="zero-results">
          Something went wrong, please try your search again
        </div>
      );
    } else if (
      deliveries.length === 0 &&
      values.type &&
      (values.input || values.date)
    ) {
      return <div id="zero-results">There seems to be nothing here</div>;
    } else if (
      deliveries.length === 0 &&
      !values.type &&
      isEmpty(persistedValues)
    ) {
      return (
        <div id="zero-results">
          Please use the search or date filter above to begin
        </div>
      );
    } else {
      if (
        persistedValues &&
        persistedValues.input &&
        persistedValues.type &&
        !persistedValues.date
      ) {
        const numOfResults = calculateNumOfResults(deliveries);
        const resultString = `${numOfResults} ${
          numOfResults === 0 || numOfResults > 1 ? "results" : "result"
        } found`;
        return (
          <div>
            <div id="results-count">{resultString}</div>
            {deliveries &&
              deliveries.map((driver, driverIndex) => (
                <div key={driverIndex}>
                  <SearchResults deliveries={driver} />
                </div>
              ))}
          </div>
        );
      } else {
        return (
          <div>
            {deliveries &&
              deliveries
                .sort((a, b) => (a.truckNumber > b.truckNumber ? 1 : -1))
                .map((driver, driverIndex) => (
                  <div key={driverIndex}>
                    <DriverDeliveries driver={driver} />
                  </div>
                ))}
          </div>
        );
      }
    }
  }

  function calculateNumOfResults(arr) {
    return arr.reduce((length, delivery) => {
      if (delivery.unassignedOrders) {
        return (length += delivery.unassignedOrders.length);
      }
      return length; //Reduce breaks if no values are returned in the case delivery.unassignedOrders is nullish
    }, 0);
  }

  return (
    <div id={"deliveries"}>
      {(loading || globalLoading) && (
        <div
          onClick={() => {
            if (loading) {
              setLoading(false);
              reset();
            }
          }}
          data-testid="loading-overlay"
          className="loading-overlay"
        >
          <Loading />
        </div>
      )}
      <div>
        <Search />
        <div>{!loading && getSearchResults()}</div>
      </div>
    </div>
  );
};

export default Deliveries;
