import permit, { checkAdditionalRoleAccessForUser } from "../util/Permissions";
import { userRole } from "../util/Roles";
import * as constants from "../util/Constants";

export const dcaParams = (user, currentLocation) => {
  if (userRole(user) === constants.ROLE_ADMS_VENDORS) {
    return `/vendor/?vendor_number=${user.mvendorNum}`;
  } else if (userRole(user) === constants.ROLE_ADMS_DELIVERY_AGENTS) {
    return `/da/?vendor_number=${user.mvendorNum}&location=${currentLocation.locationNumber}`;
  } else if (
    userRole(user) === constants.ROLE_EMT_CORP ||
    userRole(user) === "EMT_N" ||
    userRole(user) === constants.ROLE_ADMS_MDO_USERS
  ) {
    return `/mdo?location=${currentLocation.locationNumber}`;
  } else if (
    userRole(user) === constants.ROLE_ADMS_LOGISTICS_MANAGERS ||
    userRole(user) === constants.ROLE_ADMS_LOGISTICS_USERS
  ) {
    return `/deliveryagent`;
  } else {
    return "";
  }
};

export const shouldAllowDashboardCommon = (user, currentLocation) => {
  if (permit("dashboardCommonAccess", user)) {
    if (process.env.REACT_APP_DMP_DASHBOARD_ALWAYS_SHOW === "Y") {
      return true; // LLC only
    } else if (
      checkAdditionalRoleAccessForUser(
        constants.DMP_DASHBOARD_COMMON_ACCESS_GROUP,
        user
      ) ||
      (process.env.REACT_APP_DMP_DASHBOARD_COMMON_LOCATIONS &&
        process.env.REACT_APP_DMP_DASHBOARD_COMMON_LOCATIONS.split(
          ","
        ).includes(currentLocation.locationNumber.toString()))
    ) {
      return true;
    }
  }
  return false;
};

export const shouldAllowDashboardSth = (user, currentLocation) => {
  if (permit("dashboardSthAccess", user)) {
    if (process.env.REACT_APP_STH_DASHBOARD_ALWAYS_SHOW === "Y") {
      return true;
    } else if (
      checkAdditionalRoleAccessForUser(
        constants.DMP_DASHBOARD_STH_ACCESS_GROUP,
        user
      ) ||
      process.env.REACT_APP_DMP_DASHBOARD_STH_LOCATIONS.split(",").includes(
        currentLocation.locationNumber.toString()
      )
    ) {
      return true;
    }
  }
  return false;
};

export const shouldAllowRoutePlannerView = (user, currentLocation) => {
  if (permit("routePlanner", user)) {
    if (process.env.REACT_APP_RP_VIEW_ALWAYS_SHOW === "Y") {
      return true; // LLC only
    } else if (
      checkAdditionalRoleAccessForUser(
        constants.DMP_DASHBOARD_COMMON_ACCESS_GROUP,
        user
      ) ||
      (process.env.REACT_APP_DMP_VIEW_RP_LOCATIONS &&
        process.env.REACT_APP_DMP_VIEW_RP_LOCATIONS.split(",").includes(
          currentLocation.locationNumber.toString()
        ))
    ) {
      return true;
    }
  }
  return false;
};

export const shouldAllowWorkbin = (user) => {
  return (
    permit("workbinAccess", user) &&
    checkAdditionalRoleAccessForUser(constants.DMP_WORKBIN_ACCESS_GROUP, user)
  );
};
