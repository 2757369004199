import React, { useContext, useEffect, useState } from "react";
import "./Notes.less";
import { Input, Button, Modal, message, Tabs } from "antd";
import { SwapOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import Note from "./Note/Note";
import * as constants from "../../util/Constants";
import axios from "axios";
import { UserContext } from "../../context/RootContext";
import permit from "../../util/Permissions";
import getBffUrl from "../../util/getBffUrl";
import { Spin } from "antd";
import OrderContext from "../../context/OrderContext";
import useNewRelicMetrics from "../../hooks/newRelicMetricsHook";
import {
  ADD_NOTE_EVENT,
  ADD_NOTE_CANCEL_EVENT,
  SUBMIT_ADD_NOTE_EVENT,
  VIEW_ALL_NOTE_EVENT,
  VIEW_ALL_NOTE_TAB_EVENT,
} from "../../util/NewRelicConstants";

function formatTimeStamp(createTimeStamp) {
  //SHOWING IN EASTERN TIME
  return moment
    .tz(new Date(createTimeStamp), "America/New_York")
    .locale("en")
    .format("MM/DD/YY h:mm A");
}

const renderModalNotes = (notes, defaultNoteLevel) => {
  return notes.map((note, noteIndex) => (
    <Note
      key={noteIndex}
      timestamp={formatTimeStamp(note.createTimeStamp || note.createdOn)}
      user={note.lastUpdUserId || note.createdBy}
      noteText={note.deliveryNoteText || note.text}
      label={note.label || defaultNoteLevel}
      modelNumber={note.modelNumber}
      workOrderNumber={note.workOrderNumber}
    />
  ));
};

const NotesModal = (props) => {
  const {
    customerName,
    deliveryDate,
    showModal,
    onCancelHandler,
    deliveryNotes,
    orderNotes,
    sortDirection,
    reverseSortButtonHandler,
    defaultNoteLevel,
    handleViewAllNotesClick,
  } = props;

  return (
    <Modal
      className="notes-modal"
      data-testid="notes-modal"
      centered
      title={`Notes for ${customerName.firstName} ${
        customerName.lastName
      }'s delivery, scheduled for ${
        deliveryDate
          ? moment(new Date(deliveryDate.replace(/-/g, "/"))).format("MM/DD/YY")
          : ""
      }`}
      open={showModal}
      footer={null}
      onCancel={() => onCancelHandler()}
    >
      <div slot="body">
        <div className="button-row">
          <Button
            className="sort-button"
            type="primary"
            size="small"
            icon={<SwapOutlined rotate="90" />}
            onClick={() => {
              reverseSortButtonHandler();
            }}
          >
            Sort by: {sortDirection}
          </Button>
        </div>
        {
          <Tabs
            items={initializeTabItems(
              deliveryNotes,
              orderNotes,
              defaultNoteLevel,
              handleViewAllNotesClick
            )}
          />
        }
      </div>
    </Modal>
  );
};

const NotesBody = (props) => {
  const { deliveryNotes, sortDirection, defaultNoteLevel } = props;
  return (
    <div className={"notes-body"}>
      {deliveryNotes &&
        sortDirection &&
        deliveryNotes
          .slice(0, 3)
          .map((note, noteIndex) => (
            <Note
              key={noteIndex}
              timestamp={formatTimeStamp(
                note.createTimeStamp || note.createdOn
              )}
              user={note.lastUpdUserId || note.createdBy}
              noteText={note.deliveryNoteText || note.text}
              label={note.label || defaultNoteLevel}
              modelNumber={note.modelNumber}
              workOrderNumber={note.workOrderNumber}
            />
          ))}
    </div>
  );
};

const initializeTabItems = (
  deliveryNotes,
  orderNotes,
  defaultNoteLevel,
  handleViewAllNotesClick
) => {
  const tabItems = [
    {
      key: "item-1",
      label: `Delivery Notes`,
      children:
        deliveryNotes && renderModalNotes(deliveryNotes, defaultNoteLevel),
    },
  ];

  if (orderNotes.length > deliveryNotes.length) {
    tabItems.push({
      key: "item-2",
      label: (
        <div onClick={handleViewAllNotesClick} style={{ cursor: "pointer" }}>
          View All Notes
        </div>
      ),
      children: orderNotes && renderModalNotes(orderNotes, defaultNoteLevel),
    });
  }
  return tabItems;
};

const NotesAddNote = (props) => {
  const {
    textAreaHandler,
    addNotesText,
    isLoading,
    submitButtonHandler,
    cancelButtonHandler,
  } = props;
  return (
    <div slot="header" className="add-note-header">
      <span className="add-note-text">Add Note</span>
      <div>
        <Input.TextArea
          onChange={(e) => textAreaHandler(e.target.value)}
          maxLength={245}
          rows={4}
        />
      </div>
      <div className="note-footer">
        <span className={"character-remaining-label"}>
          {245 - addNotesText.length} characters remaining
        </span>
        <Button
          id="submit-button"
          disabled={!addNotesText.length > 0 || isLoading === true}
          width="40%"
          type="secondary"
          size="big"
          onClick={() => {
            submitButtonHandler();
          }}
        >
          Submit
        </Button>
        <span
          id="cancel-button"
          className="cancel"
          onClick={() => cancelButtonHandler()}
        >
          Cancel
        </span>
      </div>

      {isLoading && (
        <div className="notes-spinner">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

const NotesHeader = (props) => {
  const {
    allNoteStatus,
    deliveryNotes,
    user,
    viewAllNotesButtonHandler,
    addNoteButtonHandler,
    viewAll,
  } = props;
  return (
    <div className={"notes-header"} data-testid="notes-header">
      {allNoteStatus === constants.DELIVERY_STATUS.success && (
        <div>
          <span>Notes ({deliveryNotes.length})</span>
          {viewAll && (
            <span
              className="view-all"
              onClick={() => {
                viewAllNotesButtonHandler();
              }}
            >
              View all
            </span>
          )}
          {permit("addNote", user) && (
            <img
              className="add-note"
              src="/images/addNote.svg"
              alt={"add-note-button"}
              align={"right"}
              onClick={() => {
                addNoteButtonHandler();
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

const NotesError = (props) => {
  const { isLoading, onClickHandler } = props;
  return (
    <div className="error-container">
      {isLoading === false ? (
        <h3 className="retry-text">
          Notes could not be retrieved, please try again
        </h3>
      ) : (
        //TODO: extract Spinner into shared component
        <div className={"error-spinner"}>
          <Spin size="large" />
        </div>
      )}
      <div className="button-container">
        <Button
          className="retry-button"
          type="primary"
          onClick={() => {
            onClickHandler();
          }}
        >
          Retry
        </Button>
      </div>
    </div>
  );
};

const NotesViewAdd = (props) => {
  const { isLoading, onClickHandler } = props;
  return (
    <div>
      <div>
        <span
          className={`view-add-notes ${isLoading ? "grayout" : ""}`}
          onClick={() => {
            if (isLoading) {
              return;
            }
            onClickHandler();
          }}
        >
          View/Add Notes
        </span>
        <span className="display-instruction">
          To display Notes please click on View/Add Notes
        </span>
      </div>
      {isLoading && (
        //TODO: extract Spinner into shared component
        <div className="notes-spinner">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

const SortDirection = {
  latest: "latest",
  earliest: "earliest",
};

const Notes = (props) => {
  const {
    notes,
    customerName,
    deliveryDate,
    referenceId,
    orderNumber,
    deliveryNoteStatus,
    sortBy,
    msNbr,
    defaultNoteLevel,
    isComRevisit,
    lineNumbers,
    view,
    orderIndex,
  } = props;
  const { logNewRelicMetricsEvent } = useNewRelicMetrics();
  const [showModal, setShowModal] = useState(false);
  const [showNotesSection, setShowNotesSection] = useState(true);
  const [addNotesText, setNotesText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useContext(UserContext);
  const {
    isCustomerInfoUpdated,
    setCustomerInfoIsUpdated,
    setUpdateCustomerInfoIsLoading,
    openCustomerInfoModal,
  } = useContext(OrderContext);
  const { ldapId, companyName } = user;
  const [sortDirection, setSortDirection] = useState(SortDirection.latest);
  const [orderNotes, setOrderNotes] = useState(notes);
  const [allNoteStatus, setAllNoteStatus] = useState(deliveryNoteStatus);
  const [filteredOrderNotes, setFilteredOrderNotes] = useState([]);

  function sortNotes() {
    const sortedNotes = orderNotes;
    if (allNoteStatus === constants.DELIVERY_STATUS.fail) {
      return (
        <NotesError isLoading={isLoading} onClickHandler={retrieveNotes} />
      );
    }

    if (sortDirection === SortDirection.latest) {
      sortedNotes.sort((A, B) => {
        //return negative if A is bigger (A comes first)
        return new Date(B[sortBy]) - new Date(A[sortBy]);
      });
    } else {
      sortedNotes.sort((A, B) => {
        //return negative if B is bigger (B comes first)
        return new Date(A[sortBy]) - new Date(B[sortBy]);
      });
    }

    /* NOTE: we use spread operator because there's a React quirk where
     *  setting a state with the same array does not trigger a component
     *  rerender, but a "new" array made with a spread operator does.
     *  SO reference: https://stackoverflow.com/a/56266640
     */
    setOrderNotes([...sortedNotes]);
    return sortedNotes;
  }

  const payload = {
    lastUpdUserId: ldapId,
    orderReference: orderNumber,
    deliveryNoteText: addNotesText,
  };

  function formatDeliveryNoteObjectForUI(deliveryNote) {
    deliveryNote.deliveryNoteText = `${companyName} - ${deliveryNote.deliveryNoteText}`;
  }

  useEffect(() => {
    const sortedNotes = sortNotes();
    filterNotes(sortedNotes);
  }, [sortDirection]);

  function filterNotes(notes) {
    const newFilteredNotes = [];
    if (allNoteStatus !== constants.DELIVERY_STATUS.fail) {
      notes.map((note) => {
        if (
          view === constants.DASHBOARD_CONTEXT &&
          referenceId === note.referenceId
        ) {
          newFilteredNotes.push(note);
        }
        if (
          // if Revisit order, then only add delivery specific delivery notes
          (view === constants.DASHBOARD_COMMON_CONTEXT &&
            isComRevisit &&
            note.label &&
            note.label === constants.NOTES_LABELS.delivery &&
            lineNumbers &&
            lineNumbers.includes(note.lineNumber)) ||
          // if Original order, add all notes including order notes and other delivery specific notes
          (view === constants.DASHBOARD_COMMON_CONTEXT &&
            !isComRevisit &&
            ((note.label && note.label === constants.NOTES_LABELS.order) ||
              (lineNumbers && lineNumbers.includes(note.lineNumber))))
        ) {
          newFilteredNotes.push(note);
        }
      });
    }
    setFilteredOrderNotes([...newFilteredNotes]);
  }

  function retrieveNotes(customerInfoUpdate) {
    if (referenceId === null) {
      setAllNoteStatus(constants.DELIVERY_STATUS.fail);
      return;
    }
    if (!customerInfoUpdate) {
      setIsLoading(true);
    }
    axios
      .get(`${getBffUrl()}/dashboard/v1/driversDeliveries`, {
        params: { DeliveryID: referenceId },
      })
      .then((response) => {
        const notesData = response.data;
        let delivery;
        notesData.forEach((noteData) => {
          noteData.unassignedOrders.forEach((unassignedOrder) => {
            if (unassignedOrder.referenceId === referenceId) {
              delivery = unassignedOrder;
            }
          });
        });
        if (delivery.deliveryNoteStatus === constants.DELIVERY_STATUS.success) {
          if (customerInfoUpdate) {
            message.success("Customer information updated!");
          }
          setAllNoteStatus(constants.DELIVERY_STATUS.success);
          setOrderNotes(delivery.deliveryNoteList);
          filterNotes(delivery.deliveryNoteList);
        } else if (
          delivery.deliveryNoteStatus === constants.DELIVERY_STATUS.fail
        ) {
          setAllNoteStatus(constants.DELIVERY_STATUS.fail);
        }
      })
      .catch((err) => {
        setAllNoteStatus(constants.DELIVERY_STATUS.fail);
      })
      .finally(() => {
        if (customerInfoUpdate) {
          setUpdateCustomerInfoIsLoading(false);
          openCustomerInfoModal(false);
        } else {
          setIsLoading(false);
        }
      });
  }

  function addNote(note) {
    formatDeliveryNoteObjectForUI(note);
    note.deliveryId = referenceId;
    setIsLoading(true);
    axios
      .post(`${getBffUrl()}/dashboard/v2/deliveryNote`, note)
      .then((resp) => {
        if (resp.status === 200) {
          note.referenceId = referenceId;
          if (view === constants.DASHBOARD_COMMON_CONTEXT) {
            note.label = constants.NOTES_LABELS.order;
          }
          note[sortBy] = new Date(new Date().getTime()).toString();
          if (sortDirection === SortDirection.latest) {
            setOrderNotes([note, ...orderNotes]);
            filterNotes([note, ...orderNotes]);
          } else {
            setOrderNotes([...orderNotes, note]);
            filterNotes([...orderNotes, note]);
          }
          setShowNotesSection(true);
          message.success("Note added successfully");
        }
      })
      .catch((error) => {
        message.error("Could not add note, please try again");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  if (isCustomerInfoUpdated) {
    setCustomerInfoIsUpdated(false);
    retrieveNotes(true);
  }

  /**
   * NotesHeader Component handlers
   */
  function viewAllNotesButtonHandler() {
    logNewRelicMetricsEvent(VIEW_ALL_NOTE_EVENT, {
      order_number: orderNumber,
      index: orderIndex,
    });
    setShowModal(true);
  }

  function addNoteButtonHandler() {
    logNewRelicMetricsEvent(ADD_NOTE_EVENT, {
      order_number: orderNumber,
      index: orderIndex,
    });
    setShowNotesSection(!showNotesSection);
    setNotesText("");
  }

  /**
   * NotesNewNotes Component handlers
   */
  function textAreaHandler(text) {
    setNotesText(text);
  }

  function submitButtonHandler() {
    logNewRelicMetricsEvent(SUBMIT_ADD_NOTE_EVENT, {
      order_number: orderNumber,
      index: orderIndex,
    });
    addNote(payload);
  }

  function cancelButtonHandler() {
    logNewRelicMetricsEvent(ADD_NOTE_CANCEL_EVENT, {
      order_number: orderNumber,
      index: orderIndex,
    });
    setShowNotesSection(!showNotesSection);
  }

  function handleViewAllNotesClick() {
    logNewRelicMetricsEvent(VIEW_ALL_NOTE_TAB_EVENT, {
      order_number: orderNumber,
      index: orderIndex,
    });
  }

  /**
   * NotesModal Component handler
   */
  function onCancelHandler() {
    setShowModal(false);
  }

  function reverseSortButtonHandler() {
    if (sortDirection === SortDirection.latest) {
      setSortDirection(SortDirection.earliest);
    } else {
      setSortDirection(SortDirection.latest);
    }
  }

  function render() {
    if (allNoteStatus === constants.DELIVERY_STATUS.fail) {
      return (
        <NotesError isLoading={isLoading} onClickHandler={retrieveNotes} />
      );
    } else {
      if (allNoteStatus === constants.DELIVERY_STATUS.na) {
        return (
          <NotesViewAdd isLoading={isLoading} onClickHandler={retrieveNotes} />
        );
      } else {
        return (
          <div className="notes-window-wrapper">
            {!showNotesSection && (
              <NotesAddNote
                textAreaHandler={textAreaHandler}
                addNotesText={addNotesText}
                isLoading={isLoading}
                submitButtonHandler={submitButtonHandler}
                cancelButtonHandler={cancelButtonHandler}
              />
            )}
            {showNotesSection && (
              <>
                <NotesHeader
                  allNoteStatus={allNoteStatus}
                  deliveryNotes={filteredOrderNotes}
                  user={user}
                  viewAllNotesButtonHandler={viewAllNotesButtonHandler}
                  addNoteButtonHandler={addNoteButtonHandler}
                  viewAll={
                    filteredOrderNotes.length > 3 ||
                    filteredOrderNotes.length < orderNotes.length
                  }
                />
                <NotesBody
                  deliveryNotes={filteredOrderNotes}
                  sortDirection={sortDirection}
                  defaultNoteLevel={defaultNoteLevel}
                />
              </>
            )}
            <NotesModal
              customerName={customerName}
              deliveryDate={deliveryDate}
              showModal={showModal}
              onCancelHandler={onCancelHandler}
              deliveryNotes={filteredOrderNotes}
              orderNotes={orderNotes}
              sortDirection={sortDirection}
              reverseSortButtonHandler={reverseSortButtonHandler}
              defaultNoteLevel={defaultNoteLevel}
              handleViewAllNotesClick={handleViewAllNotesClick}
            />
          </div>
        );
      }
    }
  }

  return render();
};

export default Notes;

Notes.propTypes = {
  notes: PropTypes.array.isRequired,
  customerFirstName: PropTypes.string,
  customerLastName: PropTypes.string,
  deliveryDate: PropTypes.string,
  referenceId: PropTypes.string,
  deliveryNoteStatus: PropTypes.string.isRequired,
  msNbr: PropTypes.string,
  defaultNoteLevel: PropTypes.string,
  isComRevisit: PropTypes.bool,
};
