import React, { useContext, useState } from "react";
import "../ManualOrder.less";
import "../../DeliveryDetails/DeliveryDetails.less";
import { Button, Row, Col, Spin } from "antd";
import OrderContext from "../../../context/OrderContext";
import Note from "../../Notes/Note/Note";
import moment from "moment";
import WeeklyCalendar from "./WeeklyCalendar";
import { UserContext } from "../../../context/RootContext";

const MOScheduleDate = ({
  orderDetails,
  form,
  selectedVendor,
  setSlide,
  updateSelectDate,
  updateSelectDateIndex,
  updateSelectedDateSlotsCount,
  deliverySlotsState,
  slotsByDate,
  cleanDeliverySlots,
  createManualOrderState,
  revisitType,
  logCancelEvent,
}) => {
  const { toggleManualOrder } = useContext(OrderContext);
  const { user } = useContext(UserContext);
  const { ldapId } = user;
  const [enableSubmit, toggleSubmit] = useState(false);
  const arrayOfModelNbr = form.getFieldValue("lineItems")
    ? form.getFieldValue("lineItems").map((v) => v.split("__")[0])
    : null;

  function formatTimeStamp(createTimeStamp) {
    return moment(createTimeStamp).locale("en").format("MM/DD/YY hh:mm A");
  }
  return (
    <Row>
      <Col
        xl={{ span: 5 }}
        lg={{ span: 24 }}
        md={{ span: 24 }}
        xs={{ span: 24 }}
      >
        <div className="revisit-details-wrapper">
          <div className="revisit-window">
            <div className="manual-order-revisit-details">
              <div className="manual-order-details-header">
                {orderDetails.customerDetail.custFirstName}{" "}
                {orderDetails.customerDetail.custLastName}&#39;s revisit
              </div>
              <div className="manual-order-details-selector">
                <p>Affected items:</p>
                <div className="manual-order-models">
                  {selectedVendor}
                  {arrayOfModelNbr && ":"}
                  <span>
                    {arrayOfModelNbr &&
                      ` ${arrayOfModelNbr.toString().replace(/,/g, ", ")}`}
                  </span>
                </div>
                <p>Reason for revisit:</p>
                <div className="manual-order-revisit">
                  <p>{revisitType}</p>
                </div>
              </div>
            </div>
            <div className="manual-order-notes">
              {
                <Note
                  key={0}
                  timestamp={formatTimeStamp(new Date())}
                  user={ldapId}
                  noteText={form.getFieldValue("notes").replace(/-/g, " ")}
                />
              }
            </div>
          </div>
        </div>
      </Col>
      <Col
        xl={{ span: 15 }}
        lg={{ span: 18 }}
        md={{ span: 24 }}
        xs={{ span: 24 }}
      >
        <div className="mo-calendar-container">
          <div className="calendar-window-wrapper">
            {(deliverySlotsState && deliverySlotsState.loading) ||
            (createManualOrderState && createManualOrderState.loading) ? (
              <div className="spinner">
                <Spin size="large" />
              </div>
            ) : slotsByDate ? (
              <WeeklyCalendar
                slotsByDate={slotsByDate}
                toggleSubmit={toggleSubmit}
                updateSelectDate={updateSelectDate}
                updateSelectDateIndex={updateSelectDateIndex}
                updateSelectedDateSlotsCount={updateSelectedDateSlotsCount}
              />
            ) : null}
          </div>
        </div>
      </Col>
      <Col
        xl={{ span: 4 }}
        lg={{ span: 6 }}
        md={{ span: 24 }}
        xs={{ span: 24 }}
      >
        <div className="actions-container-mo">
          <Button
            type="primary"
            size="large"
            id="MOPrevious"
            onClick={() => {
              setSlide(0);
            }}
          >
            Previous
          </Button>

          <Button
            type="primary"
            size="large"
            id="MOSubmit"
            htmlType="submit"
            disabled={
              !enableSubmit ||
              (createManualOrderState && createManualOrderState.loading)
            }
          >
            Submit
          </Button>

          <div className="cancel-btn">
            <Button
              type="link"
              size="large"
              id="MOCancel"
              onClick={() => {
                toggleManualOrder(false);
                cleanDeliverySlots();
                logCancelEvent();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default MOScheduleDate;
