import React, { useRef, useState } from "react";

const SearchContext = React.createContext({
  values: {},
  filters: {},
});

export default SearchContext;

export const SearchProvider = ({ children }) => {
  const filters = useRef({});

  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);

  const value = {
    setValues,
    values,
    filters,
    loading,
    setLoading,
  };

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};
