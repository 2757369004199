import React from "react";
import "./RoutePlannerTableContent.less";
import { Empty, Table } from "antd";
import { useMatch, useResolvedPath } from "react-router-dom";
import { useTableConfig } from "../../hooks/RoutePlanner/useTableConfig";
import DriverNotesDrawer from "./DriverNotesDrawer";
import { getRPRowClassName } from "../../util/RoutePlanner/rpTableConfigUtils";
import { useUrlData } from "../../hooks/RoutePlanner/useUrlData";

export default function RoutePlannerTableContent({
  tab,
  data,
  error,
  loading,
}) {
  const match = useMatch("/route-planner/*");
  const resolvedPath = useResolvedPath(match.pathname);
  const {
    columns,
    handleTableChange,
    isDrawerOpen,
    setIsDrawerOpen,
    selectedStopId,
    driverNotesOrderNumber,
    driverNotesWorkOrderNumber,
  } = useTableConfig(data, resolvedPath.pathname, "summary");
  const { getAllQueryParams } = useUrlData();

  const params = getAllQueryParams();

  const emptyTextDesc =
    error ||
    (!error &&
      (tab !== "dispatch" ? params && !loading : !loading) &&
      data.length === 0)
      ? `No data`
      : tab === "unassigned"
      ? `Select a date to load ${tab} bin`
      : tab === "archive"
      ? `Please use the search above to begin`
      : tab === "planning"
      ? `Select a date to load ${tab} schedule`
      : `Loading ${tab} schedule`;

  const locale = {
    emptyText: (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        data-testid="empty-table-placeholder"
        description={emptyTextDesc}
      />
    ),
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        rowClassName={getRPRowClassName}
        onChange={handleTableChange}
        pagination={false}
        tableLayout="auto"
        size={"middle"}
        locale={locale}
      />
      {isDrawerOpen && (
        <DriverNotesDrawer
          isDrawerOpen={isDrawerOpen}
          setDrawerOpen={setIsDrawerOpen}
          selectedStopId={selectedStopId}
          driverNotesOrderNumber={driverNotesOrderNumber}
          driverNotesWorkOrderNumber={driverNotesWorkOrderNumber}
        />
      )}
    </div>
  );
}
