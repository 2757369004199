export default {
  loading: false,
  error: false,
  user: {
    firstName: null,
    locationNumber: null,
    roles: null,
    locations: null,
    ldapId: null,
    mvendorNum: null,
    companyName: null,
    userMailAccess: false,
    isFDCUser: false,
  },
};
