import {
  MAIL_REQUEST,
  MAIL_SUCCESS,
  MAIL_FAIL,
  DELETE_ALL_MAIL,
} from "../constants/mailConstants";

const mailReducer = (state, { payload, type }) => {
  switch (type) {
    case MAIL_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case MAIL_SUCCESS:
      return {
        loading: false,
        mail: payload,
        error: false,
      };
    case MAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case DELETE_ALL_MAIL:
      return {
        mail: null,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
};

export default mailReducer;
