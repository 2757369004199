export function updateCustomerDetail(originalObj) {
  const mapping = {
    custFirstName: "firstName",
    custMiddleName: "middleName",
    custLastName: "lastName",
    custStreetAddress1: "streetAddress1",
    custCity: "city",
    custState: "state",
    custCountry: "country",
    custZipCode: "zipCode",
    emailId: "emailId",
    custPhoneNumber: "dayPhone",
    custAltPhoneNbr: "mobilePhone",
    custOrdId: "customerOrderNumber",
  };

  const transformedObj = {};

  for (const key in mapping) {
    if (Object.prototype.hasOwnProperty.call(originalObj, key)) {
      transformedObj[mapping[key]] = originalObj[key];
    } else {
      transformedObj[mapping[key]] = "";
    }
  }

  return transformedObj;
}

export function createFirstUpdatePurchaseOrderDetail(deliveryDetail) {
  const { poDetailList } = deliveryDetail;

  return poDetailList.map((poDetail) => {
    return {
      poNumber: poDetail.poNbr,
      vendorName: poDetail.vendorName,
      vendorNumber: poDetail.vendorNbr,
      msNumber: poDetail.msNbr,
      lineItems: poDetail.lineLevelDetailList
        ? poDetail.lineLevelDetailList.map((lineItem) => {
            return {
              skuNumber: lineItem.skuNbr ? lineItem.skuNbr : null,
              modelNumber: lineItem.modelNbr ? lineItem.modelNbr : null,
              lineNumber: lineItem.lineNbr ? lineItem.lineNbr : null,
              quantity: lineItem.quantity ? lineItem.quantity : null,
              itemDesc: lineItem.itemDesc ? lineItem.itemDesc : null,
              serialNumber: lineItem.serialNbr ? lineItem.serialNbr : null,
            };
          })
        : [],
    };
  });
}

function addPartsAndService(updatedPurchaseOrderDetail, woDetail) {
  return updatedPurchaseOrderDetail.map((orderDetail) => {
    const sortedLineItems = orderDetail.lineItems.sort(
      (a, b) => parseInt(a.lineNumber) - parseInt(b.lineNumber)
    );
    const lineItemsWithPartsAndServices = sortedLineItems.map(
      (lineItem, index, array) => {
        const nextLineItem = array[index + 1]; // Get the next lineItem
        const nextLineNumber = nextLineItem
          ? parseInt(nextLineItem.lineNumber)
          : Infinity;
        const associatedLineLevelDetails = woDetail.filter((detail) => {
          const detailLineNumber = parseInt(detail.lineNbr);
          const currentLineNumber = parseInt(lineItem.lineNumber);

          return (
            detailLineNumber > currentLineNumber &&
            detailLineNumber < nextLineNumber
          );
        });

        const partsAndServices = associatedLineLevelDetails.map(
          (matchingDetail) => {
            return {
              skuNumber: matchingDetail.skuNbr,
              modelNumber: matchingDetail.modelNbr,
              lineNumber: matchingDetail.lineNbr,
              quantity: matchingDetail.quantity,
              itemDesc: matchingDetail.itemDesc,
            };
          }
        );

        return {
          ...lineItem,
          partsAndServices: partsAndServices,
        };
      }
    );

    return {
      ...orderDetail,
      lineItems: lineItemsWithPartsAndServices,
    };
  });
}

export function updatePurchaseOrderDetail(deliveryDetail) {
  const { woDetail } = deliveryDetail;

  // Create the initial update purchase order detail
  const updatedPurchaseOrderDetail =
    createFirstUpdatePurchaseOrderDetail(deliveryDetail);

  const sortedWoDetail = woDetail
    ? woDetail.lineLevelDetailList.sort(
        (a, b) => parseInt(a.lineNbr) - parseInt(b.lineNbr)
      )
    : null;
  // Initialize result variable
  let result;

  // Check if woDetail exists
  if (woDetail) {
    // If woDetail exists, call addPartsAndService function
    result = addPartsAndService(updatedPurchaseOrderDetail, sortedWoDetail);
  } else {
    // If woDetail doesn't exist, set result to updatedPurchaseOrderDetail
    result = updatedPurchaseOrderDetail;
  }

  // Return the result
  return result;
}
