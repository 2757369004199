import {
  IDENTITY_ME_REQUEST,
  IDENTITY_ME_SUCCESS,
  IDENTITY_ME_FAIL,
} from "../constants/pfConstants";

const pfReducer = (state, { payload, type }) => {
  switch (type) {
    case IDENTITY_ME_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case IDENTITY_ME_SUCCESS:
      return {
        loading: false,
        data: payload,
        error: false,
      };
    case IDENTITY_ME_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default pfReducer;
