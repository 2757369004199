import React from "react";
import "./DeliveryInfo.less";

function DeliveryInfo({ deliveryDetail, orderType }) {
  const { truckId, isAttended, deliveryLineItem } = deliveryDetail;

  return (
    <div className="delivery-type-card">
      <div className="delivery-type">Delivery type:</div>
      {orderType && <div className="delivery-type">{orderType}</div>}
      {isAttended != null && (
        <div className="delivery-type">
          {isAttended === true && "Attended"}
          {isAttended === false && "Unattended"}
        </div>
      )}
      {truckId && (
        <div className="delivery-type">{truckId ? truckId : "Unassigned"}</div>
      )}
      {deliveryLineItem && (
        <div data-testid="delivery-line">{deliveryLineItem.itemDesc}</div>
      )}
    </div>
  );
}

export default DeliveryInfo;
