import Axios from "axios";
import {
  MAIL_MESSAGE_REQUEST,
  MAIL_MESSAGE_SUCCESS,
  MAIL_MESSAGE_FAIL,
  CLEAN_MAIL_MESSAGE,
} from "../constants/mailMessageConstants";
import getBffUrl from "../../util/getBffUrl";

export const getMailMessage = (dispatch) => async (messageMetaData, ldap) => {
  dispatch({
    type: MAIL_MESSAGE_REQUEST,
  });
  try {
    //const { data } = await Axios.get("http://localhost:8080/mail/v1/mailMessageDetails", {
    const { data } = await Axios.get(
      `${getBffUrl()}/mail/v1/mailMessageDetails`,
      {
        params: {
          mailMsgId: messageMetaData.mailMsgId,
          refMailMsgId: messageMetaData.refMailMsgId,
          ldap,
        },
      }
    );
    dispatch({
      type: MAIL_MESSAGE_SUCCESS,
      payload: {
        ...messageMetaData,
        mailMsgBody: data ? data : [],
      },
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: MAIL_MESSAGE_FAIL, payload: message });
  }
};

export const cleanMailMessage = (dispatch) => () => {
  dispatch({
    type: CLEAN_MAIL_MESSAGE,
  });
};
