import React, { useState } from "react";
import { Button, Tooltip } from "antd";
import "./CheckmarkTooltipButton.less";

const CheckmarkTooltipButton = ({
  "aria-label": ariaLabel,
  icon,
  text,
  onClick,
  disabled,
  tooltip,
}) => {
  const [animate, setAnimate] = useState(false);
  const buttonRender = (
    <Button
      type="primary"
      aria-label={ariaLabel}
      className={animate ? "animated-button-animating" : "animated-button"}
      icon={icon}
      disabled={disabled}
      onClick={() => {
        onClick();
        setAnimate(true);
        setTimeout(() => {
          setAnimate(false);
        }, 1000);
      }}
    >
      {text}
    </Button>
  );
  if (tooltip) {
    return <Tooltip {...tooltip}>{buttonRender}</Tooltip>;
  }
  return buttonRender;
};
export default CheckmarkTooltipButton;
