import { DownOutlined } from "@ant-design/icons";
import Collapsible from "react-collapsible";
import React, { useState } from "react";
import DeliveryDetails from "../../DeliveryDetails/DeliveryDetails";
import "./DriverDeliveries.less";
import PropTypes from "prop-types";

const DriverDeliveries = (props) => {
  const { driver } = props;
  const [isOpen, toggleCollapsible] = useState(true);

  const showDeliveryDate = Boolean(false);

  return (
    <Collapsible
      open={true}
      onTriggerOpening={() => toggleCollapsible(true)}
      onTriggerClosing={() => toggleCollapsible(false)}
      trigger={
        <div className="collapsible-trigger">
          <DownOutlined
            rotate={isOpen ? 0 : 270}
            style={{ color: "#9B9B9B", fontSize: "20px" }}
          />
          <span className="truck-number">
            Truck {driver.truckNumber ? driver.truckNumber : "not assigned"}
          </span>
        </div>
      }
    >
      <div className="order-container">
        {driver &&
          isOpen &&
          driver.unassignedOrders &&
          driver.unassignedOrders.map((order, orderIndex) => (
            <DeliveryDetails
              showDeliveryDate={showDeliveryDate}
              deliveries={driver}
              orderDetails={order}
              key={orderIndex}
              orderIndex={order.orderIndex}
            />
          ))}
      </div>
    </Collapsible>
  );
};

DriverDeliveries.propTypes = { driver: PropTypes.object.isRequired };

export default DriverDeliveries;
