import { useEffect, useState } from "react";
import { getStopFieldDataUrl } from "../../util/RoutePlanner/routePlannerUtils";
import axios from "axios";
import useNotification from "../useNotification";

/**
 * Custom hook to fetch stop field data.
 *
 * @returns {{stopFieldData: Array}} - The fetched data.
 */
export const useStopFieldData = (selectedStopID) => {
  const [stopFieldData, setStopFieldData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { openErrorNotification, contextHolder } = useNotification();

  useEffect(() => {
    setLoading(true);
    const fetchStopFieldData = async () => {
      try {
        if (selectedStopID && selectedStopID !== "") {
          const response = await axios.get(getStopFieldDataUrl(selectedStopID));
          if (response.status !== 200) {
            throw new Error("Network response was not ok");
          }

          const { data } = response;
          setStopFieldData(data);
        }
      } catch (error) {
        console.error("Error fetching route details:", error);
        setError(error.message);
        openErrorNotification({
          message: "Error Retrieving Data",
          description:
            "We're Sorry! There is no data available at this time. Please try again later.",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchStopFieldData();
  }, [selectedStopID]);

  return { loading, error, stopFieldData, contextHolder };
};
