import { Row, Col, Image, Divider } from "antd";
import React, { useContext } from "react";
import "./ProductsDetails.less";
import getSvgString from "../../../../util/getSvgAndImageString";
import DashboardContext from "../../../../context/DashboardContext";
import { DASHBOARD_STH_CONTEXT } from "../../../../util/Constants";

function ProductsDetails({ purchaseOrderDetail }) {
  const dashboardContext = useContext(DashboardContext);
  const imageComponent = (lineItem) => {
    if (
      lineItem.images &&
      lineItem.images.sizes &&
      lineItem.images.urlTemplate
    ) {
      return (
        <Image
          src={lineItem.images.urlTemplate.replace(
            /\${size}/g,
            lineItem.images.sizes[lineItem.images.sizes.length - 1]
          )}
        />
      );
    }
    return <Image src="" fallback={getSvgString("unavailableImage")} />;
  };

  let isAppliance = false;
  if (purchaseOrderDetail) {
    purchaseOrderDetail.forEach((purchaseOrder) => {
      if (purchaseOrder.lineItems && purchaseOrder.lineItems.length > 0) {
        purchaseOrder.lineItems.forEach((lineItem) => {
          isAppliance = lineItem.isSpecialOrder;
        });
      }
    });
  }

  const getQuantityText = (lineItem) => {
    const { originalOrderedQty, quantity } = lineItem;

    if (originalOrderedQty) {
      if (isLineCancelled(quantity)) {
        return `Qty: ${Math.floor(originalOrderedQty)}`;
      }
      return originalOrderedQty === quantity
        ? `Qty: ${Math.floor(quantity)}`
        : `Qty: ${Math.floor(quantity)}` +
            " of " +
            `${Math.floor(originalOrderedQty)}`;
    } else {
      return `Qty: ${Math.floor(quantity)}`;
    }
  };

  function isLineCancelled(itemQuantity) {
    return (
      itemQuantity && !isNaN(itemQuantity) && Math.floor(itemQuantity) === 0
    );
  }

  function formatDimensions(dimensions) {
    const parts = [];

    if (dimensions) {
      if (dimensions.height) {
        parts.push(
          `H ${dimensions.height.value} ${dimensions.height.unitOfMeasure}`
        );
      }
      if (dimensions.width) {
        parts.push(
          `W ${dimensions.width.value} ${dimensions.width.unitOfMeasure}`
        );
      }
      if (dimensions.depth) {
        parts.push(
          `D ${dimensions.depth.value} ${dimensions.depth.unitOfMeasure}`
        );
      }
    }
    return parts.length > 0 ? parts.join(" x ") : null;
  }

  return (
    <>
      {purchaseOrderDetail &&
        purchaseOrderDetail.length > 0 &&
        purchaseOrderDetail.map((poDetail, poIndex) => (
          <Row key={poIndex}>
            {isAppliance && (
              <Col span={24} align="left">
                <Row>
                  <Col span={24} align="left">
                    <Divider
                      orientation="left"
                      plain
                      className="product-top-divider"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={6} className="item-info">
                    {poDetail.vendorName && (
                      <span className="vendor-name">{poDetail.vendorName}</span>
                    )}
                  </Col>
                  <Col span={6} className="item-info">
                    {poDetail.msNumber && (
                      <span className="vendor-detail">
                        MSN: {poDetail.msNumber}
                      </span>
                    )}
                  </Col>
                  <Col span={6} className="item-info">
                    {poDetail.poNumber && (
                      <span className="vendor-detail">
                        PO: {poDetail.poNumber}
                      </span>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={24} align="left">
                    <Divider
                      orientation="left"
                      plain
                      className="product-bottom-divider"
                    />
                  </Col>
                </Row>
              </Col>
            )}
            <Col span={24}>
              {poDetail.lineItems &&
                poDetail.lineItems.length > 0 &&
                poDetail.lineItems.map((lineItem, lineIndex) => {
                  const formattedDimensions = formatDimensions(
                    lineItem.dimensions
                  );
                  return (
                    <div key={lineIndex}>
                      <Row
                        className={
                          isLineCancelled(lineItem.quantity)
                            ? "cancelled-item-section"
                            : ""
                        }
                      >
                        <Col span={6} className="purchase-order-details">
                          {lineItem && imageComponent(lineItem)}
                        </Col>
                        <Col span={18} className="purchase-order-details">
                          {isLineCancelled(lineItem.quantity) && (
                            <Row>
                              <Col span={4}>
                                <div id="cancelled-pill">
                                  <span className="cancelled-text">
                                    CANCELLED
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          )}
                          <div
                            className={`product-description ${
                              isLineCancelled(lineItem.quantity)
                                ? "strike-cancelled-content"
                                : ""
                            }`}
                          >
                            <span>{lineItem.itemDesc}</span>
                          </div>
                          <Row
                            className={
                              isLineCancelled(lineItem.quantity)
                                ? "strike-cancelled-content"
                                : ""
                            }
                          >
                            <Col span={4}>
                              <span>{getQuantityText(lineItem)}</span>
                            </Col>
                          </Row>
                          <div className="product-details">
                            <Row
                              className={
                                isLineCancelled(lineItem.quantity)
                                  ? "strike-cancelled-content"
                                  : ""
                              }
                            >
                              {lineItem.skuNumber && (
                                <Col
                                  xs={24}
                                  sm={24}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  className="item-info"
                                >
                                  <span>SKU # {lineItem.skuNumber}</span>
                                </Col>
                              )}
                              {formattedDimensions && (
                                <Col
                                  xs={24}
                                  sm={24}
                                  md={16}
                                  lg={16}
                                  xl={16}
                                  className="item-info"
                                >
                                  <span>Dims: {formattedDimensions}</span>
                                </Col>
                              )}
                            </Row>
                            <Row
                              className={
                                isLineCancelled(lineItem.quantity)
                                  ? "strike-cancelled-content"
                                  : ""
                              }
                            >
                              {lineItem.internetSKUNumber && (
                                <Col
                                  xs={24}
                                  sm={24}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  className="item-info"
                                  order={1}
                                >
                                  <span>
                                    Internet # {lineItem.internetSKUNumber}
                                  </span>
                                </Col>
                              )}
                              {lineItem.dimensions &&
                                lineItem.dimensions.weight && (
                                  <Col
                                    xs={24}
                                    sm={24}
                                    md={16}
                                    lg={16}
                                    xl={16}
                                    className="item-info"
                                    order={2}
                                  >
                                    <span>
                                      Weight: {lineItem.dimensions.weight.value}{" "}
                                      {lineItem.dimensions.weight.unitOfMeasure}
                                    </span>
                                  </Col>
                                )}

                              <Col
                                xs={24}
                                sm={24}
                                md={8}
                                lg={8}
                                xl={8}
                                className="item-info"
                                order={lineItem.internetSKUNumber ? 3 : 1}
                              >
                                {lineItem.modelNumber && (
                                  <span>Model # {lineItem.modelNumber}</span>
                                )}
                              </Col>
                            </Row>
                            {lineItem.partsAndServices &&
                              lineItem.partsAndServices.length > 0 && (
                                <Row className="parts-service-info">
                                  <Col span={24}>
                                    <span className="parts-service-header">
                                      Parts & Services - Manufacturer Standard
                                      Warranty
                                    </span>
                                  </Col>
                                </Row>
                              )}
                            {lineItem.partsAndServices &&
                              lineItem.partsAndServices.length > 0 &&
                              lineItem.partsAndServices.map(
                                (partsAndServices, psIndex) => (
                                  <Row
                                    key={psIndex}
                                    className={`parts-service-info ${
                                      isLineCancelled(lineItem.quantity) ||
                                      isLineCancelled(partsAndServices.quantity)
                                        ? "strike-cancelled-content"
                                        : ""
                                    }`}
                                  >
                                    {partsAndServices.itemDesc && (
                                      <Col
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={24}
                                        xl={12}
                                        className="item-info"
                                      >
                                        <span>{partsAndServices.itemDesc}</span>
                                      </Col>
                                    )}
                                    {partsAndServices.originalOrderedQty &&
                                      !isNaN(
                                        partsAndServices.originalOrderedQty
                                      ) && (
                                        <Col
                                          xs={12}
                                          sm={12}
                                          md={12}
                                          lg={24}
                                          xl={12}
                                          className="item-info"
                                        >
                                          <span>
                                            Qty:{" "}
                                            {Math.floor(
                                              partsAndServices.originalOrderedQty
                                            )}
                                          </span>
                                        </Col>
                                      )}
                                  </Row>
                                )
                              )}
                          </div>
                        </Col>
                      </Row>

                      {(isAppliance ||
                        purchaseOrderDetail.length === poIndex + 1) &&
                      poDetail.lineItems.length === lineIndex + 1 ? (
                        <></>
                      ) : (
                        <Row span={24} align="left">
                          <Divider
                            orientation="left"
                            plain
                            className="product-bottom-divider"
                          />
                        </Row>
                      )}
                    </div>
                  );
                })}
            </Col>
          </Row>
        ))}
    </>
  );
}

export default ProductsDetails;
