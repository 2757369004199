export const getColumnDefinitions = (tableType) => {
  if (tableType === "summary") {
    return {
      planning: planningColumns,
      dispatch: dispatchColumns,
      archive: archiveColumns,
      unassigned: unassignedColumns,
    };
  } else if (tableType === "details") {
    return {
      planning: planningDetailsColumns,
      dispatch: dispatchDetailsColumns,
    };
  }
  return {};
};

const planningColumns = [
  {
    title: "Truck ID",
    dataIndex: "truckId",
    key: "truckId",
    minWidth: "6em",
  },
  {
    title: "Route ID",
    dataIndex: "routeId",
    key: "routeId",
    minWidth: "6em",
  },
  {
    title: "Driver Name",
    dataIndex: "driverName",
    key: "driverName",
    minWidth: "8em",
  },
  {
    title: "Carrier",
    dataIndex: "carrierName",
    key: "carrierName",
    minWidth: "5em",
  },
  {
    title: "Number of Stops",
    dataIndex: "numberOfStops",
    key: "numberOfStops",
    minWidth: "10em",
    sorter: false,
    filters: false,
  },
  {
    title: "Last Publish",
    dataIndex: "lastUpdatedTimestamp",
    key: "lastUpdatedTimestamp",
    minWidth: "8em",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    minWidth: "6em",
  },
];

const dispatchColumns = [
  {
    title: "Truck ID",
    dataIndex: "truckId",
    key: "truckId",
    minWidth: "6em",
  },
  {
    title: "Route ID",
    dataIndex: "routeId",
    key: "routeId",
    minWidth: "6em",
  },
  {
    title: "Driver Name",
    dataIndex: "driverName",
    key: "driverName",
    minWidth: "8em",
  },
  {
    title: "Carrier",
    dataIndex: "carrierName",
    key: "carrierName",
    minWidth: "5em",
  },
  {
    title: "Number of Stops",
    dataIndex: "numberOfStops",
    key: "numberOfStops",
    minWidth: "10em",
    sorter: false,
    filters: false,
  },
  {
    title: "Completion ETA",
    dataIndex: "completionEta",
    key: "completionEta",
    minWidth: "10em",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    minWidth: "6em",
  },
];

const archiveColumns = [
  {
    title: "Route ID",
    dataIndex: "routeId",
    key: "routeId",
    minWidth: "6em",
  },
  {
    title: "Driver Name",
    dataIndex: "driverName",
    key: "driverName",
    minWidth: "8em",
  },
  {
    title: "Order #",
    dataIndex: "orderNumber",
    key: "orderNumber",
    minWidth: "10em",
  },
  {
    title: "W/O #",
    dataIndex: "workOrderNumber",
    key: "workOrderNumber",
    minWidth: "5em",
  },
  {
    title: "MSN #",
    dataIndex: "msn",
    key: "msn",
    minWidth: "5em",
  },
  {
    title: "Customer Name",
    dataIndex: "customerName",
    key: "customerName",
    minWidth: "10em",
  },
  {
    title: "Service Type",
    dataIndex: "serviceType",
    key: "serviceType",
    minWidth: "8em",
  },
  {
    title: "Delivery Date",
    dataIndex: "deliveryDate",
    key: "deliveryDate",
    minWidth: "9em",
  },
  {
    title: "Arrival Time",
    dataIndex: "actualArrivalDateTime",
    key: "actualArrivalDateTime",
    minWidth: "8em",
  },
  {
    title: "Time at Stop",
    dataIndex: "timeAtStop",
    key: "timeAtStop",
    minWidth: "8em",
  },
  {
    title: "Delivery Status",
    dataIndex: "deliveryStatus",
    key: "deliveryStatus",
    minWidth: "10em",
  },
  {
    title: "Driver Notes",
    dataIndex: "driverNotes",
    key: "driverNotes",
    minWidth: "8em",
  },
];

const unassignedColumns = [
  {
    title: "Order #",
    dataIndex: "orderNumber",
    key: "orderNumber",
    minWidth: "6em",
  },
  {
    title: "W/O #",
    dataIndex: "workOrderNumber",
    key: "workOrderNumber",
    minWidth: "5em",
  },
  {
    title: "MSN #",
    dataIndex: "msNumber",
    key: "msNumber",
    minWidth: "5em",
  },
  {
    title: "Customer Name",
    dataIndex: "customerName",
    key: "customerName",
    minWidth: "10em",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    minWidth: "8em",
  },
  {
    title: "Service Type",
    dataIndex: "serviceType",
    key: "serviceType",
    minWidth: "8em",
  },
  {
    title: "Delivery Window",
    dataIndex: "deliveryWindow",
    key: "deliveryWindow",
    minWidth: "10em",
  },
];

const planningDetailsColumns = [
  {
    title: "Stop #",
    dataIndex: "stopNumber",
    key: "stopNumber",
    minWidth: "5em",
  },
  {
    title: "Order #",
    dataIndex: "orderNumber",
    key: "orderNumber",
    minWidth: "5em",
  },
  {
    title: "W/O #",
    dataIndex: "workOrderNumber",
    key: "workOrderNumber",
    minWidth: "5em",
  },
  {
    title: "MSN #",
    dataIndex: "msNumber",
    key: "msNumber",
    minWidth: "5em",
  },
  {
    title: "Customer Name",
    dataIndex: "customerName",
    key: "customerName",
    minWidth: "10em",
  },
  {
    title: "Service Type",
    dataIndex: "serviceType",
    key: "serviceType",
    minWidth: "8em",
  },
  {
    title: "Delivery Window",
    dataIndex: "deliveryWindow",
    key: "deliveryWindow",
    minWidth: "10em",
  },
];

const dispatchDetailsColumns = [
  {
    title: "Stop #",
    dataIndex: "stopNumber",
    key: "stopNumber",
    minWidth: "5em",
  },
  {
    title: "Order #",
    dataIndex: "orderNumber",
    key: "orderNumber",
    minWidth: "5em",
  },
  {
    title: "W/O #",
    dataIndex: "workOrderNumber",
    key: "workOrderNumber",
    minWidth: "5em",
  },
  {
    title: "MSN #",
    dataIndex: "msNumber",
    key: "msNumber",
    minWidth: "5em",
  },
  {
    title: "Customer Name",
    dataIndex: "customerName",
    key: "customerName",
    minWidth: "10em",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    minWidth: "6em",
  },
  {
    title: "Service Type",
    dataIndex: "serviceType",
    key: "serviceType",
    minWidth: "8em",
  },
  {
    title: "ETA",
    dataIndex: "estArrivalDateTime",
    key: "estArrivalDateTime",
    minWidth: "4em",
  },
  {
    title: "Actual Arrival",
    dataIndex: "actualArrivalDateTime",
    key: "actualArrivalDateTime",
    minWidth: "8em",
  },
  {
    title: "Time at Stop",
    dataIndex: "timeAtStop",
    key: "timeAtStop",
    minWidth: "8em",
  },
  {
    title: "Delivery Status",
    dataIndex: "deliveryStatus",
    key: "deliveryStatus",
    minWidth: "9em",
  },
  {
    title: "Driver Notes",
    dataIndex: "driverNotes",
    key: "driverNotes",
    minWidth: "8em",
  },
];
