import React, { useContext, useState } from "react";
import { UserContext } from "../../context/RootContext";
import { Button, Modal, Radio, Row } from "antd";
import {
  GE_SUBROLES as subRoles,
  GE_PR_VENDOR_NUMBER,
} from "../../util/Constants";

const InitialRoleModal = () => {
  const { changeRole, user } = useContext(UserContext);
  const [selectedRole, setSelectedRole] = useState("");
  const mainlandVendorNumber = user.mvendorList.filter(
    (vendor) => vendor.vendorType === "OEM"
  )[0].vendorNumber;
  const prVendorNumber = GE_PR_VENDOR_NUMBER;

  return (
    <Modal
      id="InitialRoleModal"
      open={true}
      closable={false}
      maskClosable={false}
      footer={null}
      centered={true}
      width={330}
    >
      <Row
        justify="center"
        style={{
          fontSize: "18px",
          fontWeight: 600,
          padding: "20px",
        }}
      >
        Select your role to proceed
      </Row>
      <Radio.Group
        onChange={({ target }) => {
          setSelectedRole(target.value);
        }}
      >
        {Object.keys(subRoles).map((subRole, i) => (
          <Radio
            id={`irm${subRoles[subRole].replace(/\s/g, "")}`}
            value={subRole}
            key={i}
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "17px",
              margin: "8px",
            }}
          >
            {subRoles[subRole]}{" "}
            {subRole === "ADMS Vendors"
              ? `(${mainlandVendorNumber})`
              : subRole === "PR ADMS Vendors"
              ? `(${prVendorNumber})`
              : ""}
          </Radio>
        ))}
      </Radio.Group>
      <br />
      <Row justify="center" style={{ padding: "20px" }}>
        <Button
          type="primary"
          onClick={() => {
            changeRole([selectedRole]);
          }}
          disabled={!selectedRole}
          size="large"
        >
          <div style={{ fontSize: "17px" }} id="irmContinueToDMP">
            Continue to DMP
          </div>
        </Button>
      </Row>
    </Modal>
  );
};

export default InitialRoleModal;
