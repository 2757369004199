import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Steps,
  notification,
  Spin,
} from "antd";
import "./NewManualOrderButton.less"; //TODO: check why NewMOModal shares the same style sheet as NewManualOrderButton
import WeeklyCalendar from "../ManualOrder/MOScheduleDate/WeeklyCalendar";
import { UserContext } from "../../context/RootContext";
import * as constants from "../../util/Constants";
import phoneNumberFormatter from "../../util/PhoneNumberFormatter";
import { userRole, createRevisitReasons } from "../../util/Roles";
import DeliveriesHook from "../../hooks/deliveriesHook";
import CreateManualOrderHook from "../../hooks/createManualOrderHook";
import { determineManuallyCode } from "../../util/DetermineManuallyCode";
import useNewRelicMetrics from "../../hooks/newRelicMetricsHook";
import {
  NEW_MANUAL_ORDER_CANCEL_EVENT,
  NEW_MANUAL_ORDER_NEXT_ERROR_EVENT,
  NEW_MANUAL_ORDER_NEXT_EVENT,
  NEW_MANUAL_ORDER_SUBMIT_ERROR_EVENT,
  NEW_MANUAL_ORDER_SUBMIT_EVENT,
} from "../../util/NewRelicConstants";

const NewMOModal = ({ isModalVisible, setIsModalVisible }) => {
  const { createManualOrder, cleanCreateManualOrder, createManualOrderState } =
    CreateManualOrderHook();
  const { logNewRelicMetricsEvent } = useNewRelicMetrics();
  const {
    slotsByDate,
    cleanDeliverySlots,
    getDeliverySlots,
    deliverySlotsState,
  } = DeliveriesHook();

  const { user } = useContext(UserContext);

  const [addNotes, setAddNotes] = useState("");
  const [, setFieldValue] = useState({});
  const [selectDate, updateSelectDate] = useState();
  const [selectDateIndex, updateSelectDateIndex] = useState();
  const [selectedDateSlotsCount, updateSelectedDateSlotsCount] = useState();
  const [enableSubmit, toggleSubmit] = useState(false);

  const { Option } = Select;
  const { TextArea } = Input;
  const [stepsCount, setStepsCount] = useState(0);
  const [form] = Form.useForm();
  const notes = form.getFieldValue("notes");
  const firstName = form.getFieldValue("firstName");
  const lastName = form.getFieldValue("lastName");
  const streetAddress1 = form.getFieldValue("streetAddress1");
  const city = form.getFieldValue("city");
  const state = form.getFieldValue("state");
  const zipCode = form.getFieldValue("zipCode");
  const contactNumber = form.getFieldValue("contactNumber");
  const revisitReason = form.getFieldValue("revisitReason");
  const vendorNbr = form.getFieldValue("vendorNbr");
  const [isValidPhoneNbr, setIsValidPhoneNbr] = useState(false);

  useEffect(() => {
    if (deliverySlotsState.error && deliverySlotsState.errorData) {
      logNewRelicMetricsEvent(NEW_MANUAL_ORDER_NEXT_ERROR_EVENT, {
        text: revisitReason,
        error_message: deliverySlotsState.errorData.message,
        error_description: deliverySlotsState.errorData.description,
        error_status: deliverySlotsState.errorData.status,
      });
    }
  }, [deliverySlotsState]);

  function createPhoneNumberList() {
    const phoneNumbers = [];
    if (form.getFieldValue("contactNumber") != null) {
      const phoneNumber = {
        numberValue:
          form.getFieldValue("contactNumber") != null
            ? form.getFieldValue("contactNumber").substr(6, 13)
            : 0,
        phoneNumberTypeCode: 1,
        sequenceNumber: 1,
        areaCityCode:
          form.getFieldValue("contactNumber") != null
            ? form.getFieldValue("contactNumber").substr(1, 3).replace(/-/g, "")
            : 0,
        localNumber:
          form.getFieldValue("contactNumber") != null
            ? form
                .getFieldValue("contactNumber")
                .substr(6, 13)
                .replace(/-/g, "")
            : 0,
      };
      phoneNumbers.push(phoneNumber);
    }
    if (form.getFieldValue("secondaryPhone") != null) {
      const phoneNumber = {
        numberValue:
          form.getFieldValue("secondaryPhone") != null
            ? form.getFieldValue("secondaryPhone").substr(6, 13)
            : 0,
        phoneNumberTypeCode: 3,
        sequenceNumber: 2,
        areaCityCode:
          form.getFieldValue("secondaryPhone") != null
            ? form
                .getFieldValue("secondaryPhone")
                .substr(1, 3)
                .replace(/-/g, "")
            : 0,
        localNumber:
          form.getFieldValue("secondaryPhone") != null
            ? form
                .getFieldValue("secondaryPhone")
                .substr(6, 13)
                .replace(/-/g, "")
            : 0,
      };
      phoneNumbers.push(phoneNumber);
    }
    return phoneNumbers;
  }

  function SlotsErrorMessage() {
    return (
      <div className="slots-error">
        <p className="slots-error-message">Looks like something went wrong.</p>
        <p className="slots-error-desc">
          Please check the Zip Code for the Vendor you selected and try again.
        </p>
      </div>
    );
  }

  function handlePhoneNumberChange(event, field) {
    const new_number = phoneNumberFormatter(event.target.value);
    form.setFieldsValue({ [field]: new_number.number });
    setIsValidPhoneNbr(new_number.isValid);
  }

  const vendorNames = {
    60001329: "ELUX",
    60009305: "HDA",
    60010350: "RAS",
    60010349: "NSD",
    60000277: "NEW",
    60497693: "MYG",
    60012141: "NALG",
    60071986: "LG",
    60010323: "RTC",
    60010988: "ALST",
    60009844: "LinnS",
    60292562: "GE",
    60012690: "CLM",
    60030263: "GELA",
    60009458: "Temco",
    60009361: "DFL",
    60011157: "ULOV",
    60003652: "XPO",
    60086862: "FWS",
    60006964: "FSA",
    60001548: "SMSG",
    60008006: "INVT",
    60005057: "JBH",
    60011991: "RTS",
    60020061: "SHARP",
    60007612: "BOSCH",
  };

  function populateRevisitOptions(user) {
    const role = userRole(user);
    const reasonsForRevisit = createRevisitReasons(role);
    const optionsList = reasonsForRevisit.map((reason) => (
      <Option
        className="revisit-reason-option"
        key={reason.key}
        value={reason.key}
      >
        {reason.key}
      </Option>
    ));
    return optionsList;
  }

  const steps = [
    {
      title: "Profile",
      status: "Finished",
      content: (
        <Row gutter={20}>
          <Col className="gutter-row" lg={{ span: 12 }} md={{ span: 24 }}>
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[{ required: true, message: "First name is required" }]}
            >
              <Input
                name="firstName"
                className="firstName"
                placeholder="First Name"
              />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[{ required: true, message: "Last name is required" }]}
            >
              <Input name="lastName" placeholder="Last Name" />
            </Form.Item>
            <Form.Item
              name="streetAddress1"
              label="Street Address"
              rules={[
                { required: true, message: "Street address is required" },
              ]}
            >
              <Input placeholder="Street Address" />
            </Form.Item>
            <Form.Item name="streetAddress2">
              <Input placeholder="Apt, Suite, Unit, Building" />
            </Form.Item>
            <Row gutter={20}>
              <Col
                className="gutter-row"
                lg={{ span: 8 }}
                md={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Form.Item
                  name="city"
                  label="City"
                  rules={[{ required: true, message: "City is required" }]}
                >
                  <Input placeholder="City" />
                </Form.Item>
              </Col>
              <Col
                className="gutter-row"
                lg={{ span: 8 }}
                md={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Form.Item
                  name="state"
                  label="State"
                  rules={[{ required: true, message: "State is required" }]}
                >
                  <Input placeholder="State" />
                </Form.Item>
              </Col>
              <Col
                className="gutter-row"
                lg={{ span: 8 }}
                md={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Form.Item
                  name="zipCode"
                  label="Postal Code"
                  rules={[
                    { required: true, message: "Postal code is required" },
                  ]}
                >
                  <Input placeholder="Postal Code" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col className="gutter-row" lg={{ span: 12 }} md={{ span: 24 }}>
            <Row gutter={20}>
              <Col
                className="gutter-row"
                lg={{ span: 12 }}
                md={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Form.Item
                  name="contactNumber"
                  label="Home Phone Number"
                  rules={[
                    { required: true, message: "Home Number is required" },
                  ]}
                >
                  <Input
                    onChange={(event) =>
                      handlePhoneNumberChange(event, "contactNumber")
                    }
                  />
                </Form.Item>
              </Col>
              <Col
                className="gutter-row"
                lg={{ span: 12 }}
                md={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Form.Item
                  name="secondaryPhone"
                  label="Office Phone Number"
                  rules={[{ message: "Number must be correct format" }]}
                >
                  <Input
                    onChange={(event) =>
                      handlePhoneNumberChange(event, "secondaryPhone")
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col
                className="gutter-row"
                lg={{ span: 12 }}
                md={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Form.Item
                  name="revisitReason"
                  label="Reason for revisit"
                  rules={[{ required: true }]}
                >
                  <Select
                    className="revisit-selector"
                    autoFocus={false}
                    placeholder="Please select a reason for revisit"
                  >
                    {populateRevisitOptions(user)}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                className="gutter-row"
                lg={{ span: 12 }}
                md={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Form.Item
                  name="vendorNbr"
                  label="Vendor"
                  rules={[{ required: true }]}
                >
                  <Select
                    className="vendor-selector"
                    placeholder="Please select a vendor"
                  >
                    {Object.keys(vendorNames).map((opt) => (
                      <Option className="vendor-option" value={opt} key={opt}>
                        {vendorNames[opt]}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              className="notes"
              name="notes"
              label="Notes"
              rules={[{ required: true, message: "Notes are required" }]}
            >
              <TextArea
                maxLength={255}
                autoSize={{ minRows: 7, maxRows: 7 }}
                onChange={(e) => setAddNotes(e.target.value)}
              />
            </Form.Item>
            <div className="note-footer">
              <span className={"character-remaining-label"}>
                {255 - addNotes.length} characters remaining
              </span>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Date Selection",
      content: (
        <div className="new-mo-calendar-container">
          {createManualOrderState.loading ? (
            <div className="spinner" data-testid="spinner-modal">
              <Spin size="large" />
            </div>
          ) : (
            <>
              <h4>Select Date</h4>
              <Divider />
              <Row justify="center">
                <Col
                  xl={{ span: 16 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  {deliverySlotsState.error && <SlotsErrorMessage />}
                  {deliverySlotsState.loading && (
                    <div className="spinner">
                      <Spin size="large" />
                    </div>
                  )}
                  {slotsByDate && (
                    <WeeklyCalendar
                      toggleSubmit={toggleSubmit}
                      slotsByDate={slotsByDate}
                      updateSelectDate={updateSelectDate}
                      updateSelectDateIndex={updateSelectDateIndex}
                      updateSelectedDateSlotsCount={
                        updateSelectedDateSlotsCount
                      }
                    />
                  )}
                </Col>
              </Row>
            </>
          )}
        </div>
      ),
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  useEffect(() => {
    if (createManualOrderState.loading === false) {
      setIsModalVisible(false);
      if (
        createManualOrderState.error === true &&
        createManualOrderState.errorData
      ) {
        logNewRelicMetricsEvent(NEW_MANUAL_ORDER_SUBMIT_ERROR_EVENT, {
          calendar_index: selectDateIndex,
          selected_date_slot_count: selectedDateSlotsCount,
          text: revisitReason,
          error_message: createManualOrderState.errorData.message,
          error_description: createManualOrderState.errorData.description,
          error_status: createManualOrderState.errorData.status,
        });
        notification.error({
          key: `open${Date.now()}`,
          ...constants.ERROR_NOTIFICATION_CONFIGS,
          message: "Failure",
          description: `Manual order hasn't been successful created`,
        });
      }
      if (
        createManualOrderState.data &&
        createManualOrderState.error === false
      ) {
        logNewRelicMetricsEvent(NEW_MANUAL_ORDER_SUBMIT_EVENT, {
          calendar_index: selectDateIndex,
          selected_date_slot_count: selectedDateSlotsCount,
          text: revisitReason,
        });
        notification.open({
          key: `open${Date.now()}`,
          ...constants.SUCCESS_NOTIFICATION_CONFIGS,
          message: "Success",
          description: `Manual order has been successfully created with MS# ${
            createManualOrderState.data &&
            createManualOrderState.data.newMSNumber &&
            new RegExp("^[a-zA-Z0-9]{10}$", "i").test(
              createManualOrderState.data.newMSNumber
            )
              ? createManualOrderState.data.newMSNumber
              : "---"
          }`,
        });
      }
    }
  }, [createManualOrderState]);

  return (
    <Modal
      title={null}
      open={isModalVisible}
      width={"90%"}
      footer={null}
      closable={false}
      style={{ top: 20 }}
    >
      <div className="modal-body-container">
        <Form
          form={form}
          layout="vertical"
          onFinish={async () => {
            if (createManualOrderState.loading) {
              return;
            }
            await createManualOrder({
              request: {
                customerFirstName: form.getFieldValue("firstName"),
                customerLastName: form.getFieldValue("lastName"),
                shipToAddressText1: form.getFieldValue("streetAddress1"),
                shipToAddressText2:
                  form.getFieldValue("streetAddress2") != null
                    ? form.getFieldValue("streetAddress2")
                    : " ",
                shipToCityName: form.getFieldValue("city"),
                shipToStateCode: form.getFieldValue("state"),
                shipToZipCode: form.getFieldValue("zipCode"),
                contactPhones: createPhoneNumberList(),
                deliveryNoteText: form.getFieldValue("notes"),
                scheduledDeliveryDate: selectDate,
                manuallyAddedReasonCode: determineManuallyCode(
                  form.getFieldValue("revisitReason")
                ),
                merchandiseVendorNumber: form.getFieldValue("vendorNbr"),
                deliveryLines: null,
                deliveryId: "",
                hasExistingDelivery: false,
              },
            });
            setStepsCount(0);
            form.resetFields();
            toggleSubmit(false);
            cleanDeliverySlots();
            cleanCreateManualOrder();
          }}
          onValuesChange={(v) => setFieldValue(v)}
        >
          <div className="steps-container">
            <Row>
              <Col
                xl={{ span: 8 }}
                lg={{ span: 10 }}
                md={{ span: 12 }}
                xs={{ span: 24 }}
              >
                <Steps current={stepsCount} items={items} />
              </Col>
            </Row>
          </div>
          <Row>
            <h3>Create New Manual Order</h3>
          </Row>
          <div className="steps-content">{steps[stepsCount].content}</div>
          <Divider />
          <Row gutter={[16, 16]} justify="center">
            <div className="steps-action">
              <Button
                onClick={() => {
                  setIsModalVisible(false);
                  setStepsCount(0);
                  form.resetFields();
                  cleanDeliverySlots();
                  toggleSubmit(false);
                  logNewRelicMetricsEvent(NEW_MANUAL_ORDER_CANCEL_EVENT, {
                    text: revisitReason,
                  });
                }}
              >
                Cancel
              </Button>
              {stepsCount < steps.length - 1 && (
                <Button
                  style={{ margin: "0 8px" }}
                  className="next-step-btn"
                  type="primary"
                  disabled={
                    firstName === undefined ||
                    firstName === "" ||
                    lastName === undefined ||
                    lastName === "" ||
                    streetAddress1 === undefined ||
                    streetAddress1 === "" ||
                    city === undefined ||
                    city === "" ||
                    state === undefined ||
                    state === "" ||
                    zipCode === undefined ||
                    zipCode === "" ||
                    contactNumber === undefined ||
                    contactNumber === "" ||
                    isValidPhoneNbr === false ||
                    vendorNbr === undefined ||
                    revisitReason === undefined ||
                    notes === undefined ||
                    notes === ""
                  }
                  onClick={() => {
                    setStepsCount(stepsCount + 1);
                    getDeliverySlots(null, vendorNbr, zipCode);
                    logNewRelicMetricsEvent(NEW_MANUAL_ORDER_NEXT_EVENT, {
                      text: revisitReason,
                    });
                  }}
                >
                  Next Step
                </Button>
              )}
              {stepsCount > 0 && (
                <Button
                  style={{ margin: "0 8px" }}
                  onClick={() => {
                    setStepsCount(stepsCount - 1);
                    toggleSubmit(false);
                    cleanDeliverySlots();
                  }}
                >
                  Previous
                </Button>
              )}
              {stepsCount === steps.length - 1 && (
                <Button
                  htmlType="submit"
                  type="primary"
                  disabled={!enableSubmit || createManualOrderState.loading}
                >
                  Submit
                </Button>
              )}
            </div>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default NewMOModal;
