import React from "react";
import "../ManualOrder.less";
import { Checkbox } from "antd";
import PropTypes from "prop-types";

const LineItems = ({
  poIndex,
  lineIndex,
  vendorName,
  productLine,
  disabled,
  setEnabledVendor,
  enabledLineItemsSet,
  setEnabledLineItemsSet,
  setSelectedVendor,
}) => {
  return (
    <div className="line-items">
      <Checkbox
        checked={true}
        onChange={({ target }) => {
          const newLineItemsSet = new Set(enabledLineItemsSet);

          if (target.checked) {
            newLineItemsSet.add(lineIndex);
            setEnabledLineItemsSet(newLineItemsSet);
            setEnabledVendor(poIndex);
            setSelectedVendor(vendorName);
          } else {
            newLineItemsSet.delete(lineIndex);
            setEnabledLineItemsSet(newLineItemsSet);
            if (newLineItemsSet.size === 0) {
              setEnabledVendor(null);
              setSelectedVendor("");
            }
          }
        }}
        disabled={disabled}
        value={`${productLine.modelNbr}__${lineIndex}`}
      >
        <div>Model #</div>
        <div className="model-nbr">{productLine.modelNbr}</div>
      </Checkbox>
    </div>
  );
};

LineItems.propTypes = { productLine: PropTypes.object.isRequired };

export default LineItems;
