import React, { useState } from "react";
import "../ManualOrder.less";
import PropTypes from "prop-types";
import LineItems from "./LineItems";
import "../../DeliveryDetails/DeliveryDetails.less";

const VendorItems = ({
  poDetail,
  poIndex,
  enabled,
  setEnabledVendor,
  setSelectedVendor,
}) => {
  const [enabledLineItemsSet, setEnabledLineItemsSet] = useState(new Set());
  const totalPOQty =
    poDetail &&
    poDetail.lineLevelDetailList &&
    poDetail.lineLevelDetailList
      .filter((el) => el.modelNbr !== "Revisit")
      .reduce(
        (total, productLine) => total + parseInt(productLine.quantity),
        0
      );
  const itemVsItems = (poQty) => {
    return poQty > 1 ? `${poQty} items` : `${poQty} item`;
  };

  return (
    <div className="product-orders-list">
      <div className="vendor-container">
        <div className="vendor">
          {poDetail.vendorName} ({itemVsItems(totalPOQty)})
        </div>
        <div className="vendor-number">{poDetail.msNbr}</div>
      </div>
      <div className="line-items-container">
        {poDetail &&
          poDetail.lineLevelDetailList &&
          poDetail.lineLevelDetailList.map(
            (productLine, lineIndex) =>
              productLine.quantity &&
              productLine.quantity >= 1 &&
              productLine.modelNbr !== "Revisit" && (
                <LineItems
                  setSelectedVendor={setSelectedVendor}
                  vendorName={poDetail.vendorName}
                  key={lineIndex}
                  lineIndex={lineIndex}
                  productLine={productLine}
                  disabled={!enabled}
                  setEnabledVendor={setEnabledVendor}
                  setEnabledLineItemsSet={setEnabledLineItemsSet}
                  enabledLineItemsSet={enabledLineItemsSet}
                  poIndex={poIndex}
                />
              )
          )}
      </div>
    </div>
  );
};

VendorItems.propTypes = { poDetail: PropTypes.object.isRequired };

export default VendorItems;
