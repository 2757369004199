import { createContext, useState } from "react";

const initialState = {
  tab: "",
  date: "",
  searchType: "",
  searchTerm: "",
  routeId: "",
  filterMap: {},
  sorterColumn: "",
  sorterDirection: "",
};

export const RoutePlannerViewContext = createContext({
  ...initialState,
  // eslint-disable-next-line no-empty-function
  setValues: () => {},
});

export const RoutePlannerViewContextProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  const setValues = (updates) => {
    setState((prevState) => {
      const newState = { ...prevState };
      Object.entries(initialState).forEach(([key]) => {
        if (updates[key] !== undefined) {
          if (key === "filterMap") {
            newState[key] = updates[key] ?? {};
          } else {
            newState[key] = updates[key] ?? "";
          }
        } else {
          newState[key] = "";
        }
      });

      return newState;
    });
  };

  return (
    <RoutePlannerViewContext.Provider
      value={{
        ...state,
        setValues,
      }}
    >
      {children}
    </RoutePlannerViewContext.Provider>
  );
};

export default RoutePlannerViewContext;
