import React, { useEffect } from "react";
import { Spin } from "antd";
import OrderDetails from "../DeliveriesCommon/OrderDetails/OrderDetails";
import OrderContext from "../../context/OrderContext";
import useTrackerUrlHook from "../../hooks/trackerUrlHook";
import useNewRelicMetrics from "../../hooks/newRelicMetricsHook";
import {
  ORDER_CARD_EXPAND_EVENT,
  WORK_BIN_ORDER_CARD_EXPAND_EVENT,
} from "../../util/NewRelicConstants";

const OrderCard = ({
  orderDetails,
  record,
  orderIndex,
  customerOrderNumber,
  updateOrderDetails,
  expanded,
  loadDeliveryTracker,
  orderCardColor,
  hideCustomerInfoEdit,
  sthRefreshDeliveries,
  isWorkBin,
}) => {
  const { trackerUrl, updateTrackerUrl } = useTrackerUrlHook();
  const { logNewRelicMetricsEvent } = useNewRelicMetrics();

  const canCreateOrderDetails = () => {
    return (
      orderDetails[record.key] &&
      expanded &&
      !orderDetails[record.key].loading &&
      Object.keys(orderDetails[record.key].data).length !== 0
    );
  };

  const isOrderDetailsValid = canCreateOrderDetails();

  useEffect(() => {
    const eventType = isWorkBin
      ? WORK_BIN_ORDER_CARD_EXPAND_EVENT
      : ORDER_CARD_EXPAND_EVENT;
    logNewRelicMetricsEvent(eventType, {
      order_number: customerOrderNumber,
      index: orderIndex,
    });
    if (loadDeliveryTracker) {
      updateTrackerUrl(record);
    }
  }, []);

  const getFieldIfExist = (object, value) => {
    return object ? object[value] : null;
  };

  if (isOrderDetailsValid) {
    return (
      <OrderContext.Provider
        value={{
          updateOrder: () => {
            updateOrderDetails(record);
          },
        }}
      >
        <OrderDetails
          workOrderStatus={record.workOrderStatus}
          orderIndex={orderIndex}
          isWorkBin={isWorkBin}
          orderDetails={orderDetails[record.key].data}
          trackerLink={getFieldIfExist(trackerUrl[record.key], "link")}
          loadDeliveryTracker={loadDeliveryTracker}
          orderCardColor={orderCardColor}
          hideCustomerInfoEdit={hideCustomerInfoEdit}
          sthRefreshDeliveries={sthRefreshDeliveries}
        />
      </OrderContext.Provider>
    );
  } else if (expanded && getFieldIfExist(orderDetails[record.key], "loading")) {
    return (
      <div className="workbin_loading">
        <Spin />
      </div>
    );
  } else {
    return (
      <div>
        <span className="error_message">
          Oops, something went wrong. Please try again.
        </span>
      </div>
    );
  }
};

export default OrderCard;
