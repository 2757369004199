export function determineManuallyCode(manualReasonCode) {
  let manuallyAddedReasonCode;

  switch (manualReasonCode) {
    case "Replacement":
      manuallyAddedReasonCode = 1;
      break;
    case "Pick-up for Return":
      manuallyAddedReasonCode = 2;
      break;
    case "Delivery Agent Service Call":
      manuallyAddedReasonCode = 3;
      break;
    case "Other":
      manuallyAddedReasonCode = 4;
      break;
    case "Plumber/Electrician Service Call":
      manuallyAddedReasonCode = 5;
      break;
    default:
      manuallyAddedReasonCode = 4;
      break;
  }

  return manuallyAddedReasonCode;
}
