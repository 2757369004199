import {
  CREATE_MESSAGE_REQUEST,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_FAIL,
  CLEAN_CREATE_MESSAGE,
} from "../constants/createMailConstants";

const createMessageReducer = (state, { payload, type }) => {
  switch (type) {
    case CREATE_MESSAGE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case CREATE_MESSAGE_SUCCESS:
      return {
        loading: false,
        data: payload,
        error: false,
      };
    case CREATE_MESSAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case CLEAN_CREATE_MESSAGE:
      return {
        data: null,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
};

export default createMessageReducer;
