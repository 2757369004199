import { Collapse, Card, Divider, Spin } from "antd";
import React, { useState } from "react";
import ProductLine from "./ProductLine.js";
import CancelledProductLine from "./CancelledProductLine";
import "./PurchaseOrder.less";
import PropTypes from "prop-types";
import moment from "moment";
import axios from "axios";
import getBffUrl from "../../util/getBffUrl";

const PurchaseOrder = (props) => {
  const { isWorkbin } = props;
  const [poDetail, setPoDetail] = useState(props.poDetail);
  const [loading, setLoading] = useState(false);
  const { Panel } = Collapse;
  const totalPOQty =
    poDetail &&
    poDetail.lineLevelDetailList &&
    poDetail.lineLevelDetailList.reduce(
      (total, productLine) => total + parseInt(productLine.quantity),
      0
    );
  const totalPOlines =
    poDetail && poDetail.lineLevelDetailList
      ? poDetail.lineLevelDetailList.length
      : 0;
  const displayDivider = (index) => {
    if (index > 0) {
      return <Divider />;
    }
  };

  const itemVsItems = (poQty) => {
    return poQty > 1 ? `${poQty} items` : `${poQty} item`;
  };

  const displayVendorNameAndMSNumber = () => {
    if (totalPOQty > 0) {
      return (
        <div className="vendor-ms-number-container">
          <div className="vendor-short-name">
            {poDetail.vendorName} ({itemVsItems(totalPOlines)})
          </div>
          <div className="ms-number">{poDetail.msNbr}</div>
          {displayCancelledString()}
        </div>
      );
    } else {
      return (
        <div className="vendor-ms-number-container">
          <div className="vendor-short-name-cancelled">
            {poDetail.vendorName} ({itemVsItems(totalPOlines)})
          </div>
          <div className="ms-number-cancelled">{poDetail.msNbr}</div>
          {displayCancelledString()}
        </div>
      );
    }
  };

  const displayCancelledString = () => {
    if (totalPOQty === 0) {
      return (
        <div className="po-cancelled-container">
          <span className="po-cancelled">
            <img
              id="cancelled-img"
              src="/images/cancelled.svg"
              alt="cancelled-icon"
            />
            <span>CANCELLED</span>
          </span>
        </div>
      );
    } else if (totalPOlines > totalPOQty) {
      return (
        <div className="po-cancelled-container">
          <img
            id="cancelled-img"
            src="/images/cancelled.svg"
            alt="cancelled-icon"
          />
          <span className="po-cancelled">
            {totalPOlines - totalPOQty}/{totalPOlines} ITEMS CANCELLED
          </span>
        </div>
      );
    }
  };

  const displayLineCancelledDate = (productLine) => {
    return productLine.lineLevelEventList &&
      productLine.lineLevelEventList.find((event) => event.eventCrtTs) ? (
      <div id="cancelled-item-label">
        CANCELLED ITEM{" "}
        {moment(
          productLine.lineLevelEventList.find((event) => event.eventCrtTs)
            .eventCrtTs
        ).format("MM/DD/YY")}
      </div>
    ) : (
      <div id="cancelled-item-label">CANCELLED ITEM</div>
    );
  };

  const displaySKUNumber = (item) => {
    return (
      <div className="vendor-ms-number-container">
        <div className="vendor-short-name">SKU {item.skuNbr}</div>
        <div className="ms-number">Quantity {parseInt(item.quantity)} ea</div>
        {parseInt(item.quantity) === 0 && (
          <div className="po-cancelled-container">
            <span className="po-cancelled">
              <img
                id="cancelled-img"
                src="/images/cancelled.svg"
                alt="cancelled-icon"
              />
              <span>CANCELLED</span>
            </span>
          </div>
        )}
      </div>
    );
  };

  if (isWorkbin && poDetail && poDetail.lineLevelDetailList) {
    return (
      <>
        {poDetail.lineLevelDetailList.map((item, idx) => (
          <div className="po-container" key={idx}>
            <Collapse className="po-collapse" ghost={true}>
              <Panel header={displaySKUNumber(item)}>
                <Card
                  className="po-content-container"
                  title={
                    <div className="po-number-title">
                      <div className="po-number-text">Item Description</div>
                      <div className="po-number">{item.itemDesc}</div>
                    </div>
                  }
                ></Card>
              </Panel>
            </Collapse>
          </div>
        ))}
      </>
    );
  }

  function expandDetails(key) {
    if (key.length > 0 && !isWorkbin) {
      setLoading(true);
      const deliveryLineIds = [];
      for (const x in poDetail.lineLevelDetailList) {
        deliveryLineIds.push(poDetail.lineLevelDetailList[x].lineReferenceId);
      }
      axios
        .post(`${getBffUrl()}/v1/deliveryLines`, {
          deliveryId: poDetail.referenceId,
          deliveryLineIds: deliveryLineIds,
        })
        .then((response) => {
          if (response && response.status === 200) {
            if (response.data && response.data.length > 0) {
              const new_poDetail = { ...poDetail };
              new_poDetail.lineLevelDetailList = response.data;
              setPoDetail(new_poDetail);
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }

  return (
    <div className="po-container">
      <Collapse className="po-collapse" ghost={true} onChange={expandDetails}>
        <Panel header={displayVendorNameAndMSNumber()}>
          {loading ? (
            <div className="po-detail-loading">
              <Spin />
            </div>
          ) : (
            <Card
              className="po-content-container"
              title={
                <div className="po-number-title">
                  <div className="po-number-text">Vendor PO number</div>
                  <div className="po-number">{poDetail.poNbr}</div>
                </div>
              }
            >
              <div>
                <Divider />
                {poDetail &&
                  poDetail.lineLevelDetailList &&
                  poDetail.lineLevelDetailList.map(
                    (productLine, lineIndex) =>
                      productLine.quantity &&
                      productLine.quantity < 1 && (
                        <div className="product-line-container" key={lineIndex}>
                          <div id="cancelled-item-container">
                            {displayLineCancelledDate(productLine)}
                            <CancelledProductLine
                              productLine={productLine}
                              key={lineIndex}
                            />
                          </div>
                        </div>
                      )
                  )}
              </div>
              {totalPOQty > 0 && totalPOQty < totalPOlines && <Divider />}
              {poDetail &&
                poDetail.lineLevelDetailList &&
                poDetail.lineLevelDetailList.map(
                  (productLine, lineIndex) =>
                    productLine.quantity &&
                    productLine.quantity >= 1 && (
                      <div className="product-line-container" key={lineIndex}>
                        {displayDivider(lineIndex)}
                        <ProductLine
                          productLine={productLine}
                          key={lineIndex}
                        />
                      </div>
                    )
                )}
            </Card>
          )}
        </Panel>
      </Collapse>
    </div>
  );
};

PurchaseOrder.propTypes = { poDetail: PropTypes.object.isRequired };

export default PurchaseOrder;
