import React from "react";
import PropTypes from "prop-types";
import * as constants from "../../util/Constants";

const Timeline = (props) => {
  const { deliveryWindowDetail } = props;
  const { referenceId, orderEvents } = deliveryWindowDetail;

  function displayCurrentEvent() {
    const wr = document.getElementById(`timeline-box-${referenceId}`);
    if (wr) {
      wr.scrollTop = orderEvents.length * 75 - 75;
    }
  }

  function getTimeLineDetails() {
    const timelineEvents = [];
    populateTimelineEvents(timelineEvents);
    setTimelineFlags(timelineEvents);
    displayCurrentEvent();
    return timelineEvents;
  }

  function populateTimelineEvents(timelineEvents) {
    //past and current
    orderEvents.forEach((item, slot) => {
      const orderEvent = {
        time: null,
        eventDesc: null,
        flag: null,
        referenceId: null,
      };
      orderEvent.time = orderEvents[slot].orderEventCrtTimeStamp;
      orderEvent.eventDesc = orderEvents[slot].orderEventDescription;
      orderEvent.referenceId = referenceId;
      orderEvent.rescheduleText = item.rescheduledDeliveryDate
        ? `From ${item.originalDeliveryDate.substring(
            0,
            8
          )} to ${item.rescheduledDeliveryDate.substring(0, 8)}`
        : "";
      orderEvent.reasonCode = item.reasonCode
        ? `Reason: ${constants.RESCHEDULE_REASON_CODES[item.reasonCode]}`
        : "";
      timelineEvents.push(orderEvent);
    });

    //future
    const futureHappyEvents = getFutureEvents(
      timelineEvents.map((event) => event.eventDesc)
    );
    futureHappyEvents.forEach((futureEvent) => {
      const orderEvent = {
        time: null,
        eventDesc: null,
        flag: null,
        referenceId: null,
      };
      orderEvent.time = "";
      orderEvent.eventDesc = futureEvent;
      timelineEvents.push(orderEvent);
    });
  }

  function getFutureEvents(eventData) {
    const happyPath = [
      constants.ORDER_PROCESSED,
      constants.ON_THE_WAY,
      constants.AT_LOCATION,
      constants.DELIVERED,
    ];
    if (
      eventData.includes(constants.CANCELLED) ||
      eventData.includes(constants.DELIVERED_W_EXCEPTION) ||
      eventData.includes(constants.DELIVERED)
    ) {
      return [];
    } else if (
      eventData.includes(constants.MISSED_W_ATTEMPT) ||
      eventData.includes(constants.MISSED_WO_ATTEMPT)
    ) {
      return ["Next steps pending"];
    } else {
      for (let i = 0; i < eventData.length; i++) {
        for (let x = 0; x < happyPath.length; x++) {
          if (eventData[i] === happyPath[x]) {
            happyPath.splice(x, x + 1);
          }
        }
      }
    }
    return happyPath;
  }

  function setTimelineFlags(timelineEvents) {
    let i, j;
    for (i = 0, j = 0; i < timelineEvents.length; i++) {
      if (timelineEvents[i].time === "") {
        timelineEvents[i].flag = constants.FUTURE;
        //Below step to assign CURRENT flag just before all the FUTURE events
        if (j === 0) {
          timelineEvents[i - 1].flag = constants.CURRENT;
          j++;
        }
      }
      if (timelineEvents[i].time !== "") {
        timelineEvents[i].flag = constants.PAST;
      }
    }
  }

  return (
    <div className="timeline-box" id={`timeline-box-${referenceId}`}>
      <div className="timeline-item-content">
        <TimelineItem
          events={getTimeLineDetails(deliveryWindowDetail)}
          referenceId={deliveryWindowDetail.referenceId}
        />
      </div>
    </div>
  );
};

const TimelineItem = (props) => {
  const { events, referenceId } = props;

  return (
    <div className="timeline-wrapper">
      {events.map((event, numRowElements) => (
        <div
          className="timeline-container"
          key={numRowElements}
          data-testid="timeline-container"
        >
          {
            <TimelineItemContent
              time={event.time}
              eventDesc={event.eventDesc}
              rescheduleText={event.rescheduleText}
              flag={event.flag}
              referenceId={referenceId}
              events={events}
              reasonCode={event.reasonCode}
            />
          }
        </div>
      ))}
    </div>
  );
};

const TimelineItemContent = (props) => {
  const {
    eventDesc,
    time,
    flag,
    referenceId,
    events,
    rescheduleText,
    reasonCode,
  } = props;
  let exceptionRefId = null;

  function getEventType() {
    if (flag === constants.PAST) {
      return (
        <div id="past-tense-event" className="text">
          {eventDesc === constants.RESCHEDULED ? (
            <>
              {eventDesc}
              <br />
              <div
                id="rescheduleText"
                style={{
                  fontSize: "10px",
                }}
              >
                {rescheduleText}
                <br />
                {reasonCode}
              </div>
            </>
          ) : (
            eventDesc
          )}
        </div>
      );
    } else if (
      flag === constants.CURRENT &&
      (eventDesc === constants.MISSED_W_ATTEMPT ||
        eventDesc === constants.MISSED_WO_ATTEMPT ||
        eventDesc === constants.DELIVERED_W_EXCEPTION)
    ) {
      return (
        <div
          id={`present-tense-event-${referenceId}`}
          className={"text present-tense-event-missed-delivery"}
        >
          {eventDesc}
        </div>
      );
    } else if (
      flag === constants.CURRENT &&
      (eventDesc === constants.ORDER_PROCESSED ||
        eventDesc === constants.RESCHEDULED ||
        eventDesc === constants.PO_RECEIVED_EVENT)
    ) {
      return (
        <div
          id={`present-tense-event-${referenceId}`}
          className={"text present-tense-event-order-or-rescheduled"}
        >
          {eventDesc === constants.RESCHEDULED ? (
            <>
              {eventDesc}
              <br />
              <div
                id="rescheduleText"
                style={{
                  fontSize: "12px",
                }}
              >
                {rescheduleText}
                <br />
                {reasonCode}
              </div>
            </>
          ) : (
            eventDesc
          )}
        </div>
      );
    } else if (
      flag === constants.CURRENT &&
      (eventDesc === constants.ON_THE_WAY ||
        eventDesc === constants.AT_LOCATION ||
        eventDesc === constants.DELIVERED)
    ) {
      return (
        <div
          id={`present-tense-event-${referenceId}`}
          className={"text present-tense-event"}
        >
          {eventDesc}
        </div>
      );
    } else if (flag === constants.FUTURE) {
      return (
        <div id="future-state-event" className="text">
          {eventDesc}
        </div>
      );
    }
  }

  function getTimeType() {
    if (flag === constants.PAST) {
      return (
        <div id="past-tense-date-time" className="text">
          {time}
        </div>
      );
    } else if (
      (flag === constants.CURRENT &&
        eventDesc === constants.MISSED_WO_ATTEMPT) ||
      (flag === constants.CURRENT && eventDesc === constants.MISSED_W_ATTEMPT)
    ) {
      return (
        <div id="present-tense-date-time-missed-delivery" className="text">
          {time}
        </div>
      );
    } else if (
      (flag === constants.CURRENT &&
        eventDesc !== constants.MISSED_WO_ATTEMPT) ||
      (flag === constants.CURRENT && eventDesc !== constants.MISSED_W_ATTEMPT)
    ) {
      return (
        <div id="present-tense-date-time" className="text">
          {time}
        </div>
      );
    }
  }

  function checkEventsForExceptions() {
    events.forEach((event) => {
      if (
        event.eventDesc === constants.MISSED_WO_ATTEMPT ||
        event.eventDesc === constants.MISSED_W_ATTEMPT
      ) {
        exceptionRefId = event.referenceId;
        return true;
      }
    });
  }

  function getLineType() {
    if (eventDesc === constants.ORDER_PROCESSED) {
      return <div id="firstLine" className="line" />;
    }
    if (
      flag === constants.PAST &&
      (eventDesc === constants.MISSED_W_ATTEMPT ||
        eventDesc === constants.MISSED_WO_ATTEMPT)
    ) {
      return <div id="happy-path-Line-to-exception" className="line" />;
    } else if (
      flag === constants.PAST &&
      (eventDesc !== constants.MISSED_W_ATTEMPT ||
        eventDesc !== constants.MISSED_WO_ATTEMPT)
    ) {
      return <div id="happy-path-Line" className="line" />;
    } else if (
      flag === constants.CURRENT &&
      (eventDesc === constants.MISSED_W_ATTEMPT ||
        eventDesc === constants.MISSED_WO_ATTEMPT)
    ) {
      return <div id="exception-path-Line" className="line" />;
    } else if (
      flag === constants.CURRENT &&
      (eventDesc !== constants.MISSED_W_ATTEMPT ||
        eventDesc !== constants.MISSED_WO_ATTEMPT)
    ) {
      checkEventsForExceptions();
      if (referenceId === exceptionRefId) {
        return <div id="exception-to-happy-path-Line" className="line" />;
      }
      return <div id="happy-path-Line" className="line" />;
    } else if (flag === constants.FUTURE) {
      return <div id="future-state-timeline" className="line" />;
    }
  }

  function getBulletType() {
    if (flag === constants.PAST) {
      if (
        eventDesc.match(constants.ORDER_PROCESSED) ||
        eventDesc.match(constants.RESCHEDULED)
      ) {
        return <div id="alternateBeforeOrderProcessed" className="circle" />;
      }
      if (
        eventDesc.match(constants.DELIVERED_W_EXCEPTION) ||
        eventDesc.match(constants.MISSED_W_ATTEMPT) ||
        eventDesc.match(constants.MISSED_WO_ATTEMPT)
      ) {
        return <div id="warning" className="alternateTriangle" />;
      }
      if (eventDesc.match(constants.CANCELLED)) {
        return <div id="catchColor" className="circle" />;
      }
      return <div id="happyBefore" className="circle" />;
    } else if (flag === constants.CURRENT) {
      if (
        eventDesc.match(constants.ORDER_PROCESSED) ||
        eventDesc.match(constants.RESCHEDULED)
      ) {
        return <div id="alternateCurrent" className="circle" />;
      }
      if (
        eventDesc.match(constants.DELIVERED_W_EXCEPTION) ||
        eventDesc.match(constants.MISSED_W_ATTEMPT) ||
        eventDesc.match(constants.MISSED_WO_ATTEMPT)
      ) {
        return <div id="newWarning" className="triangle" />;
      }
      if (eventDesc.match(constants.CANCELLED)) {
        return <div id="catchCurrent" className="circle" />;
      }
      return <div id="happyCurrent" className="circle" />;
    } else {
      return <div id="happyAfter" className="circle" />;
    }
  }

  return (
    <div
      className={`timeline-item timeline-${referenceId} ${
        flag === constants.CURRENT ? "timeline-resize-to-big" : ""
      }`}
    >
      <div className="timeline-item-content">
        {getEventType()}
        {getTimeType()}
        {getBulletType()}
        {getLineType()}
      </div>
    </div>
  );
};

Timeline.propTypes = { deliveryWindowDetail: PropTypes.object.isRequired };

TimelineItemContent.propTypes = {
  eventDesc: PropTypes.string.isRequired,
  flag: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  referenceId: PropTypes.string.isRequired,
};

TimelineItem.propTypes = {
  events: PropTypes.array.isRequired,
  referenceId: PropTypes.string.isRequired,
};

export default Timeline;
