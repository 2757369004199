import React, { useContext, useState } from "react";
import { Button, Modal } from "antd";
import PropTypes from "prop-types";
import MicroUI from "../../../DeliveryMicroUI/MicroUI";
import OrderContext from "../../../../context/OrderContext";
import {
  CLOSE_RESCHEDULE_MODAL_EVENT,
  RESCHEDULE_EVENT,
  WORK_BIN_RESCHEDULE_EVENT,
} from "../../../../util/NewRelicConstants";
import useNewRelicMetrics from "../../../../hooks/newRelicMetricsHook";
import { SOURCE_SYSTEM_ORDER_UP } from "../../../../util/Constants";

export const OrderDetailsReschedule = (props) => {
  const {
    workOrderNumber,
    location,
    orderSource,
    orderIndex,
    customerOrderNumber,
    isWorkBin,
  } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const { updateOrder } = useContext(OrderContext);
  const { logNewRelicMetricsEvent } = useNewRelicMetrics();

  // TODO: manufacturing vendors logic has to be added. Please refer to "deliverySlotsActions.js to see what "getManufacturingVendors" function should look like

  const handleClose = () => {
    setModalVisible(false);
    updateOrder();
  };

  return (
    <div>
      {/* //TODO: ADD THIS LOGIC TO THIS COMPONENT OPPORTUNELY
       {(orderSource.trim().toUpperCase().includes("AOS") ||
        orderSource.trim().toUpperCase().includes("ONLINE") ||
        orderSource.trim().toUpperCase().includes("ADMS") ||
        orderSource.trim().toUpperCase().includes("ORDERUP")) &&
        permit("reschedule", user) &&
        (!permit("applyRescheduleProductFilter", user) ||
          (permit("applyRescheduleProductFilter", user) &&
            getManufacturingVendors().includes(user.mvendorNum))) && (  */}
      <div id="new-card-reschedule-button">
        <Button
          className="default-btn"
          block
          onClick={() => {
            const eventCode = isWorkBin
              ? WORK_BIN_RESCHEDULE_EVENT
              : RESCHEDULE_EVENT;
            logNewRelicMetricsEvent(eventCode, {
              order_number: customerOrderNumber,
              text: "button",
              index: orderIndex,
            });
            setModalVisible(true);
          }}
        >
          Reschedule
        </Button>
      </div>
      <Modal
        open={modalVisible}
        title={null}
        footer={null}
        centered
        width={
          orderSource.trim().toUpperCase().includes(SOURCE_SYSTEM_ORDER_UP)
            ? "87.5%"
            : "80%"
        }
        destroyOnClose
        onCancel={() => {
          // The difference between OU and non-OU flows is that
          // the reschedule call in OU is out of our control, it happens in the app that runs in iframe.
          // That is why in non-OU flow the order will be updated after reschedule call
          // returns 200 response status.
          // But in OU-flow here we update order when modal is closed by user after he finished his work in OU
          logNewRelicMetricsEvent(CLOSE_RESCHEDULE_MODAL_EVENT, {
            order_number: customerOrderNumber,
            index: orderIndex,
            overlay_type: "commons",
          });
          if (
            orderSource.trim().toUpperCase().includes(SOURCE_SYSTEM_ORDER_UP)
          ) {
            handleClose();
          } else {
            setModalVisible(false);
          }
          //document.body.style.overflow = "auto";
        }}
      >
        {orderSource.trim().toUpperCase().includes(SOURCE_SYSTEM_ORDER_UP) && (
          <MicroUI
            location={location}
            workOrderNumber={workOrderNumber}
            orderNumber={customerOrderNumber}
          />
        )}
      </Modal>
    </div>
  );
};

OrderDetailsReschedule.propTypes = {
  workOrderDetail: PropTypes.any,
  workOrderNumber: PropTypes.string,
  customerDetail: PropTypes.any,
  deliveryDates: PropTypes.any,
  location: PropTypes.string,
  orderSource: PropTypes.string,
};
