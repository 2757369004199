import React, { useContext } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import RoutePlannerViewContext from "../../../context/RoutePlannerViewContext";

/**
 * A reusable DatePicker component that allows selecting a date and syncs with the URL query parameters.
 */
export default function DatePickerComponent({ dateFormat = "YYYY-MM-DD" }) {
  const routePlannerViewContext = useContext(RoutePlannerViewContext);
  const navigate = useNavigate();

  const onDateChange = (selectedDate) => {
    if (selectedDate) {
      const formattedDate = selectedDate.format(dateFormat);
      navigate(`?date=${formattedDate}`);
    }
  };

  return (
    <DatePicker
      size="large"
      format={dateFormat}
      placeholder={"Select Date"}
      value={
        routePlannerViewContext.date
          ? moment(routePlannerViewContext.date)
          : null
      }
      onChange={onDateChange}
      disabledDate={isDateDisabled(routePlannerViewContext.tab)}
    />
  );
}

function isDateDisabled(path) {
  return (current) => {
    if (path === "planning") {
      return current < moment().endOf("day");
    }
    return false;
  };
}
