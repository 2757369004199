import React, { useContext } from "react";
import { Table, Typography, Tooltip } from "antd";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import "./ResultTable.less";
import PhoneNumberFormatter from "../../../util/PhoneNumberFormatter";
import DateFormatter from "../../../util/DateFormatter";
import { formatTime } from "../../../util/DateFormatter";
const { Paragraph } = Typography;
import {
  DASHBOARD_STH_CONTEXT,
  DMP_WORKBIN_ACCESS_GROUP,
} from "../../../util/Constants";
import DashboardContext from "../../../context/DashboardContext";
import { ReactComponent as WorkBinIcon } from "../../Header/Images/workBinIcon.svg";
import {
  hasSortChanged,
  stringOptionsSorter,
  dateOptionsSorter,
  dateDurationOptionsSorter,
  getStartTime,
} from "./helpers/helpers";
import OrderCard from "../../OrderCard/OrderCard";
import useOrderDetailsByContext from "../../../hooks/orderdetails/orderDetailsByContextHook";
import { useSearchStateHook } from "../../../hooks/useSearchStateHook";
import { pluckFilters, FILTER_KEYS } from "../../Search/search.helper";
import { useSearchFilters } from "../../../hooks/useSearchFilters";
import { checkAdditionalRoleAccessForUser } from "../../../util/Permissions";
import { UserContext } from "../../../context/RootContext";

const ResultTable = (props) => {
  const dashboardContext = useContext(DashboardContext);
  const { user } = useContext(UserContext);
  const { update, persistedValues } = useSearchStateHook({
    deliveryDate: "",
    deliveryWindow: "",
    workOrderStatus: "",
    isAppliance: "",
    deliveryLocation: "",
    promiseDate: "",
    workOrder: "",
    orderType: "",
    date: "",

    // sorting
    sortByKey: undefined,
    sortByOrder: undefined,
  });

  const trackedSorter = {
    columnKey: persistedValues.sortByKey,
    order: persistedValues.sortByOrder,
  };

  const filteredValue = pluckFilters(FILTER_KEYS, persistedValues);

  const tableRowData = parseData(props.data, dashboardContext);

  const {
    orderDetails,
    onExpand,
    updateOrderDetails,
    getOrderCardColor,
    gerOrderBackground,
    getWorkBinExceptions,
  } = useOrderDetailsByContext(props.data);

  const handleUpdateOrderDetails = (record) => {
    updateOrderDetails(record);
    update(
      {
        bust: new Date().getTime(),
      },
      {
        persist: true,
        navOptions: {
          replace: true,
        },
      }
    );
  };

  const { data: filtersOptions, persistFilters } =
    useSearchFilters(tableRowData);

  const trimFields = (fields) => {
    return Object.keys(fields)
      .filter((key) => {
        // we should keep fields with value is null
        // as a way to tell the state that it needs
        // to be removed when merged.
        if (fields[key] === null) {
          return true;
        }

        // other falsy values should be removed (with exception to `false`)
        if (!fields[key] && typeof fields[key] !== "boolean") {
          return false;
        }
        if (!fields[key].length) {
          return false;
        }

        return true;
      })
      .reduce((acc, cur) => {
        return {
          ...acc,
          [cur]: fields[cur] ? fields[cur].join(",") : "",
        };
      }, {});
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    if (hasSortChanged(sorter, trackedSorter)) {
      persistFilters();
      const { columnKey, order } = sorter;
      if (order) {
        update(
          {
            sortByKey: columnKey,
            sortByOrder: order,
            type: persistedValues.type,
            input: persistedValues.input,
            optionalInput: persistedValues.optionalInput,
            date: persistedValues.date,
          },
          {
            persist: true,
          }
        );
      } else {
        // resetting back to default
        update(
          {
            sortByKey: "",
            sortByOrder: "",
            type: persistedValues.type,
            input: persistedValues.input,
            optionalInput: persistedValues.optionalInput,
            date: persistedValues.date,
          },
          {
            persist: true,
          }
        );
      }
    } else {
      persistFilters();
      update(
        {
          // reset pagination if it exists
          currentPage: persistedValues.currentPage ? 0 : "",
          pageNumber: persistedValues.pageNumber ? 1 : "",
          pageSize: persistedValues.pageSize,
          type: persistedValues.type,
          input: persistedValues.input,
          optionalInput: persistedValues.optionalInput,
          date: persistedValues.date,
          ...trimFields(filters),
        },
        {
          persist: true,
          navOptions: {
            replace: false,
          },
        }
      );
    }
  };

  function checkSthContext() {
    return dashboardContext === DASHBOARD_STH_CONTEXT;
  }

  // this is needed because Ant Table sort behaves odd.
  // passing a key sortOrder with value undefined
  // so rather than than, return an empty object so
  // no key gets defined.
  function getSortOrder(key, sort) {
    if (sort.columnKey && sort.columnKey === key) {
      return { sortOrder: sort.order };
    }
    return {};
  }

  let columns = [];
  if (checkSthContext()) {
    columns = [
      {
        title: (
          <Tooltip
            title={
              <span>
                The date the order is scheduled to be delivered to the customer
              </span>
            }
          >
            <span>Scheduled Delivery Date</span>
          </Tooltip>
        ),
        width: "11em",
        dataIndex: "deliveryDate",
        key: "deliveryDate",
        sorter: dateSorter,
        ...getSortOrder("deliveryDate", trackedSorter),
        filters: dateOptionsSorter(filtersOptions.deliveryDate),
        filterSearch: true,
        onFilter: (value, record) => record.deliveryDate.indexOf(value) === 0,
        filteredValue: filteredValue.deliveryDate,
      },
      {
        title: (
          <Tooltip title={<span>Must deliver by date</span>}>
            <span>Promise Date</span>
          </Tooltip>
        ),
        width: "10em",
        dataIndex: "promiseDate",
        key: "promiseDate",
        sorter: promiseDateSorter,
        ...getSortOrder("promiseDate", trackedSorter),
        filters: dateOptionsSorter(filtersOptions.promiseDate),
        filterSearch: true,
        onFilter: (value, record) => record.promiseDate.indexOf(value) === 0,
        filteredValue: filteredValue.promiseDate,
      },
      {
        title: "Customer Order #",
        width: "10em",
        dataIndex: "customerOrderNumber",
        key: "customerOrderNumber",
      },
      {
        title: "Work Order #",
        width: "10em",
        dataIndex: "workOrder",
        key: "workOrder",
        sorter: (a, b) => a.workOrder - b.workOrder,
        ...getSortOrder("workOrder", trackedSorter),
        filters: stringOptionsSorter(filtersOptions.workOrder),
        filterSearch: true,
        onFilter: (value, record) => record.workOrder.indexOf(value) === 0,
        filteredValue: filteredValue.workOrder,
      },
      {
        title: "Order Type",
        width: "10em",
        dataIndex: "orderType",
        key: "orderType",
        filters: stringOptionsSorter(filtersOptions.orderType),
        filterSearch: true,
        onFilter: (value, record) => record.orderType.indexOf(value) === 0,
        filteredValue: filteredValue.orderType,
      },
      {
        title: "Fulfillment Location",
        width: "10em",
        dataIndex: "fulfillmentLocation",
        key: "fulfillmentLocation",
        sorter: (a, b) => a.fulfillmentLocation - b.fulfillmentLocation,
        ...getSortOrder("fulfillmentLocation", trackedSorter),
      },
      {
        title: "Delivery Location",
        width: "10em",
        dataIndex: "deliveryLocation",
        key: "deliveryLocation",
        filters: stringOptionsSorter(filtersOptions.deliveryLocation),
        filterSearch: true,
        onFilter: (value, record) =>
          record.deliveryLocation.indexOf(value) === 0,
        filteredValue: filteredValue.deliveryLocation,
      },
      {
        title: "Customer Name",
        width: "10em",
        dataIndex: "customerName",
        key: "customerName",
        sorter: (a, b) => a.customerName.localeCompare(b.customerName),
        ...getSortOrder("customerName", trackedSorter),
      },
      {
        title: "Address",
        width: "11em",
        dataIndex: "address",
        key: "address",
        render: (text) => (
          <Paragraph
            ellipsis={{
              rows: 4,
              expandable: true,
              symbol: "more",
            }}
          >
            {text}
          </Paragraph>
        ),
      },
      {
        title: "Phone",
        width: "11em",
        dataIndex: "phone",
        key: "phone",
      },
      {
        title: "Delivery Window",
        width: "12em",
        dataIndex: "deliveryWindow",
        key: "deliveryWindow",
        sorter: deliveryWindowSorter,
        ...getSortOrder("deliveryWindow", trackedSorter),
        filters: dateOptionsSorter(filtersOptions.deliveryWindow),
        filterSearch: true,
        onFilter: (value, record) => record.deliveryWindow.indexOf(value) === 0,
        filteredValue: filteredValue.deliveryWindow,
      },
    ];
  } else {
    columns = [
      {
        title: "Delivery Date",
        width: "10em",
        dataIndex: "deliveryDate",
        key: "deliveryDate",
        sorter: dateSorter,
        ...getSortOrder("deliveryDate", trackedSorter),
        filters: dateOptionsSorter(filtersOptions.deliveryDate),
        filterSearch: true,
        onFilter: (value, record) => record.deliveryDate.indexOf(value) === 0,
        filteredValue: filteredValue.deliveryDate,
      },
      {
        title: "Customer Order #",
        width: "11em",
        dataIndex: "customerOrderNumber",
        key: "customerOrderNumber",
        render: (text, record) => {
          const exceptions = getWorkBinExceptions(record);
          if (
            checkAdditionalRoleAccessForUser(DMP_WORKBIN_ACCESS_GROUP, user) &&
            exceptions.length > 0
          ) {
            return (
              <div className="order-work-bin-icon">
                <Tooltip
                  placement="top"
                  title={`Work Bin: ${exceptions.join(", ")}`}
                >
                  <WorkBinIcon />
                </Tooltip>
                <div className="order-number">{text}</div>
              </div>
            );
          } else {
            return <div className="order-number">{text}</div>;
          }
        },
      },
      {
        title: "Work Order",
        width: "11em",
        dataIndex: "workOrder",
        key: "workOrder",
        sorter: (a, b) => a.workOrder - b.workOrder,
        ...getSortOrder("workOrder", trackedSorter),
      },
      {
        title: "Work Order Status",
        width: "11em",
        dataIndex: "workOrderStatus",
        filteredValue: filteredValue.workOrderStatus,
        key: "workOrderStatus",
        filters: stringOptionsSorter(filtersOptions.workOrderStatus),
        filterSearch: true,
        responsive: ["lg", "md"],
        onFilter: (value, record) =>
          record.workOrderStatus.indexOf(value) === 0,
      },
      {
        title: "Order Type",
        width: "10em",
        dataIndex: "isAppliance",
        key: "isAppliance",
        filters: stringOptionsSorter(filtersOptions.isAppliance),
        filterSearch: true,
        responsive: ["lg", "md"],
        onFilter: (value, record) => record.isAppliance === value,
        filteredValue: filteredValue.isAppliance,
      },
      {
        title: "Delivery Location",
        width: "11em",
        dataIndex: "deliveryLocation",
        key: "deliveryLocation",
        filters: stringOptionsSorter(filtersOptions.deliveryLocation),
        filterSearch: true,
        responsive: ["lg"],
        onFilter: (value, record) =>
          record.deliveryLocation.indexOf(value) === 0,
        filteredValue: filteredValue.deliveryLocation,
      },
      {
        title: "Customer Name",
        width: "11em",
        dataIndex: "customerName",
        key: "customerName",
        responsive: ["lg"],
        sorter: (a, b) => a.customerName.localeCompare(b.customerName),
        ...getSortOrder("customerName", trackedSorter),
      },
      {
        title: "Address",
        width: "11em",
        dataIndex: "address",
        key: "address",
        responsive: ["lg"],
        render: (text) => (
          <Paragraph
            ellipsis={{
              rows: 4,
              expandable: true,
              symbol: "more",
            }}
          >
            {text}
          </Paragraph>
        ),
      },
      {
        title: "Phone",
        width: "11em",
        dataIndex: "phone",
        key: "phone",
        responsive: ["lg"],
        render: (phone) => (
          <>
            <div>{phone.hPhone}</div>
            {phone.oPhone && <div>{phone.oPhone}</div>}
          </>
        ),
      },
      {
        title: "Delivery Window",
        width: "15em",
        dataIndex: "deliveryWindow",
        key: "deliveryWindow",
        responsive: ["lg"],
        sorter: deliveryWindowSorter,
        ...getSortOrder("deliveryWindow", trackedSorter),
        filters: dateDurationOptionsSorter(filtersOptions.deliveryWindow),
        filterSearch: true,
        onFilter: (value, record) => record.deliveryWindow.indexOf(value) === 0,
        filteredValue: filteredValue.deliveryWindow,
      },
    ];
  }

  const getRowClassName = (record, index) => {
    if (checkSthContext()) {
      return index % 2 === 0
        ? `table-row-light-${getOrderCardColor(record)}`
        : `table-row-dark-${getOrderCardColor(record)}`;
    } else {
      return index % 2 === 0
        ? `table-row-light-${getOrderCardColor(record)} ${gerOrderBackground(
            record
          )}`
        : `table-row-dark-${getOrderCardColor(record)} ${gerOrderBackground(
            record
          )}`;
    }
  };

  if (trackedSorter.columnKey) {
    const column = columns.find((row) => row.key === trackedSorter.columnKey);
    if (column && column.sorter && tableRowData.length) {
      tableRowData.sort((a, b) => column.sorter(a, b, trackedSorter.order));
    }
  }

  return (
    <>
      <Table
        columns={columns}
        showSorterTooltip={false}
        onChange={handleTableChange}
        dataSource={tableRowData}
        scroll={{ x: true }}
        getPopupContainer={(trigger) => trigger}
        rowClassName={getRowClassName}
        pagination={false}
        expandIcon={(props) => customExpandIcon(props)}
        expandable={{
          onExpand: onExpand,
          expandedRowRender: (record, index, indent, expanded) => (
            <OrderCard
              orderDetails={orderDetails}
              record={record}
              orderIndex={record.key}
              customerOrderNumber={record.customerOrderNumber}
              updateOrderDetails={handleUpdateOrderDetails}
              expanded={expanded}
              loadDeliveryTracker={true}
              orderCardColor={getOrderCardColor(record)}
              hideCustomerInfoEdit={checkSthContext()}
              sthRefreshDeliveries={props.sthRefreshDeliveries}
            />
          ),
        }}
      />
    </>
  );
};

function parseData(searchResults, dashboardContext) {
  const dataSource = [];
  if (!searchResults) {
    return dataSource;
  }
  if (dashboardContext === DASHBOARD_STH_CONTEXT) {
    if (searchResults.sthDeliveries !== undefined) {
      searchResults.sthDeliveries.forEach((delivery, idx) => {
        dataSource.push({
          key: idx,
          customerOrderNumber: access(
            delivery.deliveryDetail,
            "customerOrderNumber"
          ),
          workOrder: access(delivery.deliveryDetail, "workOrder"),
          customerName: getName(delivery.customerDetail),
          address: getAddress(delivery.customerDetail),
          phone: access(delivery.customerDetail, "mobilePhone"),
          promiseDate: getPromiseDate(delivery.deliveryDetail),
          deliveryDate: getDeliveryDate(delivery.deliveryDetail),
          deliveryWindow: getDeliveryWindow(delivery.deliveryDetail),
          deliveryLocation: access(delivery.deliveryDetail, "deliveryLocation"),
          orderType: access(delivery, "productType"),
          currentDeliveryStatus: access(delivery, "currentDeliveryStatus"),
          reservationId: access(delivery, "reservationId"),
          fulfillmentLocation: access(
            delivery.deliveryDetail,
            "fulfillmentLocation"
          ),
        });
      });
    }
  } else {
    if (searchResults.deliveries !== undefined) {
      searchResults.deliveries.forEach((delivery, idx) => {
        dataSource.push({
          key: idx,
          deliveryDate: getDeliveryDate(delivery),
          customerOrderNumber: access(delivery, "customerOrderNumber"),
          workOrder: access(delivery, "workOrder"),
          workOrderStatus: access(delivery, "workOrderStatus"),
          deliveryExceptions: access(delivery, "deliveryExceptions"),
          deliveryWindow: getDeliveryWindow(delivery),
          customerName: access(delivery, "customerName"),
          address: access(delivery, "deliveryAddress"),
          deliveryLocation: access(delivery, "deliveryLocation"),
          isAppliance: getOrderType(delivery),
          phone: {
            hPhone: getFormattedPhone(delivery, "H"),
            oPhone: getFormattedPhone(delivery, "O"),
          },
        });
      });
    }
  }
  return dataSource;
}

export function getName(delivery) {
  const fName = delivery.firstName;
  const lName = delivery.lastName;
  return lName.concat(", ", fName);
}

export function getAddress(delivery) {
  const sAddress1 = delivery.streetAddress1;
  if (delivery.streetAddress2 !== null && delivery.streetAddress2 !== "") {
    return sAddress1
      .concat(", ", delivery.streetAddress2)
      .concat(", ", delivery.city)
      .concat(", ", delivery.state)
      .concat(", ", delivery.country)
      .concat(", ", delivery.zipCode);
  } else {
    return sAddress1
      .concat(", ", delivery.city)
      .concat(", ", delivery.state)
      .concat(", ", delivery.country)
      .concat(", ", delivery.zipCode);
  }
}

export function getPromiseDate(delivery) {
  return !delivery.estimatedDeliveryDate
    ? "No Promise Date"
    : DateFormatter(delivery.estimatedDeliveryDate, "YYYY-MM-DD");
}

const promiseDateSorter = (a, b, sortOrder) => {
  if (a.promiseDate === "Invalid date" || a.promiseDate === "No Promise Date") {
    return sortOrder === "ascend" ? -1 : 1;
  } else if (
    b.promiseDate === "Invalid date" ||
    b.promiseDate === "No Promise Date"
  ) {
    return sortOrder === "ascend" ? 1 : -1;
  }
  return new Date(a.promiseDate) - new Date(b.promiseDate);
};

function getFormattedPhone(delivery, phoneType) {
  if (delivery.customerPhone.length > 0 && phoneType === "H") {
    return PhoneNumberFormatter(delivery.customerPhone[0]).number;
  }
  if (delivery.customerPhone.length > 1 && phoneType === "O") {
    return PhoneNumberFormatter(delivery.customerPhone[1]).number;
  }
  return "";
}

export function getOrderType(delivery) {
  return delivery.isAppliance ? "Appliance" : "Merchandise";
}

export function getDeliveryDate(delivery) {
  return !delivery.deliveryStartDateTime
    ? "No Delivery Date"
    : DateFormatter(delivery.deliveryStartDateTime, "YYYY-MM-DD");
}

export function getDeliveryWindow(delivery) {
  if (
    delivery.deliveryStartDateTime &&
    delivery.deliveryEndDateTime &&
    delivery.deliveryStartDateTime !== null &&
    delivery.deliveryEndDateTime !== null
  ) {
    const startTime = formatTime(delivery.deliveryStartDateTime, "hh:mm A");
    const endTime = formatTime(delivery.deliveryEndDateTime, "hh:mm A");

    if (startTime === "Invalid time" || endTime === "Invalid time") {
      return "Invalid timewindow";
    } else {
      return `${startTime} - ${endTime}`;
    }
  }
  return "Delivery Window Not Set";
}

/**
 * parameters a and b should usually be YYYY-MM-DD, or "No Delivery Date" or "Invalid date"
 * parameter sortOrder can be 'ascend' or 'decend'
 * This function does not get called when table is first rendered, nor when sort is set back to neutral
 */
const dateSorter = (a, b, sortOrder) => {
  if (
    a.deliveryDate === "Invalid date" ||
    a.deliveryDate === "No Delivery Date"
  ) {
    return sortOrder === "ascend" ? -1 : 1;
  } else if (
    b.deliveryDate === "Invalid date" ||
    b.deliveryDate === "No Delivery Date"
  ) {
    return sortOrder === "ascend" ? 1 : -1;
  }
  return new Date(a.deliveryDate) - new Date(b.deliveryDate);
};

/**
 * parameters a and b should usually be hh:mm - hh:mm, or "Delivery Window Not Set" or "Invalid timewindow"
 * parameter sortOrder can be 'ascend' or 'decend'
 * This function does not get called when table is first rendered, nor when sort is set back to neutral
 */
const deliveryWindowSorter = (a, b, sortOrder) => {
  if (
    a.deliveryWindow === "Invalid timewindow" ||
    a.deliveryWindow === "Delivery Window Not Set"
  ) {
    return sortOrder === "ascend" ? -1 : 1;
  } else if (
    b.deliveryWindow === "Invalid timewindow" ||
    b.deliveryWindow === "Delivery Window Not Set"
  ) {
    return sortOrder === "ascend" ? 1 : -1;
  }
  const startTimeA = getStartTime(a.deliveryWindow);
  const startTimeB = getStartTime(b.deliveryWindow);

  if (startTimeA < startTimeB) {
    return -1;
  } else if (startTimeB < startTimeA) {
    return 1;
  }
};

//This function will attempt to access a JSON property given the JSON root and a path.
//If the property does not exist, it will simply return null
//NOTE:This is not a replacement for proper null checking
function access(object, path) {
  const steps = path.split(".");
  while (object && steps.length) {
    object = object[steps.shift()];
  }
  const property = object === undefined ? null : object;
  return property;
}

function customExpandIcon(props) {
  if (props.expanded) {
    return (
      <a
        style={{ color: "black" }}
        onClick={(e) => {
          props.onExpand(props.record, e);
        }}
      >
        <DownOutlined data-testid="collapse-arrow" />
      </a>
    );
  } else {
    return (
      <a
        style={{ color: "black" }}
        onClick={(e) => {
          props.onExpand(props.record, e);
        }}
      >
        <RightOutlined data-testid="expand-arrow" />
      </a>
    );
  }
}

export default ResultTable;
