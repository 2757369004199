import axios from "axios";
import {
  CLEAN_DELIVERY_SLOTS,
  DELIVERY_SLOTS_FAIL,
  DELIVERY_SLOTS_REQUEST,
  DELIVERY_SLOTS_SUCCESS,
} from "../constants/deliverySlotsConstants";
import moment from "moment";
import getBffUrl from "../../util/getBffUrl";
import * as constants from "../../util/Constants";
import { determineManuallyCode as determineRevisitTypeCode } from "../../util/DetermineManuallyCode";

export const getDeliverySlotsAction =
  (dispatch) => async (orderDetails, vendorNbr, zipCode) => {
    function getEndDate(date) {
      const endDate = new Date(date);
      endDate.setDate(endDate.getDate() + 92);
      return endDate;
    }

    function getManufacturingVendors() {
      const vendors = [];
      orderDetails.deliveryDetail &&
        orderDetails.deliveryDetail.poDetailList.forEach((poDetail) => {
          vendors.push(poDetail.vendorNbr);
        });
      return vendors.toString();
    }

    function getParams() {
      const params = {};
      const today = new Date();
      const endDate = getEndDate(today);

      params.startDate = moment(today).format("YYYY-MM-DD").toString();
      params.endDate = moment(endDate).format("YYYY-MM-DD").toString();
      params.checkCutOffTime = "N";

      if (orderDetails !== null) {
        params.zipCode = orderDetails.customerDetail.custZipCode;
        if (
          orderDetails.workOrderDetail != null &&
          getManufacturingVendors() !== ""
        ) {
          params.vendorNbr = getManufacturingVendors();
          params.deliveryVendorNbr = orderDetails.workOrderDetail.vendorNbr;
        } else if (
          orderDetails.workOrderDetail &&
          getManufacturingVendors() === ""
        ) {
          params.vendorNbr = orderDetails.workOrderDetail.vendorNbr;
          params.deliveryVendorNbr = orderDetails.workOrderDetail.vendorNbr;
        } else {
          params.vendorNbr = getManufacturingVendors();
        }
      } else {
        params.zipCode = zipCode;
        params.vendorNbr = vendorNbr;
      }
      return params;
    }

    dispatch({
      type: DELIVERY_SLOTS_REQUEST,
    });
    const slotsByDate = {};

    await axios
      .get(`${getBffUrl()}/dashboard/v1/deliverySlots`, {
        params: getParams(),
      })
      .then((response) => {
        if (
          response.data &&
          response.data.errors === null &&
          response.data.deliverySlots
        ) {
          response.data.deliverySlots.forEach((slot) => {
            slotsByDate[moment(slot.mdate).utc().format("YYYY-MM-DD")] =
              slot.mavails;
          });

          dispatch({
            type: DELIVERY_SLOTS_SUCCESS,
            payload: slotsByDate,
          });
        }
      })
      .catch((error) => {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        const description =
          error.response &&
          error.response.data &&
          error.response.data.description;
        const status = error.response && error.response.status;
        dispatch({
          type: DELIVERY_SLOTS_FAIL,
          payload: {
            message: message,
            description: description,
            status: status,
          },
        });
      });
  };

export const cleanDeliverySlotsAction = (dispatch) => () => {
  dispatch({
    type: CLEAN_DELIVERY_SLOTS,
  });
};

export const getRevisitSlotsAction =
  (dispatch) =>
  async (
    customerOrderNumber,
    workOrderNumber,
    location,
    customerDetail,
    itemsForRevisit,
    revisitType
  ) => {
    function createApplianceRevisitLine(item) {
      return {
        lineNumber: item.lineNumber,
        modelNumber: item.modelNumber,
        skuNumber: item.skuNumber,
        parentLineNumber: item.lineNumber,
        lineType: constants.APPLIANCE_LINE,
      };
    }

    function createDefaultLine(item) {
      return {
        lineNumber: item.lineNumber,
        modelNumber: constants.REVISIT_MODEL_NUMBER,
        skuNumber: constants.REVISIT_SKU,
        parentLineNumber: item.lineNumber,
        lineType: constants.DEFAULT_REVISIT_LINE,
      };
    }

    function createPartAndServiceRevisitLine(item, pasItem) {
      return {
        lineNumber: pasItem.lineNumber,
        modelNumber: pasItem.modelNumber,
        skuNumber: pasItem.skuNumber,
        parentLineNumber: item.lineNumber,
        lineType: constants.PARTS_SERVICES_LINE,
      };
    }

    dispatch({
      type: DELIVERY_SLOTS_REQUEST,
    });
    try {
      const fulfillmentPayload = {};

      fulfillmentPayload.orderNumber = customerOrderNumber;
      fulfillmentPayload.workOrderNumber = workOrderNumber;
      fulfillmentPayload.deliveryLocation = location;
      fulfillmentPayload.startDate = moment(new Date()).format("YYYY-MM-DD");
      fulfillmentPayload.endDate = moment(new Date())
        .add(90, "days")
        .format("YYYY-MM-DD");
      fulfillmentPayload.deliveryZipCode = customerDetail.zipCode;
      fulfillmentPayload.revisitServiceType =
        determineRevisitTypeCode(revisitType);
      fulfillmentPayload.lineItems = [];
      itemsForRevisit.forEach((item) => {
        if (revisitType === constants.REVISIT_REPLACEMENT) {
          fulfillmentPayload.lineItems = [
            ...fulfillmentPayload.lineItems,
            createApplianceRevisitLine(item),
          ];
        }
        fulfillmentPayload.lineItems = [
          ...fulfillmentPayload.lineItems,
          createDefaultLine(item),
        ];
        item.partsAndServices.forEach((pasItem) => {
          fulfillmentPayload.lineItems = [
            ...fulfillmentPayload.lineItems,
            createPartAndServiceRevisitLine(item, pasItem),
          ];
        });
      });

      const response = await axios.post(
        `${getBffUrl()}/v1/delivery/fulfillmentOptions`,
        fulfillmentPayload
      );

      if (response && response.status === 200) {
        if (response.data && response.data.deliverySlots) {
          dispatch({
            type: DELIVERY_SLOTS_SUCCESS,
            payload: response.data.deliverySlots,
          });
        }
      }
    } catch (error) {
      console.log("this is error", error);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: DELIVERY_SLOTS_FAIL, payload: message });
    }
  };
