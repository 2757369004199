import React from "react";
import "../ManualOrder.less";
import PropTypes from "prop-types";
import VendorItems from "./VendorItems";
import { Checkbox, Form } from "antd";

const ProductOrders = ({
  deliveryDetail,
  setSelectedVendor,
  enabledVendor,
  setEnabledVendor,
}) => {
  function isOnlyRevisitAvailable() {
    // When original order is created from 'New Manual Order' flow it will contain only 'Revisit' line
    if (
      deliveryDetail &&
      deliveryDetail.poDetailList &&
      deliveryDetail.poDetailList.length === 1 &&
      deliveryDetail.poDetailList[0].lineLevelDetailList &&
      deliveryDetail.poDetailList[0].lineLevelDetailList.length === 1 &&
      deliveryDetail.poDetailList[0].lineLevelDetailList[0].modelNbr ===
        "Revisit"
    ) {
      return true;
    }
    return false;
  }

  return (
    <>
      {isOnlyRevisitAvailable() ? (
        <div className="line-items-header">No line items present</div>
      ) : (
        <div className="line-items-header">
          Select line items for revisit
          <div className="vendor-limit">
            <i>limit one vendor</i>
          </div>
        </div>
      )}
      <div className="product-orders-container">
        <Form.Item name="lineItems">
          <Checkbox.Group>
            {deliveryDetail &&
              deliveryDetail.poDetailList &&
              deliveryDetail.poDetailList.map((poDetail, poIndex) => (
                <VendorItems
                  setSelectedVendor={setSelectedVendor}
                  key={poIndex}
                  poDetail={poDetail}
                  poIndex={poIndex}
                  enabled={enabledVendor == null || enabledVendor === poIndex}
                  setEnabledVendor={setEnabledVendor}
                />
              ))}
          </Checkbox.Group>
        </Form.Item>
      </div>
    </>
  );
};

ProductOrders.propTypes = { deliveryDetail: PropTypes.object.isRequired };

export default ProductOrders;
