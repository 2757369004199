import React, { useContext, useState } from "react";
import * as constants from "../../../../util/Constants";
import "./LegacyReschedule.less";
import { UserContext } from "../../../../context/RootContext";
import axios from "axios";
import permit from "../../../../util/Permissions";
import { Button, Calendar, Input, Select, Col, Row } from "antd";
import moment from "moment";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import getBffUrl from "../../../../util/getBffUrl";
import useNewRelicMetrics from "../../../../hooks/newRelicMetricsHook";
import { LEGACY_RESCHEDULE_EVENT } from "../../../../util/NewRelicConstants";

const LegacyReschedule = ({
  customerDetail,
  originalMSN,
  dates = {},
  orderNumber,
  slotsCount,
  handleClose,
  passSelectedDate,
  simpleClose,
}) => {
  const { user } = useContext(UserContext);
  const { ldapId, companyName } = user;
  const { logNewRelicMetricsEvent } = useNewRelicMetrics();
  const [selectDate, setSelectDate] = useState();

  const defaultPayload = {
    deliveryRescheduledToDate: moment(new Date()).format("YYYY-MM-DD"),
    timeslot: null,
    reasonCode: null,
    reasonCommentText: "",
    userID: ldapId,
    deliveryID: customerDetail.referenceId,
    customerOrderID: customerDetail.custOrdId,
    customerOrderNumber: customerDetail.custOrdNum,
    originalMSN: originalMSN,
    companyName: companyName,
  };
  const [payloadToBeSaved, setPayloadToBeSaved] = useState(defaultPayload);

  const custContactModeOptions = [
    "Live Conversation",
    "Left Voicemail",
    "Robo Call",
    "Attempted",
    "Not Contacted",
  ];

  const formatDate = (d) => {
    let dateString = null;
    const year = d.slice(0, 4);
    let month = d.slice(5, 7);
    let day = d.slice(8);
    if (day.charAt(0) === "0") {
      day = day.slice(1);
    }

    switch (month) {
      case "01":
        month = constants.JANUARY;
        break;
      case "02":
        month = constants.FEBRUARY;
        break;
      case "03":
        month = constants.MARCH;
        break;
      case "04":
        month = constants.APRIL;
        break;
      case "05":
        month = constants.MAY;
        break;
      case "06":
        month = constants.JUNE;
        break;
      case "07":
        month = constants.JULY;
        break;
      case "08":
        month = constants.AUGUST;
        break;
      case "09":
        month = constants.SEPTEMBER;
        break;
      case "10":
        month = constants.OCTOBER;
        break;
      case "11":
        month = constants.NOVEMBER;
        break;
      case "12":
        month = constants.DECEMBER;
        break;
      default:
        month = "Not a valid month.";
        break;
    }

    dateString = `${month} ${day}, ${year}`;
    return dateString;
  };

  function handleReschedule() {
    logNewRelicMetricsEvent(LEGACY_RESCHEDULE_EVENT, {
      order_number: orderNumber,
    });
    axios
      .post(`${getBffUrl()}/deliveryOrder/v1/reschedule`, payloadToBeSaved)
      .then((response) => {
        if (response && response.status === 200) {
          console.log("Success calling Reschedule");
          handleClose();
        } else {
          console.log("Failure calling Reschedule");
        }
      })
      .catch(() => {
        console.log("Error calling Reschedule");
      });
  }

  function isAvailable(day) {
    return (
      dates &&
      dates.enabled.some((date) => date === day.format("yyyy-MM-DD").toString())
    );
  }

  function isUnavailable(day) {
    return (
      dates &&
      dates.empty.some((date) => date === day.format("yyyy-MM-DD").toString())
    );
  }

  function calendarStyles(day) {
    let dayClassName = "";
    if (moment(day).isBefore(new Date(), "day")) {
      dayClassName = "before";
    }

    if (moment(day).isAfter(new Date(), "day")) {
      dayClassName = "after";
    }

    if (moment(new Date()).isSame(day, "day")) {
      dayClassName = dayClassName ? `${dayClassName} today` : "today";
    }

    if (isAvailable(day)) {
      dayClassName = dayClassName ? `${dayClassName} available` : "available";
    }

    if (isUnavailable(day)) {
      dayClassName = dayClassName
        ? `${dayClassName} unavailable`
        : "unavailable";
    }

    if (
      selectDate &&
      selectDate.isSame(day, "day") &&
      !moment(day).isBefore(new Date(), "day")
    ) {
      dayClassName = dayClassName ? `${dayClassName} selected` : "selected";
    }

    return dayClassName;
  }

  return (
    <div className="reschedule-container">
      <div className="reschedule-header">
        <h3>Reschedule Delivery</h3>
      </div>
      <div id="reschedule-content">
        <Row>
          <Col
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <div id="outer-delivery-date">
              <img
                id="calender-img"
                src="/images/calendar.svg"
                alt="calendar-icon"
              />
              <span id="current-delivery-date">CURRENT DELIVERY DATE</span>
              <div id="delivery-date">
                {formatDate(customerDetail.scheduledDeliveryDate)}
              </div>
            </div>
            <div id="delivery-calendar">
              {dates.enabled && dates.empty && dates.enabled.length > 0 && (
                <Calendar
                  className="reschedule-calendar"
                  dateFullCellRender={(date) => {
                    const day = moment(date).format("D");
                    return <div className={calendarStyles(date)}>{day}</div>;
                  }}
                  disabledDate={(current) => {
                    return (
                      current > moment(current).isAfter(new Date(), "day") !==
                      isAvailable(current)
                    );
                  }}
                  onSelect={(day) => {
                    passSelectedDate(day);
                    setSelectDate(day);
                    setPayloadToBeSaved({
                      ...payloadToBeSaved,
                      deliveryRescheduledToDate:
                        moment(day).format("YYYY-MM-DD"),
                    });
                  }}
                  fullscreen={false}
                  headerRender={({ value, onChange }) => {
                    return (
                      <div style={{ padding: 10 }}>
                        <Row type="flex" justify="space-between">
                          <Col className="col-6">
                            <Button
                              type="link"
                              onClick={() => {
                                const newValue = value.clone();
                                newValue.subtract(1, "month");
                                onChange(newValue);
                              }}
                            >
                              <LeftOutlined className="left-arrow" />
                            </Button>
                          </Col>
                          <Col>
                            <div className="month-and-year">
                              {value
                                .locale("en")
                                .clone()
                                .startOf("day")
                                .startOf("month")
                                .format("MMMM")}
                              <span>
                                {value
                                  .locale("en")
                                  .clone()
                                  .startOf("day")
                                  .startOf("month")
                                  .format("YYYY")}
                              </span>
                            </div>
                          </Col>
                          <Col>
                            <Button
                              type="link"
                              onClick={() => {
                                const newValue = value.clone();
                                newValue.add(1, "month");
                                onChange(newValue);
                              }}
                            >
                              <RightOutlined className="right-arrow" />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    );
                  }}
                />
              )}
            </div>
          </Col>
          <Col
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <div id="outer-delivery-address">
              <img
                id="pointer-img"
                src="/images/pointer.svg"
                alt="pointer-icon"
              />
              <span id="delivery-address-header">DELIVERY ADDRESS</span>
              <div id="delivery-address">
                <span>{`${customerDetail.custFirstName} ${customerDetail.custLastName}`}</span>
                <br />
                <span>{`${customerDetail.custStreetAddress1} ${customerDetail.custStreetAddress2}`}</span>
                <br />
                <span>{`${customerDetail.custCity}, ${customerDetail.custState} ${customerDetail.custZipCode}`}</span>
                <br />
                <span>{customerDetail.custPhoneNbr}</span>
                <br />
              </div>
            </div>
            <div id="select-slots-box">
              <div id="selected-date">
                <div>{slotsCount}</div>
              </div>
              <div id="selected-date-text">
                {"Delivery slots remaining for the zip code "}
                <slot id="green-text">{customerDetail.custZipCode}</slot>
                {" on "}
                <slot id="green-text">
                  {payloadToBeSaved.deliveryRescheduledToDate}
                </slot>
              </div>
            </div>
            <div id="reschedule-dropdowns">
              <div id="reason-code">
                <div className="label">Reason code </div>
                <Select
                  size="large"
                  id="reason-select"
                  placeholder="Select..."
                  style={{ width: "60%" }}
                  onChange={(v) =>
                    setPayloadToBeSaved({
                      ...payloadToBeSaved,
                      reasonCode: v,
                    })
                  }
                >
                  {Object.keys(constants.RESCHEDULE_REASON_CODES).map((i) => (
                    <Select.Option value={i} key={i}>
                      {constants.RESCHEDULE_REASON_CODES[i]}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <br />
              {permit("isCustContactModeShownAndMandatory", user) && (
                <div id="cust-contacted">
                  <div className="label">Has the customer been contacted? </div>
                  <Select
                    size="large"
                    placeholder="Select..."
                    style={{ width: "60%" }}
                    onChange={(v) =>
                      setPayloadToBeSaved({
                        ...payloadToBeSaved,
                        custContactMode: v,
                        custContacted:
                          v === custContactModeOptions[4]
                            ? "N"
                            : v === custContactModeOptions[3]
                            ? "A"
                            : "Y",
                      })
                    }
                  >
                    {custContactModeOptions.map((i) => (
                      <Select.Option value={i} key={i}>
                        {i}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              )}
            </div>
            <div id="add-notes">
              {dates.empty && (
                <>
                  <div className="label">Add Note</div>
                  <Input.TextArea
                    maxLength={255}
                    autoSize={{ minRows: 3, maxRows: 3 }}
                    style={{ width: "80%" }}
                    onChange={({ target }) => {
                      setPayloadToBeSaved({
                        ...payloadToBeSaved,
                        reasonCommentText: target.value.substring(0, 255),
                      });
                    }}
                  />
                  <div
                    style={{ width: "80%", textAlign: "right" }}
                  >{`${payloadToBeSaved.reasonCommentText.length} / 255 characters`}</div>
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <div id="reschedule-buttons">
        <section id="cancel-button">
          <Button
            type="default"
            size="large"
            block
            onClick={() => simpleClose()}
          >
            Cancel
          </Button>
        </section>
        <section id="save-button">
          <Button
            size="large"
            onClick={() => {
              handleReschedule();
            }}
            disabled={
              !payloadToBeSaved.reasonCode ||
              (payloadToBeSaved.reasonCode === "10" &&
                payloadToBeSaved.reasonCommentText.length === 0) ||
              (permit("isCustContactModeShownAndMandatory", user) &&
                payloadToBeSaved.custContactMode === undefined)
            }
          >
            Save changes
          </Button>
        </section>
      </div>
    </div>
  );
};

export default LegacyReschedule;
