import { useState } from "react";
import axios from "axios";
import getBffUrl from "../util/getBffUrl";

function useTrackerUrlHook() {
  const [trackerUrl, setTrackerUrl] = useState({});

  async function getTrackerUrl(params) {
    try {
      const response = await axios.get(`${getBffUrl()}/v1/tracker-url`, {
        params: params,
      });
      if (response.status === 200) {
        return response.data.url;
      } else {
        return "";
      }
    } catch (error) {
      console.log("error  tracker url", error);
      return "";
    }
  }

  async function updateTrackerUrl(record) {
    Promise.resolve(
      getTrackerUrl({
        orderNumber: record.customerOrderNumber,
        workOrderNumber: record.workOrder,
      })
    ).then((link) => {
      setTrackerUrl((prevState) => {
        return {
          ...prevState,
          [record.key]: {
            link: link,
          },
        };
      });
    });
  }

  return { trackerUrl, updateTrackerUrl };
}

export default useTrackerUrlHook;
