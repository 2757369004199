import { useRef, useContext } from "react";
import { buildInitialFiltersOptions } from "../components/DeliveriesCommon/ResultTable/helpers/helpers";
import SearchContext from "../context/SearchContext";

export const useSearchFilters = (tableData) => {
  const ctx = useContext(SearchContext);

  const freshData = buildInitialFiltersOptions(tableData, ctx.filters.current);

  const persistFilters = () => {
    ctx.filters.current = freshData;
  };
  return { data: freshData, persistFilters };
};
