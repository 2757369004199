import {
  CLOSE_MAIL_REQUEST,
  CLOSE_MAIL_SUCCESS,
  CLOSE_MAIL_FAIL,
  CLEAR_CLOSE_MAIL,
} from "../constants/closeMailMessageConstants";

const closeMailMessageReducer = (state, { payload, type }) => {
  switch (type) {
    case CLOSE_MAIL_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case CLOSE_MAIL_SUCCESS:
      return {
        loading: false,
        data: payload,
        error: false,
      };
    case CLOSE_MAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case CLEAR_CLOSE_MAIL:
      return {
        data: null,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
};

export default closeMailMessageReducer;
